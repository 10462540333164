import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { maskBirthDate, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import UserGroupsSelector from "layouts/administration/people/components/UserGroupsSelector";
import usePermission from "hooks/usePermission";

const UPDATE_PERSON = gql`
  mutation UpdatePerson($data: UpdatePersonInput!, $personId: String!) {
    updatePerson(data: $data, personId: $personId) {
      id
    }
  }
`;

const GET_PERSON = gql`
  query Person($id: String!) {
    person(id: $id) {
      id
      name
      cpf
      birthDate
      birthPlace
      email
      gender
      maritalStatus
      situation
      nationality
      phone1
      phone2
      registration
      rg
      # role
      rgIssuer
      # status
      # createdAt
      # updatedAt
      userGroups {
        id
        name
      }
      address {
        # id
        street
        number
        neighborhood
        city
        state
        zipCode
        complement
        # createdAt
        # updatedAt
      }
      accessCredentials {
        id
        needsPasswordChange
      }
    }
  }
`;

const DELETE_PERSON = gql`
  mutation DeletePerson($personId: String!) {
    deletePerson(personId: $personId)
  }
`;

function DeleteButton({ pushAlert, person }: { pushAlert: any; person: any }) {
  const navigate = useNavigate();

  const [deletePersonMutation, { loading }] = useMutation(DELETE_PERSON, {
    onCompleted: () => {
      navigate("/pessoas");
    },
    onError(error) {
      pushAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  function handlerDelete() {
    deletePersonMutation({
      variables: {
        personId: person.id,
      },
    });
  }

  return (
    <MDButton
      variant="contained"
      color="error"
      sx={{ mr: "auto" }}
      loading={loading}
      onClick={handlerDelete}
    >
      Excluir
    </MDButton>
  );
}

const personInitialData = {
  name: "",
  email: "",
  cpf: "",
  birthDate: "",
  phone1: "",
  phone2: "",
  registration: "",
  gender: "",
  maritalStatus: "",
  situation: "",
  rg: "",
  rgIssuer: "",
  birthPlace: "",
  nationality: "",
  // role: "CUSTOMER",
};

const addressInitialData = {
  street: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  zipCode: "",
};

const accessInitialData = {
  active: false,
  password: "",
  needsPasswordChange: false,
};

const maritalStatusOptions = [
  { value: "SINGLE", label: "Solteiro(a)" },
  { value: "MARRIED", label: "Casado(a)" },
  { value: "DIVORCED", label: "Divorciado(a)" },
  { value: "WIDOWED", label: "Viúvo(a)" },
];

function getMaritalACValue(value: string) {
  if (!value) return null;
  return maritalStatusOptions.find((option) => option.value === value);
}

const genderOptions = [
  { value: "MALE", label: "Masculino" },
  { value: "FEMALE", label: "Feminino" },
  { value: "OTHER", label: "Outro" },
];

const situationOptions = [
  { value: "ACTIVE", label: "Ativo(a)" },
  { value: "INACTIVE", label: "Inativo(a)" },
  { value: "PENSIONER", label: "Pensionista" },
  { value: "CIVIL", label: "Servidor(a) Civil" },
  { value: "RECALLED", label: "Reconvocado(a)" },
  { value: "CBSAUDE", label: "CBSAUDE" },
];

function getSituationACValue(value: string) {
  if (!value) return null;
  return situationOptions.find((option) => option.value === value);
}

function getGenderACValue(value: string) {
  if (!value) return null;
  return genderOptions.find((option) => option.value === value);
}

function InputRegistration({ value, onChange }: { value: string; onChange: (e: any) => void }) {
  const regex = /^\d{3,15}-\d{1,2}$/;
  let error: string | null = null;
  if (value?.length > 0) {
    if (!value.includes("-")) {
      error = "A Matrícula deve conter o dígito com hífen";
    } else if (!regex.test(value)) {
      error = "Matrícula inválida";
    }
  }
  return (
    <Box sx={{ width: "100%", mr: 2 }}>
      <MDInput
        variant="outlined"
        label="Matrícula"
        sx={{ bgcolor: "#fafafa" }}
        fullWidth
        value={value}
        onChange={onChange}
        error={!!error}
      />
      {!!error && (
        <MDTypography variant="button" color="error">
          {error}
        </MDTypography>
      )}
    </Box>
  );
}

export default function EditPerson() {
  const { personId } = useParams();

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [person, setPerson] = useState(personInitialData);
  const [address, setAddress] = useState(addressInitialData);
  const [access, setAccess] = useState(accessInitialData);
  const [userGroups, setUserGroups] = useState([]);

  const {
    loading,
    error: getPersonError,
    // data,
  } = useQuery(GET_PERSON, {
    variables: {
      id: personId,
    },
    onCompleted: (data) => {
      const { person } = data;
      const { address, accessCredentials, userGroups } = person;

      setPerson({
        ...person,
        cpf: maskcpf(person.cpf),
        birthDate: person?.birthDate?.split("-").reverse().join("/"),
      });

      if (address) setAddress(address);
      if (accessCredentials) setAccess({ ...accessCredentials, active: true });
      if (userGroups) setUserGroups(userGroups);
      // if (person.maritalStatus)
      //   setMaritalStatus(
      //     maritalStatusOptions.find((option) => option.value === person.maritalStatus)
      //   );
    },
  });

  const [updatePersonMutation, { error: updatePersonError, loading: updatePersonLoading }] =
    useMutation(UPDATE_PERSON, {
      onCompleted: () => {
        pushAlert({
          type: "success",
          message: "Pessoa atualizada com sucesso!",
        });
      },
    });

  useEffect(() => {
    if (getPersonError) {
      // pushError(getPersonError);
      pushAlert({
        type: "error",
        message: getPersonError.message,
      });
    }
  }, [getPersonError]);

  useEffect(() => {
    if (updatePersonError) {
      // pushError(updatePersonError);
      pushAlert({
        type: "error",
        message: updatePersonError.message,
      });
    }
  }, [updatePersonError]);

  const { can } = usePermission();

  function handlerSubmit(event: any) {
    event.preventDefault();
    const birthDate = person?.birthDate?.split("/").reverse().join("-");

    const { active, ...accessCredentials } = access;

    updatePersonMutation({
      variables: {
        personId: personId,
        data: {
          ...person,
          __typename: undefined,
          id: undefined,
          birthDate,
          address: {
            ...address,
            id: undefined,
            __typename: undefined,
          },
          accessCredentials: active
            ? {
                ...accessCredentials,
                __typename: undefined,
                id: undefined,
                password: accessCredentials.password || undefined,
              }
            : null,
          userGroupIds: can("UPDATE_PERSON_USERGROUPS")
            ? userGroups.map((userGroup: any) => userGroup.id)
            : undefined,
          userGroups: undefined,
        },
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title="Dados Pessoais" />

      <MDBox pt={2} pb={3} component="form" role="form" onSubmit={handlerSubmit}>
        <AlertBox alerts={alerts} />

        <MDTypography variant="h5" gutterBottom mb={3}>
          Pessoa {loading && <CircularProgress color="inherit" size={16} />}
        </MDTypography>

        <Grid container spacing={4}>
          {/* ----- */}
          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Dados Básicos
              </MDTypography>

              <MDInput
                variant="outlined"
                label="Nome Completo"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                required
                value={person.name}
                onChange={(e: any) => setPerson({ ...person, name: e.target.value })}
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="CPF"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={person.cpf}
                  onChange={(e: any) => setPerson({ ...person, cpf: maskcpf(e.target.value) })}
                  inputProps={{ maxLength: 14 }}
                  // required
                />
                <MDInput
                  variant="outlined"
                  label="Data de Nascimento"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={person.birthDate}
                  inputProps={{ maxLength: 10, minLength: 10 }}
                  // required
                  onChange={(e: any) =>
                    setPerson({ ...person, birthDate: maskBirthDate(e.target.value) })
                  }
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="RG"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={person.rg}
                  onChange={(e: any) => setPerson({ ...person, rg: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Órgão Emissor do RG"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={person.rgIssuer}
                  onChange={(e: any) => setPerson({ ...person, rgIssuer: e.target.value })}
                />

                <Autocomplete
                  disablePortal
                  options={maritalStatusOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa" }}
                  renderInput={(params) => <MDInput {...params} label="Estado Civil" />}
                  value={getMaritalACValue(person.maritalStatus)}
                  onChange={(e: any, newValue: any) => {
                    setPerson({ ...person, maritalStatus: newValue.value });
                  }}
                />
              </Box>

              <Box sx={{ display: "flex", mb: 2, alignItems: "flex-start" }}>
                <InputRegistration
                  value={person.registration}
                  onChange={(e: any) =>
                    setPerson({ ...person, registration: e.target.value.replace(/[^0-9-]/g, "") })
                  }
                />

                <Autocomplete
                  disablePortal
                  options={situationOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa" }}
                  renderInput={(params) => <MDInput {...params} label="Situação" />}
                  value={getSituationACValue(person.situation)}
                  onChange={(e: any, newValue: any) => {
                    setPerson({ ...person, situation: newValue.value });
                  }}
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="Naturalidade"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={person.birthPlace}
                  onChange={(e: any) => setPerson({ ...person, birthPlace: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Nacionalidade"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={person.nationality}
                  onChange={(e: any) => setPerson({ ...person, nationality: e.target.value })}
                />
                <Autocomplete
                  disablePortal
                  options={genderOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa" }}
                  renderInput={(params) => <MDInput {...params} label="Sexo" />}
                  value={getGenderACValue(person.gender)}
                  onChange={(e: any, newValue: any) => {
                    setPerson({ ...person, gender: newValue.value });
                  }}
                />
              </Box>
            </Card>

            <Card sx={{ p: 3, mt: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Contato
              </MDTypography>
              <MDInput
                variant="outlined"
                label="E-mail"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={person.email}
                onChange={(e: any) => setPerson({ ...person, email: e.target.value })}
                type="email"
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="Telefone"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={person.phone1}
                  onChange={(e: any) => setPerson({ ...person, phone1: e.target.value })}
                  type="tel"
                />
                <MDInput
                  variant="outlined"
                  label="Celular"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={person.phone2}
                  onChange={(e: any) => setPerson({ ...person, phone2: e.target.value })}
                  type="tel"
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Endereço
              </MDTypography>
              <MDInput
                variant="outlined"
                label="Logradouro"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={address.street}
                onChange={(e: any) => setAddress({ ...address, street: e.target.value })}
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="Número"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.number}
                  onChange={(e: any) => setAddress({ ...address, number: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Bairro"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={address.neighborhood}
                  onChange={(e: any) =>
                    setAddress({
                      ...address,
                      neighborhood: e.target.value,
                    })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="CEP"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.zipCode}
                  onChange={(e: any) => setAddress({ ...address, zipCode: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Estado"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.state}
                  onChange={(e: any) => setAddress({ ...address, state: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Cidade"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={address.city}
                  onChange={(e: any) => setAddress({ ...address, city: e.target.value })}
                />
              </Box>
              <MDInput
                variant="outlined"
                label="Complemento"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={address.complement}
                onChange={(e: any) => setAddress({ ...address, complement: e.target.value })}
              />
            </Card>

            <Card sx={{ p: 3, mt: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Sistema
              </MDTypography>
              <FormControlLabel
                control={
                  <Switch
                    checked={access.active}
                    onChange={(e: any) => setAccess({ ...access, active: e.target.checked })}
                  />
                }
                label={"Acesso ao Sistema"}
              />
              {access.active && (
                <MDBox mt={2}>
                  <MDInput
                    variant="outlined"
                    label={access.password ? "Nova senha" : "Senha (inalterada)"}
                    sx={{ bgcolor: "#fafafa", mb: 2 }}
                    fullWidth
                    value={access.password}
                    onChange={(e: any) => setAccess({ ...access, password: e.target.value })}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={access.needsPasswordChange}
                        onChange={(e: any) =>
                          setAccess({ ...access, needsPasswordChange: e.target.checked })
                        }
                      />
                    }
                    label="Forçar redefinição de senha no próximo login"
                  />
                </MDBox>
              )}
              {can("UPDATE_PERSON_USERGROUPS") && (
                <UserGroupsSelector
                  userGroups={userGroups}
                  setUserGroups={setUserGroups}
                  sx={{ bgcolor: "#fafafa", mt: 2 }}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        <MDBox mt={2}>
          <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {can("DELETE_PERSON") && <DeleteButton pushAlert={pushAlert} person={person} />}

            {can("UPDATE_PERSON") && (
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={updatePersonLoading}
              >
                Salvar dados
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
