// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme, styled } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { maskcnpj, maskcpf } from "utils/mask";

const GET_PROVIDERS = gql`
  query Providers($searchInput: String, $take: Float, $skip: Float) {
    providers(searchInput: $searchInput, take: $take, skip: $skip) {
      total
      items {
        id
        name
        documentType
        document
        companyName
        description
        phone1
        phone2
        email
        status
        createdAt
        updatedAt
        specialty {
          id
          name
        }
        address {
          # id
          city
          street
          number
        }
      }
    }
  }
`;

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function TypeBadge({ type }: { type: string }) {
  switch (type) {
    case "HOLDER":
      return <MDBadge badgeContent="TITULAR" color="success" container />;
    case "DEPENDENT":
      return <MDBadge badgeContent="DEPENDENTE" color="warning" container />;
    default:
      return <MDBadge badgeContent={type} color="light" container />;
  }
}

function StatusBadge({ status }: { status: string }) {
  switch (status) {
    case "ACTIVE":
      return <MDBadge badgeContent="Ativo" color="success" container />;
    case "INACTIVE":
      return <MDBadge badgeContent="Inativo" color="secondary" container />;
    default:
      return <MDBadge badgeContent={status} color="light" container />;
  }
}

function Providers() {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [searchError, setSearchError] = useState<null | Error>(null);
  const [count, setCount] = useState(0);

  const {
    data,
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_PROVIDERS, {
    variables: {
      searchInput,
      take,
      skip,
    },
  });

  useEffect(() => {
    if (data?.providers?.total >= 0) {
      setCount(data?.providers?.total);
    }
  }, [data]);

  useEffect(() => {
    setSearchError(ApolloError);
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {!searchError || (
          <MDAlert sx={{ mb: 5 }} color="error" dismissible onClose={() => setSearchError(null)}>
            {searchError.message}
          </MDAlert>
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Prestadores de serviço{" "}
                    {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>
                  <MDButton
                    sx={{
                      ml: "auto",
                      px: 3,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="text"
                    color="white"
                    // onClick={() => setOpenModalNewProvider(true)}
                    to="/prestador/cadastrar"
                    component={Link}
                  >
                    <Icon>add</Icon>&nbsp; Add prestador de serviço
                  </MDButton>
                  <Divider orientation="vertical" light sx={{ height: 40, mr: 3, ml: 0 }} />
                  <Card
                    sx={{
                      px: 1.5,
                      borderRadius: "6px",
                    }}
                  >
                    <MDInput
                      label="Procurar"
                      type="search"
                      sx={{
                        minWidth: 200,
                        "& .MuiInput-underline:before": { border: "none" },
                        "& .MuiInput-underline:after": { border: "none" },
                        "& .MuiInput-underline:hover": { border: "none" },
                        "& .MuiInputBase-root:hover:before": { border: "none" },
                      }}
                      variant="standard"
                      value={searchInput}
                      onChange={(e: any) => {
                        setSearchInput(e.target.value);
                        setPage(0);
                        setSkip(0);
                      }}
                    />
                  </Card>
                </Stack>
              </MDBox>

              <MDBox sx={{ p: 3 }}>
                <TableContainer component="div" sx={{ boxShadow: "none" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Especialidade</TableCell>
                        <TableCell>Município</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell>Endereço</TableCell>

                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data?.providers?.items ?? []).map((provider: any) => (
                        <TableRow
                          key={provider.id}
                          sx={{
                            ":hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {provider.name}
                          </TableCell>
                          <TableCell>{provider.specialty?.name}</TableCell>
                          <TableCell>{provider.address?.city}</TableCell>
                          <TableCell>{provider.phone1}</TableCell>
                          <TableCell>
                            {[provider.address?.street, provider.address?.number]
                              .filter((fragment) => !!fragment)
                              .join(", ")}
                          </TableCell>
                          {/* <TableCell>{provider.documentType}</TableCell>
                          <TableCell>
                            {provider.documentType === "CNPJ"
                              ? maskcnpj(provider.document)
                              : maskcpf(provider.document)}
                          </TableCell> */}

                          <TableCell>
                            <StatusBadge status={provider.status} />
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              iconOnly
                              component={Link}
                              to={`/prestador/${provider.id}`}
                            >
                              <Icon>arrow_forward_ios</Icon>
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* {loading && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                              <CircularProgress />
                            </MDBox>
                          </TableCell>
                        </TableRow>
                      )} */}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={6}
                          count={count}
                          rowsPerPage={take}
                          page={page}
                          labelRowsPerPage="Registros por página"
                          labelDisplayedRows={function defaultLabelDisplayedRows({
                            from,
                            to,
                            count,
                          }) {
                            return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                          }}
                          onPageChange={(event, newPage) => {
                            setPage(newPage);
                            setSkip(newPage * take);
                          }}
                          onRowsPerPageChange={(event) => {
                            setTake(parseInt(event.target.value, 10));
                            setPage(0);
                            setSkip(0);
                          }}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Providers;
