import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import rememberMeReducer from "./rememberme";
import sessionReducer from "./session";

const reducers = combineReducers({
  session: sessionReducer,
  rememberMe: rememberMeReducer,
});

const store = configureStore({
  reducer: reducers,
  // devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const appDispatch = store.dispatch;

export const persistor = persistStore(store);
