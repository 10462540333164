import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import routes from "routes";

export default function useRoutes() {
  const permissions = useSelector((state: RootState) => state.session.user.permissions);
  const memoRoutes = useMemo(() => {
    return routes;
  }, [permissions]);

  return memoRoutes;
}
