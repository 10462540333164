export function getAge(nascimento: string) {
  let today = new Date();
  let birthDate = new Date(nascimento);
  let years = today.getFullYear() - birthDate.getFullYear();
  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() == birthDate.getMonth() && today.getDate() < birthDate.getDate())
  ) {
    years--;
  }
  return years;
}
