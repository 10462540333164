// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme, styled } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { maskcnpj, maskcpf } from "utils/mask";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBRL } from "utils/mask";
import StatusBadge from "../components/StatusBadge";

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default function GuidesTable({
  items,
  loading,
  count,
  take,
  page,
  setPage,
  setSkip,
  setTake,
}: any) {
  return (
    <TableContainer component="div" sx={{ boxShadow: "none" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nº</TableCell>
            <TableCell>Beneficiário</TableCell>
            <TableCell>Prestador</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Data</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(items ?? []).map((guide: any) => (
            <TableRow
              key={guide.id}
              sx={{
                ":hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <TableCell>{guide?.number}</TableCell>
              <TableCell component="th" scope="row">
                <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                  {guide?.associate.person.name}
                </MDTypography>
                {!guide?.associate.holder?.person.name || (
                  <MDTypography variant="button">
                    Titular: {guide.associate.holder?.person.name}
                  </MDTypography>
                )}
              </TableCell>
              <TableCell>{guide?.items[0]?.service?.provider?.name}</TableCell>
              <TableCell>
                <MDTypography
                  variant="button"
                  color="primary"
                  sx={{
                    textDecoration: "line-through",
                    // fontWeight: "bold"
                  }}
                >
                  {maskBRL(
                    guide.items.reduce((ac: number, item: any) => (item.price ?? 0) + ac, 0)
                  )}
                </MDTypography>

                <MDTypography
                  variant="body2"
                  color="success"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {maskBRL(
                    guide.items.reduce(
                      (ac: number, item: any) => (item.price ?? 0) * item.coefficient + ac,
                      0
                    )
                  )}
                </MDTypography>
              </TableCell>
              <TableCell>
                <StatusBadge status={guide.status} />
              </TableCell>
              <TableCell>{getLocaleDateString(guide.date)}</TableCell>

              <TableCell>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  component={Link}
                  to={`/guia/${guide.id}`}
                >
                  <Icon>arrow_forward_ios</Icon>
                </MDButton>
              </TableCell>
            </TableRow>
          ))}
          {/* {loading && (
            <TableRow>
              <TableCell colSpan={7}>
                <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </MDBox>
              </TableCell>
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={6}
              count={count}
              rowsPerPage={take}
              page={page}
              labelRowsPerPage="Registros por página"
              labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
              }}
              onPageChange={(event, newPage) => {
                setPage(newPage);
                setSkip(newPage * take);
              }}
              onRowsPerPageChange={(event) => {
                setTake(parseInt(event.target.value, 10));
                setPage(0);
                setSkip(0);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
