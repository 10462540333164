import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import { useEffect } from "react";
import AlertBox, { AlertObject } from "components/AlertBox";
import TableMontlyFees from "./components/TableMontlyFees";
// import TabExpenses from "./components/TabExpenses";
import StatisticsCards from "./components/StatisticsCards";
import { codeMonth } from "utils/codeMonth";
import getMonthObjects from "utils/getMonthObjects";

export default function MontlyFees() {
  const [month, setMonth] = useState<number>(
    codeMonth(new Date().getFullYear(), new Date().getMonth() + 1)
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <MDTypography variant="h5" gutterBottom mb={3}>
          Financeiro - Mensalidades
        </MDTypography>

        <MDBox mt={-7} sx={{ width: "100%", display: "flex" }}>
          <FormControl sx={{ bgcolor: "#fafafa", width: 400, ml: "auto" }}>
            <InputLabel id="select-type-label">Mês</InputLabel>
            <Select
              labelId="select-type-label"
              value={month}
              label="Mês"
              onChange={(e: any) => setMonth(e.target.value)}
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                },
              }}
            >
              {getMonthObjects(24).map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
        <Divider sx={{ my: 3 }} />

        <MDBox>
          <StatisticsCards month={month} />
        </MDBox>
        <Divider sx={{ my: 3 }} />

        <MDBox sx={{ my: 3 }}>
          <TableMontlyFees month={month} />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
