import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

const DELETE_ASSOCIATE_CREDIT = gql`
  mutation DeleteAssociateCredit($associateCreditId: String!) {
    deleteAssociateCredit(associateCreditId: $associateCreditId)
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalDeleteCredit({ creditToDelete, setCreditToDelete }: any) {
  const [deleteCreditError, setDeleteCreditError] = useState<null | Error>(null);

  const [deleteCreditMutation, { error: ApolloDeleteCreditError, loading: loadingDeleteCredit }] =
    useMutation(DELETE_ASSOCIATE_CREDIT, {
      onCompleted: () => {
        handlerCloseModalEdit();
      },
      refetchQueries: ["AssociateCredits", "AssociateFinancial"],
    });

  function handlerDeleteCredit() {
    deleteCreditMutation({
      variables: {
        associateCreditId: creditToDelete.id,
      },
    });
  }

  function handlerCloseModalEdit() {
    setCreditToDelete(null);
  }

  useEffect(() => {
    setDeleteCreditError(ApolloDeleteCreditError);
  }, [ApolloDeleteCreditError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={!!creditToDelete}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Atenção
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDBox
            sx={{
              py: 3,
              textAlign: "center",
            }}
          >
            <MDTypography variant="body1" color="dark" mb={1}>
              Você tem certeza que deseja excluir?
            </MDTypography>
            <MDTypography variant="body1" color="dark" mb={1}>
              Esta ação é irreversível!
            </MDTypography>
          </MDBox>
        </DialogContent>
        {!deleteCreditError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setDeleteCreditError(null)}
          >
            {deleteCreditError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerDeleteCredit}
            variant="gradient"
            color="error"
            sx={{ m: 1 }}
            loading={loadingDeleteCredit}
          >
            Excluir
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
