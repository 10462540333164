// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
import { maskBRL, maskcpf } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import { decodeMonth } from "utils/codeMonth";
import { Link } from "react-router-dom";
import ModalDeleteProviderPayment from "./components/ModalDeleteProviderPayment";
import { ProviderPaymentsReportInput } from "hooks/useReport/reports/providerPayments";
import useReport from "hooks/useReport/useReport";

const MONTHS_LABELS = {
  1: "JAN",
  2: "FEV",
  3: "MAR",
  4: "ABR",
  5: "MAI",
  6: "JUN",
  7: "JUL",
  8: "AGO",
  9: "SET",
  10: "OUT",
  11: "NOV",
  12: "DEZ",
} as {
  [key: number]: string;
};

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const GET_PROVIDER_PAYMENTS = gql`
  query ProviderPayments(
    $take: Float
    $skip: Float
    $filters: FindProviderPaymentFilters
    $orderBy: FindProviderPaymentOrderBy
  ) {
    providerPayments(take: $take, skip: $skip, filters: $filters, orderBy: $orderBy) {
      total
      items {
        id
        amount
        paymentDescription
        date
        createdAt
        updatedAt
        provider {
          id
          name
        }
        guides {
          id
        }
      }
    }
  }
`;

export default function TableMontlyFees({ month }: { month: number }) {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const {
    data,
    loading,
    // error: ApolloError,
    refetch,
  } = useQuery(GET_PROVIDER_PAYMENTS, {
    variables: {
      filters: {
        search: searchInput,
        month,
      },
      orderBy: {
        date: "desc",
      },
      take,
      skip,
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  useEffect(() => {
    if (data?.providerPayments?.total >= 0) {
      setCount(data?.providerPayments?.total);
    }
  }, [data]);

  // useEffect(() => {
  //   if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  // }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip, month]);

  const decodedActualMonth = decodeMonth(month);
  const decodedNextMonth = decodeMonth(month + 1);

  const actualMonthLabel = MONTHS_LABELS[decodedActualMonth.month] + "/" + decodedActualMonth.year;
  const nextMonthLabel = MONTHS_LABELS[decodedNextMonth.month] + "/" + decodedNextMonth.year;

  const [paymentToDelete, setPaymentToDelete] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [getReport, { loading: loadingReport }] = useReport<ProviderPaymentsReportInput>(
    "providerPaymentsReport",
    { month }
  );

  return (
    <>
      <AlertBox alerts={alerts} />
      {/* <ModalEditRevenue item={revenueToEdit} setItem={setRevenueToEdit} />
      <ModalNewRevenue open={openModalNewRevenue} setOpen={setOpenModalNewRevenue} />
      <ModalDeleteRevenue item={revenueToDelete} setItem={setRevenueToDelete} /> */}
      <ModalDeleteProviderPayment item={paymentToDelete} setItem={setPaymentToDelete} />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} xxl={9}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="dark"
            >
              <Stack direction="row" alignItems="center">
                <MDTypography variant="h6" color="white">
                  Pagamentos às Clínicas {actualMonthLabel}
                  {loading && <CircularProgress color="inherit" size={16} />}
                </MDTypography>
                <Box sx={{ ml: "auto" }} />

                <MDButton
                  variant="text"
                  color="white"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{ ml: 2 }}
                  onClick={handleClick}
                  startIcon={<Icon>menu</Icon>}
                >
                  Ações
                </MDButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <MenuItem component={Link} to="/financeiro/despesas-operacionais/nova">
                    <Icon sx={{ mr: 1 }}>add</Icon>
                    Adicionar Despesa
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} /> */}
                  <MenuItem onClick={() => getReport()} disabled={loadingReport}>
                    <Icon sx={{ mr: 1 }}>file_download</Icon>
                    Exportar
                    {loadingReport && <CircularProgress color="inherit" sx={{ ml: 1 }} size={12} />}
                  </MenuItem>
                </Menu>
                <Divider orientation="vertical" light sx={{ height: 40, mr: 3, ml: 0 }} />

                <Card
                  sx={{
                    px: 1.5,
                    borderRadius: "6px",
                  }}
                >
                  <MDInput
                    label="Procurar"
                    type="search"
                    sx={{
                      minWidth: 200,
                      "& .MuiInput-underline:before": { border: "none" },
                      "& .MuiInput-underline:after": { border: "none" },
                      "& .MuiInput-underline:hover": { border: "none" },
                      "& .MuiInputBase-root:hover:before": { border: "none" },
                    }}
                    variant="standard"
                    value={searchInput}
                    onChange={(e: any) => {
                      setSearchInput(e.target.value);
                      setPage(0);
                      setSkip(0);
                    }}
                  />
                </Card>
              </Stack>
            </MDBox>

            <MDBox sx={{ p: 3 }}>
              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nº</TableCell>
                      <TableCell>Prestador</TableCell>
                      <TableCell>Pagamento</TableCell>
                      <TableCell>Qtd Guias</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data?.providerPayments?.items ?? []).map((item: any) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          ":hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                            {item.id}
                          </MDTypography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                            {item.provider?.name}
                          </MDTypography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                            {item.paymentDescription}
                          </MDTypography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                            {item.guides.length}
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography
                            variant="body2"
                            sx={{ fontWeight: "500", color: "error.main" }}
                          >
                            {maskBRL(item.amount)}
                          </MDTypography>
                        </TableCell>

                        <TableCell>{item.date?.split("-").reverse().join("/")}</TableCell>
                        <TableCell sx={{ minWidth: "116px" }}>
                          <MDButton
                            variant="gradient"
                            color="info"
                            iconOnly
                            // onClick={() => setExpenseToEdit(item)}
                            component={Link}
                            to={`/prestador/pagamento/${item?.id}`}
                          >
                            <Icon>arrow_forward_ios</Icon>
                          </MDButton>

                          <MDButton
                            variant="gradient"
                            color="error"
                            iconOnly
                            onClick={() => setPaymentToDelete(item)}
                            sx={{
                              ml: 1,
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={5}
                        count={count}
                        rowsPerPage={take}
                        page={page}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={function defaultLabelDisplayedRows({
                          from,
                          to,
                          count,
                        }) {
                          return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                        }}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                          setSkip(newPage * take);
                        }}
                        onRowsPerPageChange={(event) => {
                          setTake(parseInt(event.target.value, 10));
                          setPage(0);
                          setSkip(0);
                        }}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
