import { Link, useLocation, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useQuery } from "@apollo/client";
import { CircularProgress, Icon, AppBar, Tabs, Tab } from "@mui/material";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import { useEffect } from "react";
import AlertBox, { AlertObject } from "components/AlertBox";
import TabInfo from "./components/TabInfo";
import TabFiles from "./components/TabFiles";

const GET_GUIDE = gql`
  query Guide($guideId: String!) {
    guide(id: $guideId) {
      id
      status
      date
      createdAt
      updatedAt
      associate {
        id
        type
        holder {
          id
          person {
            id
            name
            cpf
          }
        }
        person {
          id
          name
          cpf
        }
        plan {
          id
          name
          coefficient
          price
        }
      }
      items {
        id
        price
        coefficient
        service {
          id
          name
          provider {
            id
            name
          }
        }
      }
    }
  }
`;

export default function ViewGuide() {
  const { guideId } = useParams();

  const location = useLocation();
  const anchor = location.hash;
  const [tabValue, setTabValue] = useState(anchor || "#dados");
  useEffect(() => {
    if (anchor) {
      setTabValue(anchor);
    } else {
      setTabValue("#dados");
    }
  }, [anchor]);

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const handleSetTabValue = (event: any, newValue: any) => setTabValue(newValue);

  // -----------------------------------------------------------------------------

  const {
    data: { guide } = {},
    loading,
    error: getGuideError,
  } = useQuery(GET_GUIDE, {
    variables: {
      guideId: guideId,
    },
  });

  useEffect(() => {
    if (getGuideError) {
      pushAlert({ type: "error", message: getGuideError.message });
    }
  }, [getGuideError]);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Ver guia" />

      <MDBox pt={2} pb={3}>
        <AlertBox alerts={alerts} />

        <MDTypography variant="h5" gutterBottom mb={3}>
          Guia de Autorização
          {loading && <CircularProgress color="inherit" size={16} />}
        </MDTypography>

        <MDBox sx={{ mb: 3 }}>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleSetTabValue}>
              <Tab
                label="Dados"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    feed
                  </Icon>
                }
                component={Link}
                to="#dados"
                replace={true}
                value="#dados"
              />
              <Tab
                label="Arquivos"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    insert_drive_file
                  </Icon>
                }
                disabled={guide?.type === "DEPENDENT"}
                component={Link}
                to="#arquivos"
                replace={true}
                value="#arquivos"
              />
            </Tabs>
          </AppBar>
        </MDBox>

        {tabValue === "#dados" && <TabInfo guide={guide} />}
        {tabValue === "#arquivos" && <TabFiles guide={guide} />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
