import { useNavigate } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBirthDate, maskBRL, maskcpf } from "utils/mask";

import { useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";

const GET_PLANS = gql`
  query GetPlans($take: Float) {
    plans(take: $take) {
      total
      items {
        id
        name
        description
        price
        coefficient
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_PEOPLE = gql`
  query PeopleNonAssociate {
    peopleNonAssociate {
      id
      name
      cpf
    }
  }
`;

const GET_HOLDERS = gql`
  query Associates($filters: FindAssociatesFilters, $take: Float) {
    associates(filters: $filters, take: $take) {
      total
      items {
        id
        person {
          id
          name
          cpf
        }
      }
    }
  }
`;

const CREATE_ASSOCIATE = gql`
  mutation CreateAssociate($data: CreateAssociateInput!) {
    createAssociate(data: $data) {
      id
    }
  }
`;

const initialData = {
  personId: "",
  holderId: "",
  planId: "",
  type: "",
  admissionDate: getLocaleDateString(new Date()),
  kinship: "",
  folder: "",
};

export default function NewAssociate() {
  const [peopleOptions, setPeopleOptions] = useState<any[]>([]);

  const [selectedPerson, setSelectedPerson] = useState<any>(null);
  function getPersonACValue(personId: number) {
    if (!personId) return null;
    return peopleOptions.find((s) => s.value === personId);
  }

  const [holdersOptions, setHoldersOptions] = useState<any[]>([]);
  const [selectedHolder, setSelectedHolder] = useState<any>(null);
  function getHolderACValue(holderId: number) {
    if (!holderId) return null;
    return holdersOptions.find((s) => s.value === holderId);
  }

  const {
    data: { peopleNonAssociate: people } = {},
    // loading,
    // error: ApolloError,
    // refetch,
  } = useQuery(GET_PEOPLE, {
    onCompleted: (data) => {
      setPeopleOptions(
        data.peopleNonAssociate.map((p: any) => ({
          value: p.id,
          label: `${p.name} | ${maskcpf(p.cpf)}`,
        }))
      );
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  const {
    data: { associates: holders } = {},
    // loading,
    // error: ApolloError,
    // refetch,
  } = useQuery(GET_HOLDERS, {
    variables: {
      take: 99999,
      filters: {
        types: ["HOLDER"],
      },
    },
    onCompleted: (data) => {
      setHoldersOptions(
        data.associates.items.map((h: any) => ({
          value: h.id,
          label: `${h.person.name} | ${maskcpf(h.person.cpf)}`,
        }))
      );
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  // useEffect(() => {
  //   refetch();
  // }, []);

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [data, setData] = useState(initialData);

  function clearForm() {
    setData(initialData);
  }

  const navigate = useNavigate();

  const [createAssociateMutation, { loading: createAssociateLoading }] = useMutation(
    CREATE_ASSOCIATE,
    {
      onCompleted: (data) => {
        pushAlert({ type: "success", message: "Associado cadastrado com sucesso!" });

        const associateId = data.createAssociate.id;
        setTimeout(() => {
          clearForm();
          navigate(`/associado/${associateId}`, { replace: true });
        }, 500);
      },
      onError(error) {
        pushAlert({ type: "error", message: error.message });
      },
    }
  );

  const { data: plansData } = useQuery(GET_PLANS, {
    variables: {
      take: 100,
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  function handlerSubmit(event: any) {
    event.preventDefault();
    const admissionDate = data.admissionDate.split("/").reverse().join("-");

    createAssociateMutation({
      variables: {
        data: {
          personId: selectedPerson?.id,
          type: data.type,
          holderId: selectedHolder?.id,
          planId: data.planId,
          admissionDate,
          kinship: data.kinship,
          folder: data.folder ? parseInt(data.folder) : undefined,
        },
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AlertBox alerts={alerts} />
        <Grid container spacing={4} component="form" role="form" onSubmit={handlerSubmit}>
          {/* ----- */}
          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={2}>
                Novo Associado
              </MDTypography>

              {/* <MDInput
                variant="outlined"
                label="CPF"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={data.personCPF}
                onChange={(e: any) => setData({ ...data, personCPF: maskcpf(e.target.value) })}
                inputProps={{ maxLength: 14 }}
                required
              /> */}

              <Autocomplete
                disablePortal
                options={peopleOptions}
                fullWidth
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                renderInput={(params) => <MDInput {...params} label="Pessoa" />}
                value={getPersonACValue(selectedPerson?.id)}
                onChange={(e: any, newValue: any) => {
                  setSelectedPerson(people?.find((a: any) => a.id === newValue?.value));
                }}
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <FormControl fullWidth sx={{ bgcolor: "#fafafa", flex: 2 }} required>
                  <InputLabel id="select-type-label">Tipo</InputLabel>
                  <Select
                    labelId="select-type-label"
                    value={data.type}
                    label="Tipo"
                    onChange={(e: any) => setData({ ...data, type: e.target.value })}
                    required
                  >
                    <MenuItem value="HOLDER">TITULAR</MenuItem>
                    <MenuItem value="DEPENDENT">DEPENDENTE</MenuItem>
                  </Select>
                </FormControl>

                {data.type === "HOLDER" && (
                  <MDInput
                    variant="outlined"
                    label="Pasta"
                    sx={{ bgcolor: "#fafafa", flex: 1, ml: 2 }}
                    fullWidth
                    value={data.folder}
                    onChange={(e: any) =>
                      setData({
                        ...data,
                        folder: e.target.value,
                      })
                    }
                  />
                )}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <FormControl fullWidth sx={{ bgcolor: "#fafafa", mr: 2 }} required>
                  <InputLabel id="select-plan-label">Plano</InputLabel>
                  <Select
                    labelId="select-plan-label"
                    value={data.planId}
                    label="Sexo"
                    onChange={(e: any) => setData({ ...data, planId: e.target.value })}
                  >
                    {plansData?.plans.items.map((plan: any) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.name} - {maskBRL(plan.price)} - {plan.coefficient * 100}%
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <MDInput
                  variant="outlined"
                  label="Data de Adesão"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  required
                  value={data.admissionDate}
                  inputProps={{ maxLength: 10, minLength: 10 }}
                  onChange={(e: any) =>
                    setData({
                      ...data,
                      admissionDate: maskBirthDate(e.target.value),
                    })
                  }
                />
              </Box>

              {data.type === "DEPENDENT" && (
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Autocomplete
                    disablePortal
                    options={holdersOptions}
                    fullWidth
                    sx={{ bgcolor: "#fafafa", mr: 2 }}
                    renderInput={(params) => <MDInput {...params} label="Titular" />}
                    value={getHolderACValue(selectedHolder?.id)}
                    onChange={(e: any, newValue: any) => {
                      setSelectedHolder(
                        holders?.items?.find((h: any) => h?.id === newValue?.value)
                      );
                    }}
                  />

                  {/* <MDInput
                    variant="outlined"
                    label="CPF do Titular"
                    sx={{ bgcolor: "#fafafa", mr: 2 }}
                    fullWidth
                    value={data.holderCPF}
                    onChange={(e: any) =>
                      setData({
                        ...data,
                        holderCPF: maskcpf(e.target.value),
                      })
                    }
                    inputProps={{ maxLength: 14 }}
                    required
                  /> */}
                  <FormControl fullWidth sx={{ bgcolor: "#fafafa" }}>
                    <InputLabel id="select-type-label">Parentesco</InputLabel>
                    <Select
                      labelId="select-type-label"
                      value={data.kinship}
                      label="Parentesco"
                      onChange={(e: any) => setData({ ...data, kinship: e.target.value })}
                    >
                      <MenuItem value="SPOUSE">Cônjuge</MenuItem>
                      <MenuItem value="CHILD">Filho(a)</MenuItem>
                      <MenuItem value="OTHER">Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <MDButton variant="contained" color="secondary" sx={{ mr: 2 }} onClick={clearForm}>
                Limpar
              </MDButton>
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={createAssociateLoading}
              >
                Salvar Dados
              </MDButton>
            </MDBox>
          </Grid>

          {/* <Grid item xs={12} xxl={6}>
            
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
