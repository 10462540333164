import MDBadge from "components/MDBadge";

export default function StatusBadge({ status }: { status: string }) {
  switch (status) {
    case "ACTIVE":
      return <MDBadge badgeContent="Ativo" color="success" container />;
    case "INACTIVE":
      return <MDBadge badgeContent="Inativo" color="secondary" container />;
    default:
      return <MDBadge badgeContent={status} color="light" container />;
  }
}
