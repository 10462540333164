/** 
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// layouts
import Dashboard from "./layouts/dashboard";
import Login from "./layouts/authentication/login";
import Logout from "./layouts/authentication/logout/index";
import ViewPeople from "layouts/administration/people/ViewPeople";
import Associates from "layouts/administration/associates";
import Plans from "layouts/administration/configurations/plans";
import NewAssociate from "./layouts/administration/associates/NewAssociate";
import ViewAssociate from "layouts/administration/associates/ViewAssociate";
import NewPerson from "./layouts/administration/people/NewPerson";
import EditPerson from "layouts/administration/people/EditPerson";
import Providers from "layouts/administration/providers";
import NewProvider from "layouts/administration/providers/NewProvider";
import ViewProvider from "layouts/administration/providers/ViewProvider";
import Guides from "layouts/guides";
import NewGuide from "layouts/guides/NewGuide";
import ViewGuide from "layouts/guides/ViewGuide";
import PrintGuide from "layouts/guides/PrintGuide";
import AuthGuide from "layouts/guides/AuthGuide";
// import Financial from "layouts/administration/financial";
import NewProviderPayment from "layouts/administration/providers/NewProviderPayment";
import ViewProviderPayment from "layouts/administration/providers/ViewProviderPayment";
import MedicalRecord from "layouts/medicalRecord";
import Statements from "layouts/statements";
import OperationalExpenses from "layouts/administration/financial/OperationalExpenses";
import ExternalRevenues from "layouts/administration/financial/ExternalRevenues";
import MontlyFees from "layouts/administration/financial/MontlyFees";
import AssociatesCredits from "layouts/administration/financial/AssociatesCredits";
import ProviderPayments from "layouts/administration/financial/ProviderPayments";
import FinancialOverview from "layouts/administration/financial/Overview";
import NewOperationalExpense from "layouts/administration/financial/OperationalExpenses/NewOperationalExpense";
import ViewOperationalExpense from "layouts/administration/financial/OperationalExpenses/ViewOperationalExpense";
import ProviderSpecialties from "layouts/administration/configurations/providerSpecialties";
import ProviderServiceTypes from "layouts/administration/configurations/providerServiceTypes";
import CustomerView from "layouts/customerView";
import CustomerViewGuide from "layouts/customerView/ViewGuide";
import OperationalExpenseTypes from "layouts/administration/configurations/operationalExpenseTypes";
import UserGroups from "layouts/administration/configurations/userGroups";
import Appointments from "layouts/appointments";
import AppointmentOwnerGroups from "layouts/administration/configurations/appointmentOwnerGroups";
import AppointmentOwners from "layouts/administration/configurations/appointmentOwners";

const routes = [
  {
    type: "collapse",
    noCollapse: true,
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
    activeWhenUrlEquals: "/",
    activeWhenUrlStartWith: "/dashboard",
    permissions: ["READ_GUIDE_LIST"] as string[],
  },
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "Minhas Guias",
  //   key: "minhas-guias",
  //   icon: <Icon fontSize="small">note_add</Icon>,
  //   route: "/minhas-guias",
  //   component: <CustomerView />,
  //   permissions: ["READ_GUIDE_SELF"] as string[],
  //   activeWhenUrlStartWith: "/minhas-guias",
  // },
  // {
  //   name: "Ver Guia",
  //   key: "ver-minha-guia",
  //   route: "/minhas-guias/guia/:guideId",
  //   component: <CustomerViewGuide />,
  // },
  {
    type: "divider",
    key: "divider-" + Math.random(),
    permissions: ["READ_GUIDE_LIST"] as string[],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Guias",
    key: "guias",
    icon: <Icon fontSize="small">note_add</Icon>,
    route: "/guias",
    component: <Guides />,
    permissions: ["READ_GUIDE_LIST"] as string[],
    activeWhenUrlStartWith: "/guia",
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Agendamentos",
    key: "agendamentos",
    icon: <Icon fontSize="small">date_range</Icon>,
    route: "/agendamentos",
    component: <Appointments />,
    permissions: ["READ_APPOINTMENT_LIST"] as string[],
    activeWhenUrlStartWith: "/agendamento",
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Prontuário",
    key: "prontuario",
    icon: <Icon fontSize="small">medical_information</Icon>,
    route: "/prontuario",
    component: <MedicalRecord />,
    permissions: ["READ_MEDICALAPPOINTMENT_LIST"] as string[],
    activeWhenUrlStartWith: "/prontuario",
  },

  {
    type: "collapse",
    noCollapse: true,
    name: "Declarações",
    key: "declaracoes",
    icon: <Icon fontSize="small">text_snippet</Icon>,
    route: "/declaracoes",
    component: <Statements />,
    permissions: ["READ_GUIDE_LIST"] as string[],
    activeWhenUrlStartWith: "/declaracoes",
  },
  {
    type: "divider",
    key: "divider-" + Math.random(),
    permissions: ["READ_ASSOCIATE_LIST"] as string[],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Associados",
    key: "associados",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/associados",
    component: <Associates />,
    permissions: ["READ_ASSOCIATE_LIST"] as string[],
    activeWhenUrlStartWith: "/associado",
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Pessoas",
    key: "pessoas",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: "/pessoas",
    component: <ViewPeople />,
    permissions: ["READ_PERSON_LIST"],
    activeWhenUrlStartWith: "/pessoa",
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Prestadores de Serviço",
    key: "prestadores",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/prestadores",
    component: <Providers />,
    permissions: ["READ_PROVIDER_LIST"] as string[],
    activeWhenUrlStartWith: "/prestador",
  },
  {
    type: "collapse",
    name: "Financeiro",
    key: "financeiro",
    icon: <Icon fontSize="small">attach_money</Icon>,
    permissions: ["READ_FINANCIALSTATISTICS"] as string[],
    collapse: [
      {
        type: "collapse",
        name: "Visão Geral",
        key: "financeiro_visao_geral",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/financeiro/visao-geral",
        component: <FinancialOverview />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/financeiro/visao-geral",
      },
      {
        type: "collapse",
        name: "Mensalidades",
        key: "financeiro_mensalidades",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/financeiro/mensalidades",
        component: <MontlyFees />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/financeiro/mensalidades",
      },
      {
        type: "collapse",
        name: "Receitas de Guias",
        key: "receitas_guias",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/financeiro/receitas-guias",
        component: <AssociatesCredits />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/financeiro/receitas-guias",
      },
      {
        type: "collapse",
        name: "Receitas Externas",
        key: "receitas_externas",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/financeiro/receitas-externas",
        component: <ExternalRevenues />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/financeiro/receitas-externas",
      },
      {
        type: "collapse",
        name: "Desp. Operacionais",
        key: "despesas_operacionais",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/financeiro/despesas-operacionais",
        component: <OperationalExpenses />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/financeiro/despesas-operacionais",
      },
      {
        type: "collapse",
        name: "Pgtos Clinicas",
        key: "financeiro_pgtos_clinicas",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/financeiro/pagamentos-clinicas",
        component: <ProviderPayments />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/financeiro/pagamentos-clinicas",
      },
    ],
  },

  {
    type: "collapse",
    name: "Configurações",
    key: "configuracoes",
    icon: <Icon fontSize="small">tune</Icon>,
    permissions: ["READ_PLAN_LIST"] as string[],
    collapse: [
      {
        type: "collapse",
        name: "Planos",
        key: "planos",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/planos",
        component: <Plans />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/configuracoes/planos",
      },
      {
        type: "collapse",
        name: "Tipos de Serviços",
        key: "tipos-de-servicos",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/prestadores/tipos-de-servicos",
        component: <ProviderServiceTypes />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/configuracoes/prestadores/tipos-de-servicos",
      },
      {
        type: "collapse",
        name: "Especialidades",
        key: "Especialidades",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/prestadores/especialidades",
        component: <ProviderSpecialties />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/configuracoes/prestadores/especialidades",
      },
      {
        type: "collapse",
        name: "Tipos de Despesa Op...",
        key: "tipos-de-despesa-operacional",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/despesas-operacionais/tipos",
        component: <OperationalExpenseTypes />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/configuracoes/despesas-operacionais/tipos",
      },
      {
        type: "collapse",
        name: "Níveis de Acesso",
        key: "niveis-de-acesso",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/niveis-acesso",
        component: <UserGroups />,
        permissions: [] as string[],
        activeWhenUrlStartWith: "/configuracoes/niveis-acesso",
      },
      {
        type: "collapse",
        name: "Agendas",
        key: "agendas",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/agendas",
        component: <AppointmentOwners />,
        permissions: ["READ_APPOINTMENTOWNER_LIST"] as string[],
        activeWhenUrlStartWith: "/configuracoes/agendas",
      },
      {
        type: "collapse",
        name: "Grupos de Agenda",
        key: "grupos-de-agenda",
        icon: <Icon fontSize="small">remove</Icon>,
        route: "/configuracoes/grupos-agenda",
        component: <AppointmentOwnerGroups />,
        permissions: ["READ_APPOINTMENTOWNERGROUP_LIST"] as string[],
        activeWhenUrlStartWith: "/configuracoes/grupos-agenda",
      },
    ],
  },

  {
    type: "divider",
    key: "divider-3",
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Sair",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
  },
  //==========================
  //====== Fora do menu ======
  //==========================
  {
    name: "Login",
    key: "login",
    route: "/login",
    private: false,
    component: <Login />,
  },
  {
    name: "Cadastrar pessoa",
    key: "nova-pessoa",
    route: "/pessoa/cadastrar",
    component: <NewPerson />,
  },
  {
    name: "Editar pessoa",
    key: "editar-pessoa",
    route: "/pessoa/:personId",
    component: <EditPerson />,
  },
  {
    name: "Novo Associado",
    key: "novo-associado",
    route: "/associado/novo",
    component: <NewAssociate />,
  },
  {
    name: "Ver Associado",
    key: "ver-associado",
    route: "/associado/:associateId",
    component: <ViewAssociate />,
  },

  {
    name: "Cadastrar Prestador",
    key: "novo-prestador",
    route: "/prestador/cadastrar",
    component: <NewProvider />,
  },
  {
    name: "Ver Pagamento ao Prestador",
    key: "ver-pagamento-prestador",
    route: "/prestador/pagamento/:providerPaymentId",
    component: <ViewProviderPayment />,
  },
  {
    name: "Novo Pagamento ao Prestador",
    key: "novo-pagamento-prestador",
    route: "/prestador/:providerId/pagamento/novo",
    component: <NewProviderPayment />,
  },
  {
    name: "Ver Prestador",
    key: "ver-associado",
    route: "/prestador/:providerId",
    component: <ViewProvider />,
  },
  {
    name: "Nova Guia",
    key: "nova-guia",
    route: "/guia/nova",
    component: <NewGuide />,
  },
  {
    name: "Imprimir Guia",
    key: "imprimir-guia",
    route: "/guia/:guideId/impressao",
    component: <PrintGuide />,
  },
  {
    name: "Autenticidade da Guia",
    key: "autenticidade-guia",
    route: "/guia/:guideId/autenticidade",
    component: <AuthGuide />,
    private: false,
  },
  {
    name: "Ver Guia",
    key: "ver-guia",
    route: "/guia/:guideId",
    component: <ViewGuide />,
  },
  {
    name: "Adicionar Despesa",
    key: "Adicionar-Despesa",
    route: "/financeiro/despesas-operacionais/nova",
    component: <NewOperationalExpense />,
  },
  {
    name: "Ver Despesa Operacional",
    key: "ver-despesas-operacionais",
    route: "/financeiro/despesas-operacionais/:operationalExpenseId",
    component: <ViewOperationalExpense />,
  },
];

export default routes;
