import { useState, useEffect } from "react";
import { Autocomplete, SxProps, Theme } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import MDInput from "components/MDInput";

const GET = gql`
  query AppointmentOwners {
    appointmentOwners {
      id
      name
      group {
        id
        name
      }
    }
  }
`;

export interface AppointmentOwner {
  id: string;
  name: string;
  group: {
    id: string;
    name: string;
  };
}

export interface AppointmentOwnerSelectorProps {
  ownerId: string;
  setOwner: (appointmentOwner: AppointmentOwner) => void;
  sx?: SxProps<Theme>;
}

export default function OwnerSelector({ ownerId, setOwner, sx }: AppointmentOwnerSelectorProps) {
  const [appointmentOwnerOptions, setOwnerOptions] = useState<AppointmentOwner[]>([]);

  function getOptionValueById(id: string) {
    return appointmentOwnerOptions.find((option) => option.id === id);
  }

  const { loading, data } = useQuery(GET, {
    onError(error) {
      alert(error.message);
    },
  });

  useEffect(() => {
    if (data) {
      const appointmentOwners = [...data.appointmentOwners];
      appointmentOwners.sort(
        (a: AppointmentOwner, b: AppointmentOwner) =>
          a.group?.name.localeCompare(b.group?.name) ?? 1
      );

      setOwnerOptions(appointmentOwners);
      if (appointmentOwners.length > 0 && !ownerId) {
        setOwner(appointmentOwners[0]);
      }
    }
  }, [data]);

  return (
    <Autocomplete
      disablePortal
      options={appointmentOwnerOptions}
      groupBy={(option) => option.group?.name ?? "SEM GRUPO"}
      getOptionLabel={(option) => option.name}
      sx={sx}
      renderInput={(params) => <MDInput {...params} label="Agenda" />}
      value={getOptionValueById(ownerId) ?? null}
      onChange={(e: any, newValue: any) => {
        setOwner(newValue);
      }}
      //   isOptionEqualToValue={(option, value) => option.id === value?.id}
      loading={loading}
      disableClearable
    />
  );
}
