import PageLayout from "components/LayoutContainers/PageLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import GuideInPaper from "./components/GuideInPaper";
import { Box } from "@mui/material";

export default function AuthGuide() {
  const { guideId } = useParams();

  useEffect(() => {
    var metaTag = document.querySelector('meta[name="viewport"]');
    if (metaTag !== null) {
      // 1mm = 3.779527559px
      metaTag.setAttribute("content", "width=560px, initial-scale=1");
    }
  }, []);

  return (
    <PageLayout>
      <Box sx={{ overflow: "auto" }}>
        <GuideInPaper guideId={guideId} />
      </Box>
    </PageLayout>
  );
}
