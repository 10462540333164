// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";
import { maskBRL, maskcpf } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import * as xlsx from "xlsx";

import ModalNewService from "./components/ModalNewService";
import ModalEditService from "./components/ModalEditService";
import ModalImportServices from "./components/ModalImportServices";

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const GET_SERVICES = gql`
  query GetServices($filters: FindServiceFilters, $take: Float, $skip: Float) {
    services(filters: $filters, take: $take, skip: $skip) {
      total
      items {
        id
        name
        price
        description
        providerId
      }
    }
  }
`;

const DELETE_SERVICE = gql`
  mutation DeleteService($serviceId: String!) {
    deleteService(serviceId: $serviceId)
  }
`;

export default function TabServices({ provider }: { provider: any }) {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [openModalNewService, setOpenModalNewService] = useState(false);
  const [openModalEditService, setOpenModalEditService] = useState(false);

  const [selectedService, setSelectedService] = useState<any>(null);

  function handlerOpenModalEdit(service: any) {
    setOpenModalEditService(true);
    setSelectedService(service);
  }

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [queryServices, { data, loading, refetch }] = useLazyQuery(GET_SERVICES, {
    variables: {
      filters: {
        search: searchInput,
        providerId: provider?.id,
      },
      take,
      skip,
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  useEffect(() => {
    if (data?.services?.total >= 0) {
      setCount(data?.services?.total);
    }
  }, [data]);

  useEffect(() => {
    if (provider) {
      queryServices();
    }
  }, [provider]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  function handlerDelete(service: any) {
    deleteServiceMutation({
      variables: {
        serviceId: service.id,
      },
    });
  }

  const [deleteServiceMutation, { loading: loadingDeleteService }] = useMutation(DELETE_SERVICE, {
    refetchQueries: ["GetServices"],
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  const [openModalImportServices, setOpenModalImportServices] = useState(false);

  const [getAllServices, { loading: getAllServicesLoading }] = useLazyQuery(GET_SERVICES, {
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
    variables: {
      take: 999999,
      filters: {
        providerId: provider?.id,
      },
    },
  });

  return (
    <>
      <AlertBox alerts={alerts} />
      <ModalEditService
        {...{ selectedService, setSelectedService, openModalEditService, setOpenModalEditService }}
      />
      <ModalNewService {...{ openModalNewService, setOpenModalNewService, provider }} />
      <ModalImportServices
        open={openModalImportServices}
        setOpen={setOpenModalImportServices}
        provider={provider}
      />
      <MDBox mb={3} display={"flex"}>
        <MDButton
          color="primary"
          variant="gradient"
          onClick={() => setOpenModalImportServices(true)}
          sx={{ ml: "auto" }}
        >
          Importar
        </MDButton>

        <MDButton
          color="secondary"
          variant="gradient"
          onClick={() =>
            getAllServices({
              onCompleted(data) {
                const _services = data?.services?.items.map((s: any) => ({
                  name: s.name,
                  price: s.price / 100,
                  description: s.description,
                }));

                const worksheet = xlsx.utils.json_to_sheet(_services);

                for (let i = 2; i <= _services.length + 1; i++) {
                  const cell = worksheet[`B${i}`];
                  cell.z = "[$R$-416]\\ #,##0.00;[RED]\\-[$R$-416]\\ #,##0.00'";
                }

                worksheet.A1.v = "Nome";
                worksheet.B1.v = "Preço";
                worksheet.C1.v = "Descrição";

                const workbook = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(workbook, worksheet, "Serviços");
                xlsx.writeFile(workbook, `Serviços - ${provider.name}.xlsx`);
              },
            })
          }
          sx={{ ml: 1 }}
          loading={getAllServicesLoading}
        >
          Exportar
        </MDButton>
      </MDBox>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack direction="row" alignItems="center" mb={3}>
              <MDTypography variant="h5" gutterBottom>
                Serviços {loading ? <CircularProgress size={15} /> : null}
              </MDTypography>
              <MDButton
                sx={{
                  ml: "auto",
                  px: 3,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="text"
                color="secondary"
                onClick={() => setOpenModalNewService(true)}
                // component={Link}
                // to="/pessoa/cadastrar"
              >
                <Icon>add</Icon>&nbsp; Add Serviço
              </MDButton>
              <Divider orientation="vertical" sx={{ height: 40, mr: 3, ml: 0 }} />
              <MDInput
                label="Procurar"
                type="search"
                sx={{
                  minWidth: 200,
                }}
                value={searchInput}
                onChange={(e: any) => {
                  setSearchInput(e.target.value);
                  setPage(0);
                  setSkip(0);
                }}
              />
            </Stack>

            <MDBox sx={{ mb: -2 }}>
              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Preço</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data?.services.items ?? []).map((service: any) => (
                      <TableRow
                        key={service.id}
                        sx={{
                          ":hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {service.name}
                        </TableCell>

                        <TableCell>{maskBRL(service.price)}</TableCell>
                        <TableCell>{service.description}</TableCell>
                        <TableCell>
                          <MDButton
                            variant="gradient"
                            color="warning"
                            iconOnly
                            onClick={() => handlerOpenModalEdit(service)}
                          >
                            <Icon>edit</Icon>
                          </MDButton>
                          <MDButton
                            variant="gradient"
                            color="error"
                            iconOnly
                            onClick={() => handlerDelete(service)}
                            loading={loadingDeleteService}
                            sx={{
                              ml: 1,
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* {true && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                          </MDBox>
                        </TableCell>
                      </TableRow>
                    )} */}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={5}
                        count={count}
                        rowsPerPage={take}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "Registros por página",
                          },
                          native: true,
                        }}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                          setSkip(newPage * take);
                        }}
                        onRowsPerPageChange={(event) => {
                          setTake(parseInt(event.target.value, 10));
                          setPage(0);
                          setSkip(0);
                        }}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
