export function getLocaleDateString(date: string | Date) {
  if (typeof date == "string" && !date.includes("T")) {
    let data = date.split("-").reverse().join("/");
    return data;
  } else {
    let dateObj = new Date(date);
    let ano = ("0" + dateObj.getFullYear()).slice(-4);
    let mes = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let dia = ("0" + dateObj.getDate()).slice(-2);
    return `${dia}/${mes}/${ano}`;
  }
}
