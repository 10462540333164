import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";

const blankEditPlanData = {
  name: "",
  cod: "",
  description: "",
  price: "",
  coefficient: "",
};

const UPDATE_PLAN = gql`
  mutation UpdatePlan($data: UpdatePlanInput!, $planId: String!) {
    updatePlan(data: $data, planId: $planId) {
      id
      name
      description
      price
      coefficient
      createdAt
      updatedAt
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalEditPlan({
  selectedPlan,
  setSelectedPlan,
  openModalEdit,
  setOpenModalEdit,
}: any) {
  const [updatePlanError, setUpdatePlanError] = useState<null | Error>(null);
  const [data, setData] = useState(blankEditPlanData);

  useEffect(() => {
    if (!selectedPlan) return;
    setData({
      name: selectedPlan.name,
      cod: selectedPlan.cod,
      description: selectedPlan.description,
      price: maskBRL(selectedPlan.price),
      coefficient: maskCoefficient(selectedPlan.coefficient),
    });
  }, [selectedPlan]);

  const [updatePlanMutation, { error: ApolloUpdatePlanError, loading: loadingUpdatePlan }] =
    useMutation(UPDATE_PLAN, {
      onCompleted: () => {
        handlerCloseModalEdit();
      },
    });

  function handlerUpdatePlan() {
    if (!data.name) return setUpdatePlanError(new Error("Nome é obrigatório"));

    updatePlanMutation({
      variables: {
        planId: selectedPlan.id,
        data: {
          ...data,
          price: +justNumbers(data.price),
          coefficient: +data.coefficient,
        },
      },
      refetchQueries: ["GetPlans"],
    });
  }

  function handlerCloseModalEdit() {
    setOpenModalEdit(false);
    setSelectedPlan(null);
  }

  useEffect(() => {
    setUpdatePlanError(ApolloUpdatePlanError);
  }, [ApolloUpdatePlanError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={openModalEdit}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Editar plano
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Código"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.cod}
            onChange={(e: any) => setData({ ...data, cod: e.target.value })}
          />
          <MDInput
            variant="outlined"
            label="Valor da mensalidade"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.price}
            onChange={(e: any) => {
              setData({ ...data, price: maskBRL(e.target.value) });
            }}
            required
          />
          <MDInput
            variant="outlined"
            label="Coeficiente"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.coefficient}
            onChange={(e: any) => {
              setData({ ...data, coefficient: maskCoefficientInput(e.target.value) });
            }}
            helperText="O coeficiente é o valor que será multiplicado pelo preço do procedimento médico para obter o valor que será cobrado do associado."
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
          {+data.coefficient >= 2 && (
            <MDAlert color="warning">
              O coeficiente está MUITO alto. Tem certeza que o valor está correto?
            </MDAlert>
          )}
        </DialogContent>
        {!updatePlanError || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setUpdatePlanError(null)}>
            {updatePlanError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerUpdatePlan}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingUpdatePlan}
          >
            Salvar alterações
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
