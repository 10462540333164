import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
import { maskBRL } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import { getLocaleDateString } from "utils/getLocaleDateString";
import getMonthObjects from "utils/getMonthObjects";

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const GET_ASSOCIATE_DEBTS = gql`
  query AssociateDebts($take: Float, $skip: Float, $filters: FindAssociateDebtFilters) {
    associateDebts(take: $take, skip: $skip, filters: $filters) {
      total
      items {
        id
        description
        amount
        date
        createdAt
        updatedAt
        associateId
        guide {
          id
          status
          date
          createdAt
          updatedAt
          associate {
            id
            type
            holder {
              id
              person {
                id
                name
                cpf
              }
            }
            person {
              id
              name
              cpf
            }
          }
          items {
            id
            price
            coefficient
            service {
              id
              name
              provider {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export default function DebtsTable({ associate }: { associate: any }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [month, setMonth] = useState<number | "ALL">("ALL");

  const {
    data: { associateDebts } = {},
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_ASSOCIATE_DEBTS, {
    variables: {
      filters: {
        search: searchInput,
        associateId: associate?.id,
        month: month !== "ALL" ? month : undefined,
      },
      take,
      skip,
    },
  });

  useEffect(() => {
    if (associateDebts?.total >= 0) {
      setCount(associateDebts?.total);
    }
  }, [associateDebts]);

  useEffect(() => {
    if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  return (
    <Card sx={{ p: 3 }}>
      <AlertBox alerts={alerts} />
      <Stack direction="row" alignItems="center" mb={3}>
        <MDTypography variant="h5" gutterBottom>
          Débitos {loading && <CircularProgress color="inherit" size={16} />}
        </MDTypography>
        <FormControl sx={{ bgcolor: "#fafafa", minWidth: 300, ml: 3 }}>
          <InputLabel id="select-type-label">Mês</InputLabel>
          <Select
            labelId="select-type-label"
            value={month}
            label="Mês"
            onChange={(e: any) => setMonth(e.target.value)}
            MenuProps={{
              sx: {
                maxHeight: "250px",
              },
            }}
          >
            <MenuItem key="ALL" value="ALL">
              Todos
            </MenuItem>
            {getMonthObjects(24).map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ ml: "auto" }} />
        <Divider orientation="vertical" sx={{ height: 40, mr: 3, ml: 0 }} />
        <MDInput
          label="Procurar"
          type="search"
          sx={{
            minWidth: 200,
          }}
          value={searchInput}
          onChange={(e: any) => {
            setSearchInput(e.target.value);
            setPage(0);
            setSkip(0);
          }}
        />
      </Stack>

      <MDBox sx={{ mb: -2 }}>
        <TableContainer component="div" sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Descrição</TableCell> */}
                <TableCell>Guia Nº</TableCell>
                <TableCell>Beneficiário</TableCell>
                <TableCell>Prestador</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Data do Débito</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {associateDebts?.items.map(({ id, guide, amount, date }: any) => (
                <TableRow
                  key={id}
                  sx={{
                    ":hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  {/* <TableCell component="th" scope="row">
                    <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                      {item.description}
                    </MDTypography>
                  </TableCell> */}
                  <TableCell>{guide?.number}</TableCell>
                  <TableCell component="th" scope="row">
                    <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                      {guide?.associate.person.name}
                    </MDTypography>
                    {!guide?.associate.holder?.person.name || (
                      <MDTypography variant="button">
                        Titular: {guide.associate.holder?.person.name}
                      </MDTypography>
                    )}
                  </TableCell>
                  <TableCell>{guide?.items[0]?.service?.provider?.name}</TableCell>
                  <TableCell>
                    <MDTypography variant="body2" sx={{ fontWeight: "500", color: "error.main" }}>
                      {maskBRL(amount)}
                    </MDTypography>
                  </TableCell>

                  <TableCell>{getLocaleDateString(date)}</TableCell>

                  {/* <TableCell>
                    <MDButton
                      variant="gradient"
                      color="error"
                      iconOnly
                      onClick={() => setDebtToDelete(item)}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </TableCell> */}
                </TableRow>
              ))}

              {/* {loading && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </MDBox>
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={4}
                  count={count}
                  rowsPerPage={take}
                  page={page}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                    return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                  }}
                  onPageChange={(event, newPage) => {
                    setPage(newPage);
                    setSkip(newPage * take);
                  }}
                  onRowsPerPageChange={(event) => {
                    setTake(parseInt(event.target.value, 10));
                    setPage(0);
                    setSkip(0);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </MDBox>
    </Card>
  );
}
