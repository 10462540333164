// components
import MDBox from "components/MDBox";

import { useState } from "react";
import { Grid, Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { maskBRL } from "utils/mask";
import { AlertObject } from "components/AlertBox";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import { codeMonth, decodeMonth } from "utils/codeMonth";
import getMonthObjects from "utils/getMonthObjects";

const GET_FINANCIAL_STATISTICS = gql`
  query FinancialStatistics($month: Float) {
    externalRevenuesBalance(month: $month)
    associatesCreditsBalance(month: $month)
    operationalExpensesBalance(month: $month)
    providerPaymentsBalance(month: $month)
    associatesMontlyFeesBalance(month: $month)
    associatesBalance(month: $month, retroactive: true)
  }
`;

export default function StatisticsCards() {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [month, setMonth] = useState<number>(
    codeMonth(new Date().getFullYear(), new Date().getMonth() + 1)
  );

  const {
    data,
    loading,
    error: ApolloError,
  } = useQuery(GET_FINANCIAL_STATISTICS, {
    variables: {
      month,
    },
  });

  useEffect(() => {
    if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  }, [ApolloError]);

  const {
    externalRevenuesBalance,
    associatesCreditsBalance,
    operationalExpensesBalance,
    providerPaymentsBalance,
    associatesMontlyFeesBalance,
    associatesBalance,
  } = data ?? {};

  const totalExpenses = operationalExpensesBalance + providerPaymentsBalance;
  const totalRevenues =
    externalRevenuesBalance + associatesCreditsBalance + associatesMontlyFeesBalance;
  const balance = totalRevenues - totalExpenses;

  return (
    <MDBox>
      <MDBox sx={{ width: "100%", display: "flex" }}>
        <FormControl sx={{ bgcolor: "#fafafa", width: 400, ml: "auto" }}>
          <InputLabel id="select-type-label">Mês</InputLabel>
          <Select
            labelId="select-type-label"
            value={month}
            label="Mês"
            onChange={(e: any) => setMonth(e.target.value)}
            MenuProps={{
              sx: {
                maxHeight: "250px",
              },
            }}
          >
            {getMonthObjects(24).map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MDBox>
      {/* <MDButton
        color="secondary"
        variant="gradient"
        sx={{ ml: 1 }}
        loading={getAllAssociatesCreditsLoading}
      >
        Exportar
      </MDButton> */}
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Receitas Totais" }}
              count={maskBRL(totalRevenues)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "success", component: "arrow_upward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Mensalidades" }}
              count={maskBRL(associatesMontlyFeesBalance)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "success", component: "arrow_upward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Receitas Externas" }}
              count={maskBRL(externalRevenuesBalance)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "success", component: "arrow_upward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Receitas de guias" }}
              count={maskBRL(associatesCreditsBalance)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "success", component: "arrow_upward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Despesas Totais" }}
              count={maskBRL(totalExpenses)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "error", component: "arrow_downward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Despesas Operacionais" }}
              count={maskBRL(operationalExpensesBalance)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "warning", component: "arrow_downward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Pagamentos às clínicas" }}
              count={maskBRL(providerPaymentsBalance)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "warning", component: "arrow_downward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Balanço do mês" }}
              count={maskBRL(balance)}
              countColor={balance > 0 ? "success" : balance === 0 ? undefined : "error"}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "info", component: "attach_money" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Saldo devedor dos associados" }}
              count={maskBRL(associatesBalance)}
              countColor={
                associatesBalance > 0 ? "success" : associatesBalance === 0 ? undefined : "error"
              }
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "error", component: "group_remove" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}
