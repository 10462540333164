import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import MDInput from "components/MDInput";

const blankEditProviderServiceTypeData = {
  name: "",
  description: "",
};

const UPDATE_PLAN = gql`
  mutation UpdateProviderServiceType(
    $data: UpdateProviderServiceTypeInput!
    $providerServiceTypeId: String!
  ) {
    updateProviderServiceType(data: $data, providerServiceTypeId: $providerServiceTypeId) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalEdit({
  selectedProviderServiceType,
  setSelectedProviderServiceType,
  openModalEdit,
  setOpenModalEdit,
}: any) {
  const [updateProviderServiceTypeError, setUpdateProviderServiceTypeError] =
    useState<null | Error>(null);
  const [data, setData] = useState(blankEditProviderServiceTypeData);

  useEffect(() => {
    if (!selectedProviderServiceType) return;
    setData({
      name: selectedProviderServiceType.name,
      description: selectedProviderServiceType.description,
    });
  }, [selectedProviderServiceType]);

  const [
    updateProviderServiceTypeMutation,
    { error: ApolloUpdateProviderServiceTypeError, loading: loadingUpdateProviderServiceType },
  ] = useMutation(UPDATE_PLAN, {
    onCompleted: () => {
      handlerCloseModalEdit();
    },
  });

  function handlerUpdateProviderServiceType() {
    if (!data.name) return setUpdateProviderServiceTypeError(new Error("Nome é obrigatório"));

    updateProviderServiceTypeMutation({
      variables: {
        providerServiceTypeId: selectedProviderServiceType.id,
        data: {
          ...data,
        },
      },
    });
  }

  function handlerCloseModalEdit() {
    setOpenModalEdit(false);
    setSelectedProviderServiceType(null);
  }

  useEffect(() => {
    setUpdateProviderServiceTypeError(ApolloUpdateProviderServiceTypeError);
  }, [ApolloUpdateProviderServiceTypeError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={openModalEdit}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Editar Tipo de Serviço
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
        </DialogContent>
        {!updateProviderServiceTypeError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setUpdateProviderServiceTypeError(null)}
          >
            {updateProviderServiceTypeError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerUpdateProviderServiceType}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingUpdateProviderServiceType}
          >
            Salvar alterações
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
