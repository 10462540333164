// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";
import { maskBRL, maskcpf } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import MDDropzone from "components/MDDropzone";
import humanFileSize from "utils/humanFileSize";
import { CBSAUDE_API_URL } from "config/constants";
import store from "redux/store";
import ModalDeleteFile from "./components/ModalDeleteFile";
import ModalEditFile from "./components/ModalEditFile";

const GET_GUIDE_FILES = gql`
  query GetGuideFiles($guideId: String!) {
    guide(id: $guideId) {
      id
      files {
        id
        mime
        size
        filename
        createdAt
        updatedAt
        ext
        url
      }
    }
  }
`;

export default function TabFiles({ guide }: { guide: any }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }
  const token = store.getState().session.token;

  const [fileToDelete, setFileToDelete] = useState<any>(null);
  const [fileToEdit, setFileToEdit] = useState<any>(null);

  const [
    getItems,
    {
      data: { guide: { files } = { files: [] } } = { guide: {} },
      loading,
      error: getGuideFilesError,
      refetch,
    },
  ] = useLazyQuery(GET_GUIDE_FILES);

  useEffect(() => {
    if (guide?.id)
      getItems({
        variables: {
          guideId: guide.id,
        },
      });
  }, [guide]);

  useEffect(() => {
    if (getGuideFilesError) pushAlert({ type: "error", message: getGuideFilesError.message });
  }, [getGuideFilesError]);

  return (
    <>
      <AlertBox alerts={alerts} />
      <ModalDeleteFile {...{ fileToDelete, setFileToDelete }} />
      <ModalEditFile {...{ fileToEdit, setFileToEdit }} />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} xxl={8}>
          <Card sx={{ p: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={2}>
              Arquivos {loading && <CircularProgress color="inherit" size={16} />}
            </MDTypography>

            <MDBox sx={{ mb: 3 }}>
              <MDDropzone
                options={{
                  // addRemoveLinks: true,
                  dictDefaultMessage: "Clique ou solte arquivos aqui para enviar",
                  headers: {
                    Authorization: token ? `Bearer ${token}` : "",
                  },
                  success: () => refetch(),
                  error: (_, message: any) => {
                    pushAlert({ type: "error", message: message?.message ?? message });
                    refetch();
                  },
                }}
                action={`${CBSAUDE_API_URL}/guide/${guide?.id}/file`}
              />
            </MDBox>

            <TableContainer component="div" sx={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead
                  sx={
                    {
                      // backgroundColor: "grey.400",
                    }
                  }
                >
                  {files?.length > 0 && (
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Tamanho</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {files?.map((file: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{
                        ":hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <MDTypography
                          variant="body2"
                          // justifyContent="center"
                          alignItems="center"
                          sx={{
                            fontWeight: "400",
                            display: "flex",
                          }}
                          component={Link}
                          to={file.url}
                          target="_blank"
                        >
                          <Icon
                            fontSize="medium"
                            sx={{
                              mr: 1,
                              mb: "2px",
                            }}
                          >
                            insert_drive_file
                          </Icon>
                          <span>
                            {file.filename}.{file.ext}
                          </span>
                        </MDTypography>
                      </TableCell>

                      <TableCell>{humanFileSize(file.size)}</TableCell>
                      <TableCell>{new Date(file.createdAt).toLocaleString()}</TableCell>
                      <TableCell>
                        <MDButton
                          variant="gradient"
                          color="warning"
                          iconOnly
                          sx={{
                            mr: 1,
                          }}
                          onClick={() => setFileToEdit(file)}
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="error"
                          iconOnly
                          onClick={() => setFileToDelete(file)}
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
