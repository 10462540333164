import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import {
  Card,
  Grid,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import MDTypography from "components/MDTypography";

import { maskcpf } from "utils/mask";

import MDButton from "components/MDButton";
import KinshipBadge from "../../components/KinshipBadge";
import StatusBadge from "../../components/StatusBadge";

export default function TabDependents({ associate }: { associate: any }) {
  return (
    <>
      {/* <AlertBox alerts={alerts} /> */}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={3}>
              Dependentes
            </MDTypography>

            <MDBox sx={{ p: 3 }}>
              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>CPF</TableCell>
                      <TableCell>Plano</TableCell>
                      <TableCell>Parentesco</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(associate?.dependents ?? []).map((dependent: any) => (
                      <TableRow
                        key={dependent.id}
                        sx={{
                          ":hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {dependent.person.name}
                        </TableCell>

                        <TableCell>{maskcpf(dependent.person.cpf)}</TableCell>
                        <TableCell>{dependent.plan.name}</TableCell>
                        <TableCell>
                          <KinshipBadge kinship={dependent.kinship} />
                        </TableCell>
                        <TableCell>{<StatusBadge status={dependent.status} />}</TableCell>
                        <TableCell>
                          <MDButton
                            variant="gradient"
                            color="info"
                            iconOnly
                            component={Link}
                            to={`/associado/${dependent.id}#cadastro`}
                            // reloadDocument={true}
                          >
                            <Icon>arrow_forward_ios</Icon>
                          </MDButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* {true && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                          </MDBox>
                        </TableCell>
                      </TableRow>
                    )} */}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={5}
                        count={count}
                        rowsPerPage={take}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "Registros por página",
                          },
                          native: true,
                        }}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                          setSkip(newPage * take);
                        }}
                        onRowsPerPageChange={(event) => {
                          setTake(parseInt(event.target.value, 10));
                          setPage(0);
                          setSkip(0);
                        }}
                        ActionsComponent={TablePaginationActions}
                      /> */}
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
