import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "assets/css/general.css";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import apolloClient from "./services/cbsaudeBackend/apolloClient";

// Context Provider
import { MaterialUIControllerProvider } from "./context";
import { ApolloProvider } from "@apollo/client";
import { Provider, useDispatch } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect, useRef } from "react";
import { removeSession } from "redux/session";

function SessionChecker({ children }: any) {
  const lastApiCall = useRef(Date.now());

  const dispatch = useDispatch();

  function checkSession() {
    const isSessionValid = Date.now() - lastApiCall.current < 6 * 60 * 60 * 1000;
    if (!isSessionValid) {
      dispatch(removeSession());
      location.reload();
    }
  }

  function eventVisibilitychange() {
    if (!document.hidden) checkSession();
  }
  function eventApiCall() {
    lastApiCall.current = Date.now();
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", eventVisibilitychange);
    document.addEventListener("apiCall", eventApiCall);

    const interval = setInterval(() => {
      checkSession();
    }, 60 * 1000);

    return () => {
      document.removeEventListener("visibilitychange", eventVisibilitychange);
      document.removeEventListener("apiCall", eventApiCall);
      clearInterval(interval);
    };
  }, []);
  return children;
}

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<span>Loading...</span>}>
          <MaterialUIControllerProvider>
            <SessionChecker>
              <App />
            </SessionChecker>
          </MaterialUIControllerProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
