import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

const UPDATE_FILE = gql`
  mutation UpdateFile($fileId: String!, $data: UpdateFileInput!) {
    updateFile(fileId: $fileId, data: $data) {
      id
      mime
      size
      filename
      createdAt
      updatedAt
      ext
      url
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalEditFile({ fileToEdit, setFileToEdit }: any) {
  const [editFileError, setEditFileError] = useState<null | Error>(null);
  const [filename, setFilename] = useState("");

  const [editFileMutation, { error: ApolloEditFileError, loading: loadingEditFile }] = useMutation(
    UPDATE_FILE,
    {
      onCompleted: () => {
        handlerCloseModalEdit();
      },
    }
  );

  function handlerEditFile() {
    editFileMutation({
      variables: {
        fileId: fileToEdit.id,
        data: {
          filename,
        },
      },
    });
  }

  function handlerCloseModalEdit() {
    setFileToEdit(null);
  }

  useEffect(() => {
    setEditFileError(ApolloEditFileError);
  }, [ApolloEditFileError]);

  useEffect(() => {
    if (fileToEdit) setFilename(fileToEdit?.filename ?? "");
  }, [fileToEdit]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={!!fileToEdit}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Renomear
        </BootstrapDialogTitle>

        <DialogContent>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mt: 1 }}
            fullWidth
            value={filename}
            onChange={(e: any) => setFilename(e.target.value)}
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">.{fileToEdit?.ext}</InputAdornment>,
            }}
          />
        </DialogContent>
        {!editFileError || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setEditFileError(null)}>
            {editFileError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerEditFile}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingEditFile}
          >
            Salvar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
