import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import StatusBadge from "layouts/guides/components/StatusBadge";

const blankEditGuideItemData = {
  name: "",
  description: "",
  price: "",
};

const UPDATE_GUIDE = gql`
  mutation UpdateGuideItem($guideItemId: String!, $data: UpdateGuideItemInput!) {
    updateGuideItem(guideItemId: $guideItemId, data: $data) {
      id
      price
      coefficient
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalEditGuideItemItem({ guideItem, setOpenModal }: any) {
  const [updateGuideItemError, setUpdateGuideItemError] = useState<null | Error>(null);

  const [coefficient, setCoefficient] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    setCoefficient("" + guideItem?.coefficient * 100);
    setPrice(maskBRL(guideItem?.price));
  }, guideItem);

  const [
    updateGuideItemMutation,
    { error: ApolloUpdateGuideItemError, loading: loadingUpdateGuideItem },
  ] = useMutation(UPDATE_GUIDE, {
    onCompleted: () => {
      handlerCloseModalEdit();
    },
  });

  function handlerUpdateGuideItem() {
    updateGuideItemMutation({
      variables: {
        guideItemId: guideItem.id,
        data: {
          coefficient: +justNumbers(coefficient) / 100,
          price: +justNumbers(price) || null,
        },
      },
    });
  }

  function handlerCloseModalEdit() {
    setOpenModal(null);
  }

  useEffect(() => {
    setUpdateGuideItemError(ApolloUpdateGuideItemError);
  }, [ApolloUpdateGuideItemError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={!!guideItem}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Editar Item
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Serviço"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={guideItem?.service.name ?? ""}
            // onChange={(e: any) => setData({ ...data, name: e.target.value })}
            disabled
          />
          <MDInput
            variant="outlined"
            label="Preço"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={price}
            onChange={(e: any) => setPrice(maskBRL(e.target.value))}
            InputProps={{
              endAdornment: price ? (
                <MDButton
                  iconOnly
                  variant="text"
                  color="dark"
                  onClick={() => {
                    setPrice("");
                  }}
                >
                  <Icon fontSize="small">clear</Icon>
                </MDButton>
              ) : undefined,
            }}
          />
          <MDInput
            variant="outlined"
            label="Coeficiente"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={coefficient}
            onChange={(e: any) => setCoefficient(e.target.value)}
            InputProps={{
              endAdornment: <Icon fontSize="small">percent</Icon>,
            }}
            type="number"
          />
        </DialogContent>
        {!updateGuideItemError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setUpdateGuideItemError(null)}
          >
            {updateGuideItemError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerUpdateGuideItem}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingUpdateGuideItem}
          >
            Salvar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
