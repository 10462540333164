import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskBirthDate, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";
import { getLocaleDateString } from "utils/getLocaleDateString";

const CREATE_SERVICE = gql`
  mutation CreateExternalRevenue($data: CreateExternalRevenueInput!) {
    createExternalRevenue(data: $data) {
      id
      description
      amount
      date
      createdAt
      updatedAt
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const newRevenueInitialData = {
  name: "",
  description: "",
  price: "",
};

export default function ModalNewRevenue({ open, setOpen }: any) {
  const [createRevenueError, setCreateRevenueError] = useState<null | Error>(null);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  // on complete, reload GET_SERVICES
  const [
    createRevenueMutation,
    { error: ApolloCreateRevenueError, loading: loadingCreateRevenue },
  ] = useMutation(CREATE_SERVICE, {
    onCompleted: () => {
      handlerCloseModal();
    },
    refetchQueries: ["GetExternalRevenues", "FinancialStatistics"],
  });

  function handlerCreateRevenue() {
    // if (!data.name) return setCreateRevenueError(new Error("Nome é obrigatório"));

    createRevenueMutation({
      variables: {
        data: {
          amount: +justNumbers(amount),
          description: description,
          date: date.split("/").reverse().join("-"),
        },
      },
    });
  }

  function handlerCloseModal() {
    setOpen(false);
  }

  function clearData() {
    setDate(getLocaleDateString(new Date()));
    setAmount("");
    setDescription("");
  }

  useEffect(() => {
    setCreateRevenueError(ApolloCreateRevenueError);
  }, [ApolloCreateRevenueError]);

  useEffect(() => {
    if (open) clearData();
  }, [open]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Adicionar Receita
        </BootstrapDialogTitle>

        <DialogContent>
          <MDInput
            variant="outlined"
            label="Valor"
            sx={{ bgcolor: "#fafafa", mt: 2 }}
            fullWidth
            value={amount}
            onChange={(e: any) => setAmount(maskBRL(e.target.value))}
            required
          />
          <MDInput
            variant="outlined"
            label="Data"
            sx={{ bgcolor: "#fafafa", mt: 2 }}
            fullWidth
            value={date}
            onChange={(e: any) => setDate(maskBirthDate(e.target.value))}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa", mt: 2 }}
            fullWidth
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            multiline
            rows={5}
          />
        </DialogContent>
        {!createRevenueError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setCreateRevenueError(null)}
          >
            {createRevenueError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerCreateRevenue}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingCreateRevenue}
          >
            Adicionar Receita
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
