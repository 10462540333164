import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useRef } from "react";
import { Card, Divider } from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useMutation } from "@apollo/client";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Editor } from "@tinymce/tinymce-react";
import { CBSAUDE_URL } from "config/constants";
import DOMPurify from "dompurify";
import { Appointment } from "..";
import ModalDeleteAppointment from "./ModalDeleteAppointment";
import usePermission from "hooks/usePermission";

const defaultEditorConfig = {
  height: 600,
  menubar: false,
  language: "pt_BR",
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "preview",
    "help",
    "wordcount",
  ],
  toolbar:
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help",
  content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
};

const defaultContent = `<p><strong>Queixa principal:</strong>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Hist&oacute;ria da mol&eacute;stia atual:</strong>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Hist&oacute;rico e antecedentes:</strong>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Exame f&iacute;sico:</strong>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Hip&oacute;tese diagn&oacute;stica:</strong>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Conduta adotada:</strong>&nbsp;</p>
<p>&nbsp;</p>`;

const CREATE_APPOINTMENT = gql`
  mutation CreateMedicalAppointment($data: CreateMedicalAppointmentInput!) {
    createMedicalAppointment(data: $data) {
      id
      contents
      type
      associateId
      professionalId
      createdAt
      updatedAt
      professional {
        id
        name
      }
    }
  }
`;

const UPDATE_APPOINTMENT = gql`
  mutation UpdateMedicalAppointment(
    $data: UpdateMedicalAppointmentInput!
    $medicalAppointmentId: String!
  ) {
    updateMedicalAppointment(data: $data, medicalAppointmentId: $medicalAppointmentId) {
      id
      contents
      type
      associateId
      professionalId
      createdAt
      updatedAt
      professional {
        id
        name
      }
    }
  }
`;

function DateCard({ date, color }: { date: Date; color?: string }) {
  return (
    <MDBox
      bgColor={color || "secondary"}
      variant="gradient"
      display="flex"
      borderRadius="lg"
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "80px",
        //   ml: "auto",
      }}
    >
      <MDTypography variant="h4" color="white">
        {date.getDate()}
      </MDTypography>
      <MDTypography variant="h6" color="white">
        {/* ABR */}
        {date.toLocaleString("pt-BR", { month: "short" }).slice(0, -1).toUpperCase()}
      </MDTypography>
      <MDTypography
        variant="button"
        color="white"
        sx={{ borderTop: "solid 1px white", width: "100%", textAlign: "center" }}
      >
        {date.getFullYear()}
      </MDTypography>
    </MDBox>
  );
}
export default function AppointmentItem({
  appointment,
  dateColor,
  setAppointments,
}: {
  appointment: Appointment;
  dateColor?: string;
  setAppointments: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const editorRef = useRef(null);
  const [isEditing, setIsEditing] = useState(typeof appointment?.id === "undefined");
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);

  const [createMedicalAppointment, { loading: loadingCreate }] = useMutation(CREATE_APPOINTMENT, {
    onError(error) {
      alert(error.message);
    },
  });

  const [updateMedicalAppointment, { loading: loadingUpdate }] = useMutation(UPDATE_APPOINTMENT, {
    onError(error) {
      alert(error.message);
    },
  });

  function saveAppointment() {
    if (!appointment?.id) {
      createMedicalAppointment({
        variables: {
          data: {
            associateId: appointment.associateId,
            professionalId: appointment.professionalId,
            type: appointment.type,
            contents: editorRef.current.getContent(),
          },
        },
        refetchQueries: ["MedicalAppointments"],
        onCompleted(data) {
          // setIsEditing(false);
        },
      });
    } else {
      updateMedicalAppointment({
        variables: {
          medicalAppointmentId: appointment.id,
          data: {
            contents: editorRef.current.getContent(),
          },
        },
        refetchQueries: ["MedicalAppointments"],
        onCompleted(data) {
          setIsEditing(false);
        },
      });
    }
  }

  function cancelEditing() {
    if (!appointment?.id) {
      setAppointments((p) => {
        return p.filter((a) => a.id !== appointment.id);
      });
    } else {
      setIsEditing(false);
    }
  }

  const { can } = usePermission();

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <DateCard date={appointment.createdAt} color={dateColor} />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ModalDeleteAppointment item={appointmentToDelete} setItem={setAppointmentToDelete} />
        <Card
          sx={{
            p: 3,
            "&::before": {
              content: '""',
              position: "absolute",
              border: "10px solid transparent",
              borderRightColor: "#fff",
              left: "-19px",
              top: "2px",
              transform: "rotate(0deg)",
            },
            borderTopLeftRadius: "5px",
          }}
        >
          {!!appointment?.professional?.name && (
            <>
              <MDBox display="flex">
                <MDBox
                  bgColor="secondary"
                  variant="gradient"
                  p={1}
                  sx={{
                    border: "solid 1px",
                    borderColor: "secondary.main",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Responsável:
                  </MDTypography>
                </MDBox>
                <MDBox
                  p={1}
                  sx={{
                    border: "solid 1px",
                    borderColor: "secondary.main",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "none",
                    // minWidth: "200px",
                  }}
                >
                  <MDTypography variant="h6">{appointment?.professional?.name}</MDTypography>
                </MDBox>
                {!isEditing && can("UPDATE_MEDICALAPPOINTMENT") && (
                  <MDButton
                    variant="text"
                    color="dark"
                    sx={{
                      ml: "auto",
                    }}
                    onClick={() => setIsEditing(true)}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                )}
              </MDBox>
              <Divider />
            </>
          )}
          {isEditing ? (
            <>
              <MDBox sx={{ mb: 2 }}>
                <Editor
                  tinymceScriptSrc={CBSAUDE_URL + "/tinymce/tinymce.min.js"}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={appointment?.contents ?? defaultContent}
                  init={defaultEditorConfig}
                  // onChange={(evt: any) => {
                  //   setContents(evt.target.getContent());
                  // }}
                />
              </MDBox>
              <MDBox display="flex" mt={1}>
                {!!appointment?.id && can("DELETE_MEDICALAPPOINTMENT") && (
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => setAppointmentToDelete(appointment)}
                  >
                    Excluir Atendimento
                  </MDButton>
                )}

                <MDButton
                  variant="gradient"
                  color="secondary"
                  sx={{
                    ml: "auto",
                  }}
                  onClick={cancelEditing}
                >
                  Cancelar
                </MDButton>

                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{
                    ml: 2,
                  }}
                  loading={loadingCreate || loadingUpdate}
                  onClick={saveAppointment}
                >
                  Salvar atendimento
                </MDButton>
              </MDBox>
            </>
          ) : (
            <MDBox>
              {/* <MDBox display="flex" mb={-5}>
                <MDButton
                  variant="text"
                  color="dark"
                  sx={{
                    ml: "auto",
                  }}
                  onClick={() => setIsEditing(true)}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </MDButton>
              </MDBox> */}
              <MDTypography variant="body2" sx={{ mb: 2 }}>
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(appointment.contents) }}
                ></div>
              </MDTypography>
            </MDBox>
          )}
          {/*  */}
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}
