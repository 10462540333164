import MDBox from "components/MDBox";
import { CircularProgress } from "@mui/material";
import brasao from "assets/images/logos/logo-cbsaude-152x180.png";
import { gql, useQuery } from "@apollo/client";
import { forwardRef, useEffect } from "react";
import zeroFill from "utils/zeroFill";
import QRCode from "react-qr-code";
import { CBSAUDE_URL } from "config/constants";

import style from "./guide.module.css";

const GET_GUIDE = gql`
  query GuidePublic($guidePublicId: String) {
    guidePublic(id: $guidePublicId) {
      id
      date
      number
      associateType
      associateName
      holderName
      createdBy
      planCod
      items {
        id
        providerName
        providerSpecialty
        serviceName
        coefficient
        price
      }
    }
  }
`;

const GuideInPaper = forwardRef(({ guideId }: any, ref) => {
  const {
    data: { guidePublic: guide } = {},
    loading,
    error: getGuideError,
  } = useQuery(GET_GUIDE, {
    variables: {
      guidePublicId: guideId ? guideId : undefined,
    },
  });

  useEffect(() => {
    if (getGuideError) alert(getGuideError.message);
  }, [getGuideError]);

  const qrcodeValue = `${CBSAUDE_URL}/guia/${guide?.id}/autenticidade`;

  useEffect(() => {
    if (guide) {
      document.title = `GUIA DE AUTORIZAÇÃO Nº ${zeroFill(guide?.number, 5)}`;
      // if (handlePrint) handlePrint();
    }
  }, [guide]);

  if (guide) {
    return (
      <MDBox
        className={style["gc"]}
        sx={{
          px: "7mm",
          py: "7mm",
          width: "148mm",
          height: "210mm",
          // mx: "auto",
          background: "white",
          // margin none in mobile devices
        }}
        ref={ref}
      >
        <table width="100%" cellSpacing={0}>
          <tr>
            <td rowSpan={2} style={{ minWidth: "80px", borderTop: "1px solid black" }}>
              <MDBox
                component="img"
                src={brasao}
                alt="brasão"
                sx={{
                  height: "80px",
                  width: "68px",
                }}
              />
            </td>
            <td rowSpan={2} style={{ borderTop: "1px solid black" }}>
              <span>
                COOPERATIVA DE SAÚDE DOS SERVIDORES PÚBLICOS DO CORPO DE BOMBEIROS MILITAR DO ACRE -
                CBSAÚDE
              </span>
            </td>
            <td rowSpan={2} style={{ borderTop: "1px solid black" }}>
              <span style={{ fontSize: "13px" }}>
                <b>GUIA DE AUTORIZAÇÃO</b>
              </span>
              {!guide?.planCod || (
                <div style={{ fontSize: "13px", marginTop: 10, color: "black" }}>
                  CÓD. {guide?.planCod}
                </div>
              )}
            </td>
            <td style={{ borderTop: "1px solid black" }}>
              <span>Nº DO CONTROLE</span>
              <br />
              <span style={{ color: "red", fontSize: "18px" }}>{zeroFill(guide?.number, 5)}</span>
            </td>
          </tr>
          <tr>
            <td style={{ borderLeft: "none" }}>
              <span style={{ whiteSpace: "nowrap", display: "block" }}>DATA DE EMISSÃO</span>
              <span style={{ display: "block", margin: "5px 0px" }}>
                <b>{guide?.date.split("-").reverse().join("/")}</b>
              </span>
              <span style={{ whiteSpace: "nowrap", display: "block" }}>
                Validade da guia: 30 dias
              </span>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td style={{ textAlign: "left", borderRightWidth: "1px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <b>NOME DO TITULAR</b>
              </span>
            </td>
            <td style={{ width: "100%", borderLeft: "none", textAlign: "left" }} rowSpan={2}>
              <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                <b>{guide?.holderName ?? guide?.associateName}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ borderRight: "none" }}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{ textAlign: "left", borderRightWidth: "1px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <b>NOME DO DEPENDENTE</b>
              </span>
            </td>
            <td style={{ width: "100%", borderLeft: "none", textAlign: "left" }} rowSpan={2}>
              <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                <b>{guide?.holderName ? guide?.associateName : ""}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ borderRight: "none" }}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{ textAlign: "left", borderRightWidth: "1px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <b>SERVIÇO PRESTADO POR</b>
              </span>
            </td>
            <td style={{ width: "100%", borderLeft: "none", textAlign: "left" }} rowSpan={2}>
              <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                <b>{guide?.items[0]?.providerName ?? ""}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ borderRight: "none" }}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{ textAlign: "left", borderRightWidth: "1px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <b>TIPO DE SERVIÇO</b>
              </span>
            </td>
            <td style={{ width: "100%", borderLeft: "none", textAlign: "left" }} rowSpan={2}>
              <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                <b>{guide?.items[0]?.providerSpecialty ?? ""}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ borderRight: "none" }}>&nbsp;</td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="100%">
              <span style={{ whiteSpace: "nowrap" }}>ESPECIFICAÇÃO DOS SERVIÇOS</span>
            </td>
            <td>
              <span style={{ whiteSpace: "nowrap" }}>CÓDIGO DA AMB.</span>
            </td>
            <td>
              <span style={{ whiteSpace: "nowrap" }}>QUANT.</span>
            </td>
            <td>
              <span style={{ whiteSpace: "nowrap" }}>VALOR UNIT.</span>
            </td>
            <td>
              <span style={{ whiteSpace: "nowrap" }}>VALOR TOTAL</span>
            </td>
          </tr>
          {guide?.items.map((item: any, index: number) => (
            <tr key={item.id}>
              <td>
                <div className={style["service-description"]}>{item.serviceName}</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          {guide?.items.length < 11 &&
            new Array(11 - guide?.items.length).fill("dummy").map((item: any, index: number) => (
              <tr key={index}>
                <td>
                  <div className={style["service-description"]}>&nbsp;</div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}

          <tr>
            <td colSpan={3} style={{ backgroundColor: "black" }}>
              <span
                style={{
                  display: "block",
                  color: "white",
                  textAlign: "right",
                  padding: "5px",
                  lineHeight: 1,
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                VALOR TOTAL
              </span>
            </td>
            <td colSpan={2}></td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td style={{ padding: "5px", borderRight: "none" }}>
              <QRCode size={80} value={qrcodeValue} />
            </td>
            <td style={{ borderLeft: "none", textAlign: "left", lineHeight: 1.5 }}>
              <p>
                <b>Confira a autenticidade deste documento através do QRCode.</b>
              </p>
              <p>
                Caso não consiga utilizar o QRCode, acesse através do endereço: <b>{qrcodeValue}</b>
              </p>
              <p style={{ marginTop: "6px" }}>Documento emitido por: {guide?.createdBy}</p>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td style={{ lineHeight: 1.5, width: "50%" }}>
              <p>
                <b>ASSINATURA DO USUÁRIO</b>
              </p>
              <p style={{ marginTop: 15 }}>_____________________________________</p>
            </td>
            <td style={{ lineHeight: 1.5, width: "50%" }}>
              <p>
                <b>ASSINATURA E CARIMBO DO CONVENIADO</b>
              </p>
              <p style={{ marginTop: 15 }}>_____________________________________</p>
            </td>
          </tr>
        </table>
        <div style={{ textAlign: "center", lineHeight: 1 }}>
          <div>
            <span>Rua Major Gesner, 192 - Dist. Industrial - CEP 69920-172 - Rio Branco - AC</span>
          </div>
          <div>
            <span>
              Fone: 3224-4873/3223-0204/98423-7811 - CNPJ 09.611.004/0001-80 - Email:
              cbsaude@hotmail.com
            </span>
          </div>
        </div>
      </MDBox>
    );
  }

  if (loading) {
    return (
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={48} color="inherit" />
      </MDBox>
    );
  }

  return <></>;
});

export default GuideInPaper;
