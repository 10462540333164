import { FormGroup, FormControlLabel, Switch } from "@mui/material";

const allPermissions = [
  {
    label: "Ver a lista de pessoas",
    value: "READ_PERSON_LIST",
  },
  {
    label: "Ver os próprios dados",
    value: "READ_PERSON_SELF",
  },
  {
    label: "Atualizar informações qualquer pessoa",
    value: "UPDATE_PERSON",
  },
  {
    label: "Excluir qualquer pessoa",
    value: "DELETE_PERSON",
  },
  {
    label: "Cadastrar pessoa no sistema",
    value: "CREATE_PERSON",
  },
  {
    label: "Ver a lista de associados",
    value: "READ_ASSOCIATE_LIST",
  },
  {
    label: "Ver os próprios dados de associado",
    value: "READ_ASSOCIATE_SELF",
  },
  {
    label: "Ver os próprios dados de débito",
    value: "READ_ASSOCIATE_DEBT_SELF",
  },
  {
    label: "Ver os próprios dados de crédito",
    value: "READ_ASSOCIATE_CREDIT_SELF",
  },
  {
    label: "Ver os próprios dados de saldo",
    value: "READ_ASSOCIATE_BALANCE_SELF",
  },
  {
    label: "Ver a lista de planos",
    value: "READ_PLAN_LIST",
  },
  {
    label: "Cadastrar plano",
    value: "CREATE_PLAN",
  },
  {
    label: "Atualizar plano",
    value: "UPDATE_PLAN",
  },
  {
    label: "Excluir plano",
    value: "DELETE_PLAN",
  },
  {
    label: "Cadastrar associado",
    value: "CREATE_ASSOCIATE",
  },
  {
    label: "Atualizar associado",
    value: "UPDATE_ASSOCIATE",
  },
  {
    label: "Excluir associado permanentemente",
    value: "DELETE_ASSOCIATE",
  },
  {
    label: "Ver a lista de prestadores de serviço",
    value: "READ_PROVIDER_LIST",
  },
  {
    label: "Cadastrar prestador de serviço",
    value: "CREATE_PROVIDER",
  },
  {
    label: "Atualizar prestador de serviço",
    value: "UPDATE_PROVIDER",
  },
  {
    label: "Ver a lista de serviços",
    value: "READ_SERVICE_LIST",
  },
  {
    label: "Cadastrar serviço",
    value: "CREATE_SERVICE",
  },
  {
    label: "Atualizar serviço",
    value: "UPDATE_SERVICE",
  },
  {
    label: "Excluir serviço",
    value: "DELETE_SERVICE",
  },
  {
    label: "Ver a lista de guias",
    value: "READ_GUIDE_LIST",
  },
  {
    label: "Ver os próprios dados de guia",
    value: "READ_GUIDE_SELF",
  },
  {
    label: "Ver os arquivos de guia",
    value: "READ_GUIDE_FILES",
  },
  {
    label: "Cadastrar guia",
    value: "CREATE_GUIDE",
  },
  {
    label: "Atualizar guia",
    value: "UPDATE_GUIDE",
  },
  {
    label: "Atualizar status de guia sem validação",
    value: "UPDATE_GUIDE_STATUS_WITHOUTVALIDATION",
  },
  {
    label: "Cadastrar arquivo de guia",
    value: "CREATE_GUIDE_FILE",
  },
  {
    label: "Excluir arquivo",
    value: "DELETE_FILE",
  },
  {
    label: "Atualizar arquivo",

    value: "UPDATE_FILE",
  },
  {
    label: "Ver a lista de créditos de associados",
    value: "READ_ASSOCIATE_CREDIT_LIST",
  },
  {
    label: "Cadastrar crédito de associado",
    value: "CREATE_ASSOCIATE_CREDIT",
  },
  {
    label: "Excluir crédito de associado",
    value: "DELETE_ASSOCIATE_CREDIT",
  },
  {
    label: "Ver a lista de débitos de associados",
    value: "READ_ASSOCIATE_DEBT_LIST",
  },
  {
    label: "Ver o saldo de associados",
    value: "READ_ASSOCIATE_BALANCE",
  },
  {
    label: "Ver a lista de receitas externas",
    value: "READ_EXTERNALREVENUE_LIST",
  },
  {
    label: "Cadastrar receita externa",
    value: "CREATE_EXTERNALREVENUE",
  },
  {
    label: "Excluir receita externa",
    value: "DELETE_EXTERNALREVENUE",
  },
  {
    label: "Atualizar receita externa",
    value: "UPDATE_EXTERNALREVENUE",
  },
  {
    label: "Ver a lista de despesas operacionais",
    value: "READ_OPERATIONALEXPENSE_LIST",
  },
  {
    label: "Cadastrar despesa operacional",
    value: "CREATE_OPERATIONALEXPENSE",
  },
  {
    label: "Excluir despesa operacional",
    value: "DELETE_OPERATIONALEXPENSE",
  },
  {
    label: "Atualizar despesa operacional",
    value: "UPDATE_OPERATIONALEXPENSE",
  },
  {
    label: "Cadastrar arquivo de despesa operacional",
    value: "CREATE_OPERATIONALEXPENSE_FILE",
  },
  {
    label: "Ver estatísticas financeiras",
    value: "READ_FINANCIALSTATISTICS",
  },
  {
    label: "Ver a lista de pagamentos de prestadores de serviço",
    value: "READ_PROVIDERPAYMENT_LIST",
  },
  {
    label: "Cadastrar pagamento de prestador de serviço",
    value: "CREATE_PROVIDERPAYMENT",
  },
  {
    label: "Excluir pagamento de prestador de serviço",
    value: "DELETE_PROVIDERPAYMENT",
  },
  {
    label: "Atualizar pagamento de prestador de serviço",
    value: "UPDATE_PROVIDERPAYMENT",
  },
  {
    label: "Cadastrar arquivo de pagamento de prestador de serviço",
    value: "CREATE_PROVIDERPAYMENT_FILE",
  },
  {
    label: "Importar serviços",
    value: "IMPORT_SERVICES",
  },
  {
    label: "Ver prontuários",
    value: "READ_MEDICALAPPOINTMENT_LIST",
  },
  {
    label: "Cadastrar consulta médica",
    value: "CREATE_MEDICALAPPOINTMENT",
  },
  {
    label: "Atualizar consulta médica",
    value: "UPDATE_MEDICALAPPOINTMENT",
  },
  {
    label: "Excluir consulta médica",
    value: "DELETE_MEDICALAPPOINTMENT",
  },
  {
    label: "Ver a lista de mensalidades de associados",
    value: "READ_ASSOCIATE_MONTLYFEE_LIST",
  },
  {
    label: "Cadastrar mensalidade de associado",
    value: "CREATE_ASSOCIATE_MONTLYFEE",
  },
  {
    label: "Excluir mensalidade de associado",
    value: "DELETE_ASSOCIATE_MONTLYFEE",
  },
  {
    label: "Atualizar mensalidade de associado",
    value: "UPDATE_ASSOCIATE_MONTLYFEE",
  },
  {
    label: "Importar pessoas",
    value: "IMPORT_PEOPLE",
  },
  {
    label: "Ver a lista de especialidades de prestadores de serviço",
    value: "READ_PROVIDERSPECIALTY_LIST",
  },
  {
    label: "Cadastrar especialidade de prestador de serviço",
    value: "CREATE_PROVIDERSPECIALTY",
  },
  {
    label: "Atualizar especialidade de prestador de serviço",
    value: "UPDATE_PROVIDERSPECIALTY",
  },
  {
    label: "Excluir especialidade de prestador de serviço",
    value: "DELETE_PROVIDERSPECIALTY",
  },
  {
    label: "Ver a lista de tipos de serviço de prestadores de serviço",
    value: "READ_PROVIDERSERVICETYPE_LIST",
  },
  {
    label: "Cadastrar tipo de serviço de prestador de serviço",
    value: "CREATE_PROVIDERSERVICETYPE",
  },
  {
    label: "Atualizar tipo de serviço de prestador de serviço",
    value: "UPDATE_PROVIDERSERVICETYPE",
  },
  {
    label: "Excluir tipo de serviço de prestador de serviço",
    value: "DELETE_PROVIDERSERVICETYPE",
  },
  {
    label: "Ver a lista de tipos de despesa operacional",
    value: "READ_OPERATIONALEXPENSETYPE_LIST",
  },
  {
    label: "Cadastrar tipo de despesa operacional",
    value: "CREATE_OPERATIONALEXPENSETYPE",
  },
  {
    label: "Atualizar tipo de despesa operacional",
    value: "UPDATE_OPERATIONALEXPENSETYPE",
  },
  {
    label: "Excluir tipo de despesa operacional",
    value: "DELETE_OPERATIONALEXPENSETYPE",
  },
  {
    value: "READ_USERGROUP_LIST",
    label: "Ver todos os níveis de acesso",
  },
  {
    value: "CREATE_USERGROUP",
    label: "Criar níveis de acesso",
  },
  {
    value: "UPDATE_USERGROUP",
    label: "Atualizar níveis de acesso",
  },
  {
    value: "DELETE_USERGROUP",
    label: "Excluir níveis de acesso",
  },
  {
    value: "UPDATE_PERSON_USERGROUPS",
    label: "Atribuir níveis de acesso a pessoas",
  },
  // ------------
  {
    value: "READ_APPOINTMENT_LIST",
    label: "Ver a lista de agendamentos",
  },
  {
    value: "CREATE_APPOINTMENT",
    label: "Cadastrar agendamento",
  },
  {
    value: "UPDATE_APPOINTMENT",
    label: "Atualizar agendamento",
  },
  {
    value: "DELETE_APPOINTMENT",
    label: "Excluir agendamento",
  },
  // ------------
  {
    value: "READ_APPOINTMENTOWNER_LIST",
    label: "Ver a lista de agendas",
  },
  {
    value: "CREATE_APPOINTMENTOWNER",
    label: "Cadastrar nova agenda",
  },
  {
    value: "UPDATE_APPOINTMENTOWNER",
    label: "Atualizar agenda",
  },
  {
    value: "DELETE_APPOINTMENTOWNER",
    label: "Excluir agenda",
  },
  // ------------
  {
    value: "READ_APPOINTMENTOWNERGROUP_LIST",
    label: "Ver a lista de grupos de agendas",
  },
  {
    value: "CREATE_APPOINTMENTOWNERGROUP",
    label: "Cadastrar novo grupo de agenda",
  },
  {
    value: "UPDATE_APPOINTMENTOWNERGROUP",
    label: "Atualizar grupo de agenda",
  },
  {
    value: "DELETE_APPOINTMENTOWNERGROUP",
    label: "Excluir grupo de agenda",
  },
  {
    value: "READ_FINANCIALREPORTS",
    label: "Gerar relatórios financeiros",
  },
];

export default function PermissionsSelector({
  permissions,
  setPermissions,
}: {
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
}) {
  function includes(permission: string) {
    return permissions.includes(permission);
  }

  function toggle(permission: string) {
    if (includes(permission)) {
      setPermissions(permissions.filter((p) => p !== permission));
    } else {
      setPermissions([...permissions, permission]);
    }
  }

  return (
    <FormGroup>
      {allPermissions.map((p: any, index) => (
        <FormControlLabel
          key={index}
          control={<Switch checked={includes(p.value)} onChange={() => toggle(p.value)} />}
          label={p.label}
          sx={{
            "& .MuiFormControlLabel-label": {
              fontWeight: "400",
            },
          }}
        />
      ))}
    </FormGroup>
  );
}
