import { gql, useLazyQuery } from "@apollo/client";
import generate from "./generate";

export interface OperationalExpensesReportInput {
  month: number;
}

const GET_DATA = gql`
  query OperationalExpenses(
    $take: Float
    $filters: FindOperationalExpenseFilters
    $orderBy: FindOperationalExpenseOrderBy
  ) {
    operationalExpenses(take: $take, filters: $filters, orderBy: $orderBy) {
      total
      items {
        amount
        createdAt
        date
        description
        id
        paymentDescription
        type {
          id
          name
        }
        updatedAt
      }
    }
  }
`;

export default function operationalExpensesReport({ month }: OperationalExpensesReportInput) {
  return useLazyQuery(GET_DATA, {
    variables: {
      take: 999999,
      filters: {
        month: month,
      },
      orderBy: {
        type: "asc",
      },
    },

    onError(error) {
      alert(error.message);
    },

    onCompleted(data) {
      generate(data, { month });
    },
  });
}
