// components
import MDBox from "components/MDBox";

import { useState } from "react";
import { Grid } from "@mui/material";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { maskBRL } from "utils/mask";
import { AlertObject } from "components/AlertBox";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";

const GET_FINANCIAL_STATISTICS = gql`
  query FinancialStatistics($month: Float) {
    operationalExpensesBalance(month: $month)
  }
`;

export default function StatisticsCards({ month }: { month: number }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const {
    data,
    loading,
    error: ApolloError,
  } = useQuery(GET_FINANCIAL_STATISTICS, {
    variables: {
      month,
    },
  });

  useEffect(() => {
    if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  }, [ApolloError]);

  const { operationalExpensesBalance } = data ?? {};

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <MiniStatisticsCard
              title={{ text: "Total do mês" }}
              count={maskBRL(operationalExpensesBalance)}
              //   percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "error", component: "arrow_downward" }}
              direction="left"
              loading={loading}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}
