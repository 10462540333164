// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";
import { maskBRL, maskcpf } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import KinshipBadge from "../../components/KinshipBadge";
import StatusBadge from "../../components/StatusBadge";
import GuidesTable from "layouts/guides/components/GuidesTable";
import usePermission from "hooks/usePermission";
// import ModalNewGuide from "./ModalNewGuide";
// import ModalEditGuide from "./ModalEditGuide";

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const GET_GUIDES = gql`
  query Guides($filters: FindGuideFilters, $take: Float, $skip: Float) {
    guides(filters: $filters, take: $take, skip: $skip) {
      total
      items {
        id
        number
        status
        date
        createdAt
        updatedAt
        associate {
          id
          type
          holder {
            id
            person {
              id
              name
              cpf
            }
          }
          person {
            id
            name
            cpf
          }
        }
        items {
          id
          price
          coefficient
          service {
            id
            name
            provider {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default function TabGuides({ associate }: { associate: any }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const {
    data,
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_GUIDES, {
    variables: {
      filters: {
        search: searchInput,
        associateId: associate?.id,
      },
      take,
      skip,
    },
  });

  useEffect(() => {
    if (data?.guides?.total >= 0) {
      setCount(data?.guides?.total);
    }
  }, [data]);

  useEffect(() => {
    if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  const { can } = usePermission();

  return (
    <>
      <AlertBox alerts={alerts} />
      {/* <ModalEditGuide
        {...{ selectedGuide, setSelectedGuide, openModalEditGuide, setOpenModalEditGuide }}
      />
      <ModalNewGuide {...{ openModalNewGuide, setOpenModalNewGuide, associate }} /> */}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack direction="row" alignItems="center" mb={3}>
              <MDTypography variant="h5" gutterBottom>
                Guias do associado {loading && <CircularProgress color="inherit" size={16} />}
              </MDTypography>
              <MDBox
                sx={{
                  ml: "auto",
                }}
              />

              {can("CREATE_GUIDE") && (
                <MDButton
                  sx={{
                    px: 3,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="text"
                  color="secondary"
                  // onClick={() => setOpenModalNewGuide(true)}
                  component={Link}
                  to={"/guia/nova?associateId=" + associate?.id}
                >
                  <Icon>add</Icon>&nbsp; Nova Guia
                </MDButton>
              )}
              <Divider orientation="vertical" sx={{ height: 40, mr: 3, ml: 0 }} />
              <MDInput
                label="Procurar"
                type="search"
                sx={{
                  minWidth: 200,
                }}
                value={searchInput}
                onChange={(e: any) => {
                  setSearchInput(e.target.value);
                  setPage(0);
                  setSkip(0);
                }}
              />
            </Stack>

            <MDBox sx={{ mb: -2 }}>
              <GuidesTable
                {...{
                  items: data?.guides?.items,
                  loading,
                  count,
                  take,
                  page,
                  setPage,
                  setSkip,
                  setTake,
                }}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
