export default function zeroFill(num: number | string, minLength: number): string {
  if (!num) return "";
  let str = "" + num;

  if (str.length >= minLength) {
    return str;
  }

  while (str.length < minLength) {
    str = "0" + str;
  }

  return str;
}
