import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";

const blankEditServiceData = {
  name: "",
  description: "",
  price: "",
};

const UPDATE_SERVICE = gql`
  mutation UpdateService($serviceId: String!, $data: UpdateServiceInput!) {
    updateService(serviceId: $serviceId, data: $data) {
      id
      name
      price
      description
      providerId
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalEditService({
  selectedService,
  setSelectedService,
  openModalEditService,
  setOpenModalEditService,
}: any) {
  const [updateServiceError, setUpdateServiceError] = useState<null | Error>(null);
  const [data, setData] = useState(blankEditServiceData);

  useEffect(() => {
    if (!selectedService) return;
    setData({
      name: selectedService.name,
      description: selectedService.description,
      price: maskBRL(selectedService.price),
    });
  }, [selectedService]);

  const [
    updateServiceMutation,
    { error: ApolloUpdateServiceError, loading: loadingUpdateService },
  ] = useMutation(UPDATE_SERVICE, {
    onCompleted: () => {
      handlerCloseModalEdit();
    },
  });

  function handlerUpdateService() {
    if (!data.name) return setUpdateServiceError(new Error("Nome é obrigatório"));

    updateServiceMutation({
      variables: {
        serviceId: selectedService.id,
        data: {
          ...data,
          price: +justNumbers(data.price) || null,
        },
      },
    });
  }

  function handlerCloseModalEdit() {
    setOpenModalEditService(false);
    setSelectedService(null);
  }

  useEffect(() => {
    setUpdateServiceError(ApolloUpdateServiceError);
  }, [ApolloUpdateServiceError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={openModalEditService}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Editar Serviço
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Valor"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.price}
            onChange={(e: any) => {
              setData({ ...data, price: maskBRL(e.target.value) });
            }}
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
        </DialogContent>
        {!updateServiceError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setUpdateServiceError(null)}
          >
            {updateServiceError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerUpdateService}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingUpdateService}
          >
            Salvar alterações
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
