// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import { maskBRL, maskcpf } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import ModalDeleteProviderPayment from "./components/ModalDeleteProviderPayment";

const GET_PROVIDER_PAYMENTS = gql`
  query ProviderPayments(
    $take: Float
    $skip: Float
    $filters: FindProviderPaymentFilters
    $orderBy: FindProviderPaymentOrderBy
  ) {
    providerPayments(take: $take, skip: $skip, filters: $filters, orderBy: $orderBy) {
      total
      items {
        id
        amount
        paymentDescription
        date
        createdAt
        updatedAt
      }
    }
  }
`;

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default function TabFinancial({ provider }: { provider: any }) {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [paymentToDelete, setPaymentToDelete] = useState<any>(null);

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [getProviderPayments, { data, loading }] = useLazyQuery(GET_PROVIDER_PAYMENTS, {
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
    onCompleted(data) {
      setCount(data?.providerPayments?.total);
    },
  });

  useEffect(() => {
    if (provider) {
      getProviderPayments({
        variables: {
          filters: {
            search: searchInput,
            providerId: provider?.id,
          },
          orderBy: {
            date: "desc",
          },
          take,
          skip,
        },
      });
    }
  }, [provider, searchInput, take, skip]);

  return (
    <>
      <AlertBox alerts={alerts} />
      {/* <ModalEditService
        {...{ selectedService, setSelectedService, openModalEditService, setOpenModalEditService }}
      /> */}
      <ModalDeleteProviderPayment item={paymentToDelete} setItem={setPaymentToDelete} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack direction="row" alignItems="center" mb={3}>
              <MDTypography variant="h5" gutterBottom>
                Pagamentos para {provider?.name}{" "}
                {loading && <CircularProgress color="inherit" size={16} />}
              </MDTypography>
              <MDButton
                sx={{
                  ml: "auto",
                  px: 3,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="text"
                color="secondary"
                // onClick={() => setOpenModalNewService(true)}
                component={Link}
                to={`/prestador/${provider?.id}/pagamento/novo`}
              >
                <Icon>add</Icon>&nbsp; Add Pagamento
              </MDButton>
              <Divider orientation="vertical" sx={{ height: 40, mr: 3, ml: 0 }} />
              <MDInput
                label="Procurar"
                type="search"
                sx={{
                  minWidth: 200,
                }}
                value={searchInput}
                onChange={(e: any) => {
                  setSearchInput(e.target.value);
                  setPage(0);
                  setSkip(0);
                }}
              />
            </Stack>

            <MDBox sx={{ mb: -2 }}>
              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Nº</TableCell> */}

                      <TableCell>Descrição</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data?.providerPayments?.items ?? []).map((item: any) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          ":hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        {/* <TableCell component="th" scope="row">
                          <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                            {item.id}
                          </MDTypography>
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                            {item.paymentDescription}
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography
                            variant="body2"
                            sx={{ fontWeight: "500", color: "error.main" }}
                          >
                            {maskBRL(item.amount)}
                          </MDTypography>
                        </TableCell>

                        <TableCell>{item.date?.split("-").reverse().join("/")}</TableCell>
                        <TableCell>
                          <MDButton
                            variant="gradient"
                            color="info"
                            iconOnly
                            // onClick={() => setExpenseToEdit(item)}
                            component={Link}
                            to={`/prestador/pagamento/${item?.id}`}
                          >
                            <Icon>arrow_forward_ios</Icon>
                          </MDButton>

                          <MDButton
                            variant="gradient"
                            color="error"
                            iconOnly
                            onClick={() => setPaymentToDelete(item)}
                            sx={{
                              ml: 1,
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* {loading && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                          </MDBox>
                        </TableCell>
                      </TableRow>
                    )} */}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={5}
                        count={count}
                        rowsPerPage={take}
                        page={page}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={function defaultLabelDisplayedRows({
                          from,
                          to,
                          count,
                        }) {
                          return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                        }}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                          setSkip(newPage * take);
                        }}
                        onRowsPerPageChange={(event) => {
                          setTake(parseInt(event.target.value, 10));
                          setPage(0);
                          setSkip(0);
                        }}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
