import { useState, ReactNode, useRef } from "react";

// @mui material components
import Fade from "@mui/material/Fade";

// components
import MDBox from "components/MDBox";

// Custom styles for the MDAlert
import MDAlertRoot from "components/MDAlert/MDAlertRoot";
import MDAlertCloseIcon from "components/MDAlert/MDAlertCloseIcon";
import { useEffect } from "react";

// Declaring props types for MDAlert
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  dismissible?: boolean;
  children: ReactNode;
  onClose?: () => void;
  [key: string]: any;
}

function MDAlert({ color, dismissible, children, onClose, ...rest }: Props): JSX.Element | null {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => {
    setAlertStatus("fadeOut");
    onClose && onClose();
  };

  const timer = useRef<any | null>(null);

  function pauseTimer() {
    if (timer) {
      clearTimeout(timer.current);
    }
  }

  function setTimer() {
    timer.current = setTimeout(() => {
      handleAlertStatus();
    }, 10000);
  }

  function resumeTimer() {
    if (dismissible) {
      setTimer();
    }
  }

  useEffect(() => {
    if (dismissible) {
      setTimer();
      return () => clearTimeout(timer.current);
    }
  }, [dismissible]);

  // The base template for the alert
  const alertTemplate: any = (mount: boolean = true) => (
    <Fade in={mount} timeout={300} onMouseEnter={pauseTimer} onMouseLeave={resumeTimer}>
      <MDAlertRoot ownerState={{ color }} {...rest}>
        <MDBox display="flex" alignItems="center" color="white">
          {children}
        </MDBox>
        {dismissible ? (
          <MDAlertCloseIcon onClick={mount ? handleAlertStatus : undefined}>
            &times;
          </MDAlertCloseIcon>
        ) : null}
      </MDAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Declaring default props for MDAlert
MDAlert.defaultProps = {
  color: "info",
  dismissible: false,
};

export default MDAlert;
