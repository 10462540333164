export default function isValidIsoDateString(input: string): boolean {
  if (!input.match(/^\d{4}-\d{2}-\d{2}$/)) {
    // throw new Error("A data deve estar no formato yyyy-mm-dd!");
    return false;
  }
  if (isNaN(Date.parse(input))) {
    // throw new Error("A data é inválida!");
    return false;
  }
  return true;
}
