import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { AppointmentOwnerGroup } from "layouts/administration/configurations/appointmentOwnerGroups";

const DELETE = gql`
  mutation DeleteAppointmentOwnerGroup($appointmentOwnerGroupId: String!) {
    deleteAppointmentOwnerGroup(id: $appointmentOwnerGroupId)
  }
`;

export default function DeleteButton({ resource }: { resource: AppointmentOwnerGroup }) {
  const [mutationDelete, { loading }] = useMutation(DELETE, {
    refetchQueries: ["AppointmentOwnerGroups"],
    onError(error) {
      alert(error);
    },
  });

  function handlerDelete() {
    if (!confirm("Deseja realmente excluir?")) return;

    mutationDelete({
      variables: {
        appointmentOwnerGroupId: resource.id,
      },
    });
  }

  return (
    <MDButton
      variant="gradient"
      color="error"
      iconOnly
      onClick={handlerDelete}
      loading={loading}
      sx={{
        ml: 1,
      }}
    >
      <Icon>delete</Icon>
    </MDButton>
  );
}
