// components
import MDBox from "components/MDBox";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { maskBRL } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import CreditsTable from "./components/CreditsTable";
import DebtsTable from "./components/DebitsTable";
import { codeMonth } from "utils/codeMonth";
import usePermission from "hooks/usePermission";

const GET_ASSOCIATE_FINANCIAL = gql`
  query AssociateFinancial(
    $input: FindAssociateInput!
    $month: Float
    $filters: FindAssociateBalanceFilters
    $filtersInMonth: FindAssociateBalanceFilters
  ) {
    associate(input: $input) {
      id
      balance(filters: $filters)
      balanceInMonth: balance(filters: $filtersInMonth)
      countGuides
      countGuidesInMonth: countGuides(month: $month)
      totalCredits
      totalCost
    }
  }
`;

export default function TabFinancial({ associate }: { associate: any }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [getFinancialData, { data, loading, error: ApolloError }] =
    useLazyQuery(GET_ASSOCIATE_FINANCIAL);

  useEffect(() => {
    if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  }, [ApolloError]);

  useEffect(() => {
    if (associate)
      getFinancialData({
        variables: {
          input: {
            id: associate?.id,
          },
          month: codeMonth(new Date().getFullYear(), new Date().getMonth() + 1),
          filters: {
            date: {
              lt: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
              // gt: new Date(decodedMonth.year, decodedMonth.month - 1, 0),
            },
          },
          filtersInMonth: {
            date: {
              lt: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
              // gte: new Date(decodedMonth.year, decodedMonth.month - 1, 0),
            },
          },
        },
      });
  }, [associate]);

  const { balance, balanceInMonth, countGuides, countGuidesInMonth, totalCredits, totalCost } =
    data?.associate ?? {};

  const { can } = usePermission();

  return (
    <>
      <AlertBox alerts={alerts} />
      <Grid container spacing={3} mt={5}>
        {can("READ_ASSOCIATE_BALANCE") && (
          <Grid item xs={12} md={6} lg={4} xxl={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color={balance < 0 ? "error" : "success"}
                icon={balance < 0 ? "arrow_downward" : "arrow_upward"}
                title="Saldo"
                count={maskBRL(balance)}
                countColor={balance < 0 ? "error" : "success"}
                loading={loading}
                percentage={{
                  color: balanceInMonth < 0 ? "error" : "success",
                  amount: (balanceInMonth < 0 ? "-" : "") + maskBRL(balanceInMonth),
                  label: `(saldo do mês anterior)`,
                }}
              />
            </MDBox>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={4} xxl={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="info"
              icon="note_add"
              title="Guias"
              count={countGuides}
              loading={loading}
              percentage={{
                color: "dark",
                amount: countGuidesInMonth ? `+${countGuidesInMonth}` : "",
                label: "Guias este mês",
              }}
            />
          </MDBox>
        </Grid>

        {can("READ_ASSOCIATE_BALANCE") && (
          <Grid item xs={12} md={6} lg={4} xxl={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="attach_money"
                title="Custo do associado"
                count={maskBRL(totalCost)}
                loading={loading}
                percentage={{
                  color: "dark",
                  amount: maskBRL(totalCredits),
                  label: "Pago pelo associado",
                }}
              />
            </MDBox>
          </Grid>
        )}
      </Grid>

      {/* =================================== */}

      <Grid container spacing={6} mt={0}>
        {can("READ_ASSOCIATE_CREDIT_LIST") && (
          <Grid item xs={12} xxl={6}>
            <CreditsTable associate={associate} />
          </Grid>
        )}

        {can("READ_ASSOCIATE_DEBT_LIST") && (
          <Grid item xs={12} xxl={6}>
            <DebtsTable associate={associate} />
          </Grid>
        )}
      </Grid>
    </>
  );
}
