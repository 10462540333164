import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { AppointmentOwner } from "layouts/administration/configurations/appointmentOwners";

const DELETE = gql`
  mutation DeleteAppointmentOwner($appointmentOwnerId: String!) {
    deleteAppointmentOwner(id: $appointmentOwnerId)
  }
`;

export default function DeleteButton({ resource }: { resource: AppointmentOwner }) {
  const [mutationDelete, { loading }] = useMutation(DELETE, {
    refetchQueries: ["AppointmentOwners"],
    onError(error) {
      alert(error);
    },
  });

  function handlerDelete() {
    if (!confirm("Deseja realmente excluir?")) return;

    mutationDelete({
      variables: {
        appointmentOwnerId: resource.id,
      },
    });
  }

  return (
    <MDButton
      variant="gradient"
      color="error"
      iconOnly
      onClick={handlerDelete}
      loading={loading}
      sx={{
        ml: 1,
      }}
    >
      <Icon>delete</Icon>
    </MDButton>
  );
}
