import MDBadge from "components/MDBadge";

export default function KinshipBadge({ kinship }: { kinship: string }) {
  const labels = {
    SPOUSE: "Cônjuge",
    CHILD: "Filho(a)",
    OTHER: "Outro",
  } as any;

  return <MDBadge badgeContent={labels[kinship] || kinship} color="light" container />;
}
