import { gql, useLazyQuery } from "@apollo/client";
import generate from "./generate";

export interface ProviderPaymentsReportInput {
  month: number;
}

const GET_DATA = gql`
  query ProviderPayments(
    $take: Float
    $filters: FindProviderPaymentFilters
    $orderBy: FindProviderPaymentOrderBy
  ) {
    providerPayments(take: $take, filters: $filters, orderBy: $orderBy) {
      total
      items {
        amount
        createdAt
        date
        id
        paymentDescription
        updatedAt
        provider {
          id
          name
          serviceType {
            id
            name
          }
        }
      }
    }
  }
`;

export default function providerPaymentsReport({ month }: ProviderPaymentsReportInput) {
  return useLazyQuery(GET_DATA, {
    variables: {
      take: 999999,
      filters: {
        month: month,
      },
      orderBy: {
        provider: {
          serviceType: {
            name: "asc",
          },
        },
      },
    },

    onError(error) {
      alert(error.message);
    },

    onCompleted(data) {
      generate(data, { month });
    },
  });
}
