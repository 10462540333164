import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  email: null as string | null,
};

const store = createSlice({
  name: "rememberMe",
  initialState: initialState,
  reducers: {
    setEmail: (state, action) => {
      state = {
        ...state,
        email: action.payload,
      };
      return state;
    },
    removeEmail: (state: any) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key as keyof typeof initialState];
      });
      return state;
    },
  },
});

export const { setEmail, removeEmail } = store.actions;

const persistConfig = {
  key: "rememberMe",
  storage,
};

const rememberMeReducer = persistReducer(persistConfig, store.reducer);
export default rememberMeReducer;
