import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { maskBRL } from "utils/mask";
import * as xlsx from "xlsx";
import MDTypography from "components/MDTypography";
import { justNumbers } from "utils/justNumbers";

const IMPORT_SERVICES = gql`
  mutation ImportServices($data: [CreateServiceInput!]!) {
    importServices(data: $data) {
      id
      name
      providerId
      price
      description
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function parseMoneyFromExcel(value: string | number) {
  if (typeof value === "number") return value;

  if (typeof value === "string") {
    const numericValue = value.replace(/[^0-9,]/g, "");
    const numericValueWithDot = numericValue.replace(",", ".");
    return Number(numericValueWithDot);
  }

  return value;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

// const newServiceInitialData = {
//   name: "",
//   description: "",
//   price: "",
// };

export default function ModalImportServices({ open, setOpen, provider }: any) {
  const [importServicesError, setImportServicesError] = useState<null | Error>(null);
  // const [data, setData] = useState(newServiceInitialData);

  // on complete, reload GET_SERVICES
  const [importServicesMutation, { loading: loadingImportServices }] = useMutation(
    IMPORT_SERVICES,
    {
      onCompleted: () => {
        handlerCloseModal();
        // setData(newServiceInitialData);
      },
      onError: (error) => {
        setImportServicesError(error);
      },
      refetchQueries: ["GetServices"],
    }
  );

  const [fileData, setFileData] = useState<{ name: string; price: number; description?: string }[]>(
    []
  );
  const [fileName, setFileName] = useState("");
  const handleFileUpload = (event: any) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result as ArrayBuffer);
        const workbook = xlsx.read(data, { type: "array", cellNF: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = xlsx.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];
        const newFileData = [];
        for (let i = 0; i < rows.length; i++) {
          if (rows[i][0] && rows[i][1]) {
            if (justNumbers(rows[i][1]) === "") continue;

            newFileData.push({
              name: rows[i][0],
              price: parseMoneyFromExcel(rows[i][1]) * 100,
              description: rows[i][2],
            });
          }
        }
        setFileData(newFileData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  function handlerCloseModal() {
    setOpen(false);
    setFileData([]);
    setFileName("");
  }

  function handlerImportServices() {
    importServicesMutation({
      variables: {
        data: fileData.map((service) => ({
          ...service,
          providerId: provider.id,
        })),
      },
    });
  }

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Importar serviços
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDBox sx={{ px: 3 }}>
            {fileData.length === 0 && (
              <MDBox my={3} sx={{ textAlign: "center" }}>
                <MDBox>
                  <input
                    accept=".xlsx,.xls"
                    // className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                    style={{
                      display: "none",
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <MDButton
                      variant="contained"
                      color="primary"
                      component="span"
                      // className={classes.button}
                    >
                      Selecionar arquivo
                    </MDButton>
                    {fileName && <MDBox sx={{ display: "inline-block", ml: 1 }}>{fileName}</MDBox>}
                  </label>
                </MDBox>
                <MDBox mt={1}>
                  <MDTypography variant="caption" gutterBottom mb={2}>
                    Só é permitido arquivos Excel (.xlsx, .xls)
                  </MDTypography>
                </MDBox>
              </MDBox>
            )}

            {fileData.length > 0 && (
              <>
                {/* <MDTypography variant="h5" gutterBottom mb={2}>
                  Confira os dados:
                </MDTypography> */}

                <table width="100%">
                  <thead style={{ textAlign: "left" }}>
                    <tr>
                      <MDTypography variant="h6" component="th">
                        Nome *
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        Preço *
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        Descrição
                      </MDTypography>
                    </tr>
                  </thead>
                  <tbody>
                    {fileData.map((service, index) => (
                      <tr key={index}>
                        <td>{service.name}</td>
                        <td>{maskBRL(service.price)}</td>
                        <td>{service.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </MDBox>
        </DialogContent>
        {!importServicesError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setImportServicesError(null)}
          >
            {importServicesError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerImportServices}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingImportServices}
            disabled={fileData.length === 0}
          >
            Importar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
