import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  Box,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Menu,
  DialogTitle,
  IconButton,
  Dialog,
  styled,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { getAge } from "utils/getAge";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBirthDate, maskBRL, maskcnpj, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import { justNumbers } from "utils/justNumbers";
import StatusBadge, { getStatusLabel } from "layouts/guides/components/StatusBadge";
import CloseIcon from "@mui/icons-material/Close";
import ModalChangeStatus from "./components/ModalChangeStatus";
import ModalEditGuideItemItem from "./components/ModalEditGuideItem";

// const CREATE_PROVIDER = gql`
//   mutation CreateGuide($data: CreateGuideInput!) {
//     updateGuide(data: $data) {
//       id
//     }
//   }
// `;

function getPlanTypeLabel(type: string) {
  switch (type) {
    case "HOLDER":
      return "TITULAR";
    case "DEPENDENT":
      return "DEPENDENTE";
    default:
      return "";
  }
}

function possibleStatuses(actualStatus: string): string[] {
  const _ps = {
    WAITING_APPROVAL: ["PENDING", "CANCELED"],
    PENDING: ["WAITING_PAYMENT", "CANCELED", "WAITING_APPROVAL"],
    WAITING_PAYMENT: ["PENDING", "CANCELED"],
    // COMPLETED: ["WAITING_PAYMENT"],
    // CANCELED: ["COMPLETED", "CANCELED"],
  };

  return _ps[actualStatus as keyof typeof _ps] ?? [];
}

export default function TabInfo({ guide }: { guide: any }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openModalChangeStatus, setOpenModalChangeStatus] = useState<null | string>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectStatus = (status: string) => {
    setOpenModalChangeStatus(status);
    handleClose();
  };

  const [openModalEditGuideItem, setOpenModalEditGuideItem] = useState<any>(null);

  // const [updateGuideMutation, { error: updateGuideError, loading: updateGuideLoading }] =
  //   useMutation(CREATE_PROVIDER, {
  //     onCompleted: (data) => {
  //       pushAlert({ type: "success", message: "Guia cadastrado com sucesso!" });

  //       // const guideId = data.updateGuide.id;
  //       // setTimeout(() => {
  //       //   clearForm();
  //       //   navigate(`/guia/${guideId}`, { replace: true });
  //       // }, 500);
  //     },
  //   });

  // useEffect(() => {
  //   if (updateGuideError) {
  //     pushAlert({ type: "error", message: updateGuideError.message });
  //   }
  // }, [updateGuideError]);

  // function handlerSubmit(event: any) {
  //   event.preventDefault();
  // }

  return (
    <>
      <AlertBox alerts={alerts} />
      <ModalChangeStatus
        {...{
          selectedStatus: openModalChangeStatus,
          setOpenModal: setOpenModalChangeStatus,
          guide,
        }}
      />
      <ModalEditGuideItemItem
        {...{
          guideItem: openModalEditGuideItem,
          setOpenModal: setOpenModalEditGuideItem,
        }}
      />
      <Grid
        container
        spacing={4}
        component="form"
        role="form"
        // onSubmit={handlerSubmit}
      >
        {/* ----- */}
        <Grid item xs={12} xxl={6}>
          <Card sx={{ p: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={2}>
              Guia
            </MDTypography>

            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography variant="subtitle2" color="dark" mr={1}>
                Status:
              </MDTypography>
              <StatusBadge status={guide?.status} />

              <Box sx={{ ml: "auto" }} />
              {possibleStatuses(guide?.status).length > 0 && (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    sx={{ ml: 2 }}
                    onClick={handleClick}
                  >
                    Alterar Status
                  </MDButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {possibleStatuses(guide?.status).map((status, index: number) => (
                      <MenuItem key={status + index} onClick={() => handleSelectStatus(status)}>
                        {getStatusLabel(status)}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
              {guide?.status !== "CANCELED" && (
                <MDButton
                  variant="gradient"
                  color="secondary"
                  sx={{ ml: 2 }}
                  component={Link}
                  to={`/guia/${guide?.id}/impressao`}
                  target="_blank"
                >
                  Imprimir
                </MDButton>
              )}
            </MDBox>
            {guide?.status === "WAITING_PAYMENT" && (
              <MDButton
                variant="gradient"
                color="warning"
                sx={{ ml: "auto", mt: 2 }}
                component={Link}
                to={`/prestador/${guide?.items[0]?.service?.provider?.id}/pagamento/novo`}
                // target="_blank"
              >
                Ir para pagamento
              </MDButton>
            )}

            <Divider sx={{ mb: 3 }} />

            <MDInput
              variant="outlined"
              label="Associado"
              sx={{ bgcolor: "#fafafa", mb: 2 }}
              fullWidth
              value={
                guide?.associate?.person?.name + " | " + maskcpf(guide?.associate?.person?.cpf)
              }
              disabled
            />

            <MDBox sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <MDTypography variant="subtitle2" color="dark" mr={2}>
                Tipo: <strong>{getPlanTypeLabel(guide?.associate?.type)}</strong>
              </MDTypography>

              <MDTypography variant="subtitle2" color="dark" mr={2}>
                Plano:{" "}
                <strong>
                  {guide?.associate?.plan?.name} ({guide?.associate?.plan?.coefficient * 100}
                  %)
                </strong>
              </MDTypography>

              {guide?.associate?.type === "DEPENDENT" && (
                <MDTypography variant="subtitle2" color="dark" mr={2}>
                  Titular: <strong>{guide?.associate?.holder?.person.name}</strong>
                </MDTypography>
              )}
            </MDBox>

            <MDInput
              variant="outlined"
              label="Prestador de serviço"
              sx={{ bgcolor: "#fafafa", mb: 2 }}
              fullWidth
              value={guide?.items[0]?.service?.provider?.name ?? ""}
              disabled
            />

            <MDInput
              variant="outlined"
              label="Data"
              sx={{ bgcolor: "#fafafa", mb: 2 }}
              fullWidth
              value={guide?.date.split("-").reverse().join("/") ?? ""}
              disabled
            />
          </Card>
        </Grid>

        <Grid item xs={12} xxl={6}>
          <Card sx={{ p: 3, mb: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={2}>
              Serviços
            </MDTypography>

            <TableContainer component="div" sx={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead
                  sx={{
                    backgroundColor: "grey.400",
                  }}
                >
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Preço</TableCell>
                    <TableCell>Coeficiente(%)</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guide?.items.map((item: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 > 0 ? "grey.200" : "grey.100",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {item.service.name}
                      </TableCell>

                      <TableCell>
                        <MDTypography
                          variant="button"
                          color="primary"
                          sx={{
                            textDecoration: "line-through",
                          }}
                        >
                          {maskBRL(item.price)}
                        </MDTypography>

                        <MDTypography
                          variant="body2"
                          color="success"
                          sx={{
                            fontWeight: "500",
                          }}
                        >
                          {maskBRL(item.price ? item.price * item.coefficient : null)}
                        </MDTypography>
                      </TableCell>
                      <TableCell>{item.coefficient * 100}%</TableCell>
                      <TableCell>
                        <MDButton
                          variant="gradient"
                          color="warning"
                          iconOnly
                          onClick={() => {
                            setOpenModalEditGuideItem(item);
                          }}
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
