import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";

// components
import MDBox from "components/MDBox";

// examples components
import PageLayout from "components/LayoutContainers/PageLayout";

// Declaring props types for BasicLayout
interface Props {
  image?: string;
  children: ReactNode;
}

function BasicLayout({ image, children }: Props): JSX.Element {
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          // backgroundImage: ({
          //   functions: { linearGradient, rgba },
          //   palette: { gradients },
          // }: Theme) =>
          //   image && `${linearGradient(rgba("#F4F5FA", 0.9), rgba("#F4F5FA", 1))}, url(${image})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          backgroundColor: ({ palette }: Theme) => palette.background.default,
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={6} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default BasicLayout;
