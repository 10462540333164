import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  ImageList,
  ImageListItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  ImageListItemBar,
  CircularProgress,
  Box,
  Icon,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { getAge } from "utils/getAge";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBirthDate, maskBRL, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import MDBadge from "components/MDBadge";
import StatusBadge from "layouts/guides/components/StatusBadge";
import { justNumbers } from "utils/justNumbers";

const CREATE_PROVIDER_PAYMENT = gql`
  mutation CreateProviderPayment($data: CreateProviderPaymentInput!) {
    createProviderPayment(data: $data) {
      id
      paymentDescription
      amount
      date
      createdAt
      updatedAt
    }
  }
`;

const GET_PROVIDER = gql`
  query Provider($providerId: String!) {
    provider(id: $providerId) {
      id
      name
      bankAccount {
        id
        agency
        account
        type
        variation
        bank {
          id
          name
          code
        }
      }
    }
  }
`;

const GET_GUIDES = gql`
  query Guides($filters: FindGuideFilters, $take: Float, $skip: Float) {
    guides(filters: $filters, take: $take, skip: $skip) {
      total
      items {
        id
        number
        status
        associate {
          id
          type
          holder {
            id
            person {
              id
              name
            }
          }
          person {
            id
            name
          }
        }
        items {
          id
          price
          service {
            id
            name
            provider {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface Guide {
  id: number;
  number: number | null;
  amount: number;
  status: string;
  associateName: string;
  included: boolean;
}

const bankAccountTypeLabel = {
  CHECKING: "Corrente",
  SAVINGS: "Poupança",
} as any;
export default function NewProviderPayment() {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [guides, setGuides] = useState<Guide[]>([]);
  const [amount, setAmount] = useState("");
  const [paymentDescription, setPaymentDescription] = useState(
    "TED | BANCO X | AG XXXX-X | CC XXXXX-X"
  );
  const [date, setDate] = useState(getLocaleDateString(new Date()));
  const [getGuides] = useLazyQuery(GET_GUIDES, {
    onCompleted(data) {
      if (data?.guides?.items?.length > 0) {
        setGuides(
          data.guides.items.map((_guide: any) => ({
            id: _guide.id,
            amount: _guide.items.reduce((ac: number, item: any) => (item.price ?? 0) + ac, 0),
            status: _guide.status,
            associateName:
              _guide.associate.type === "HOLDER"
                ? _guide.associate.person.name
                : _guide.associate.holder.person.name,
            included: false,
          }))
        );
      }
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  const { data: { provider } = {}, loading } = useQuery(GET_PROVIDER, {
    variables: {
      providerId: providerId,
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
    onCompleted(data) {
      getGuides({
        variables: {
          filters: {
            status: ["WAITING_PAYMENT"],
            providerId: data?.provider?.id,
          },
          take: 99999,
        },
      });
    },
  });

  function handlerChangeIncluded(guide: Guide, newIncluded: boolean) {
    const newArray = [...guides];

    for (let i = 0; i < newArray.length; i++) {
      const _guide = newArray[i];
      if (_guide.id === guide.id) {
        _guide.included = newIncluded;
        break; // para interromper a iteração assim que encontrar o objeto desejado
      }
    }
    setGuides(newArray);
  }

  const [createProviderPaymentMutation, { loading: loadingCreateProviderPayment }] = useMutation(
    CREATE_PROVIDER_PAYMENT,
    {
      onCompleted: (data) => {
        navigate(`/prestador/pagamento/${data.createProviderPayment.id}`);
      },
      onError(error) {
        pushAlert({ type: "error", message: error.message });
      },
      refetchQueries: ["Guide"],
    }
  );

  function handlerSubmit(event: any) {
    event.preventDefault();

    createProviderPaymentMutation({
      variables: {
        data: {
          amount: +justNumbers(amount),
          paymentDescription: paymentDescription,
          date: date.split("/").reverse().join("-"),
          guideIds: guides.filter((guide) => guide.included).map((guide) => guide.id),
          providerId: provider?.id,
        },
      },
    });
  }

  useEffect(() => {
    setAmount(
      maskBRL(guides.reduce((ac: number, item: any) => (item.included ? item.amount : 0) + ac, 0))
    );
  }, [guides]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AlertBox alerts={alerts} />
        <Grid
          container
          spacing={4}
          component="form"
          role="form"
          onSubmit={handlerSubmit}
          justifyContent="center"
        >
          {/* ----- */}
          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom>
                Novo pagamento para {provider?.name}{" "}
                {loading && <CircularProgress color="inherit" size={16} />}
              </MDTypography>

              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Banco: <strong>{provider?.bankAccount?.bank?.name}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Agência: <strong>{provider?.bankAccount?.agency}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Conta: <strong>{provider?.bankAccount?.account}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Tipo: <strong>{bankAccountTypeLabel[provider?.bankAccount?.type as any]}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block" }}
                >
                  Variação: <strong>{provider?.bankAccount?.variation}</strong>
                </MDTypography>
              </MDBox>
              <Divider sx={{ my: 3 }} />

              {/* <MDBox
                sx={{
                  textAlign: "right",
                  px: 2,
                }}
              >
                <MDTypography
                  variant="body2"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Total:
                </MDTypography>
                <MDTypography
                  variant="h3"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {maskBRL(
                    guides.reduce(
                      (ac: number, item: any) => (item.included ? item.amount : 0) + ac,
                      0
                    )
                  )}
                </MDTypography>
              </MDBox>

              <Divider sx={{ my: 3 }} /> */}

              <MDTypography variant="h6" gutterBottom mb={2}>
                Guias incluídas
              </MDTypography>

              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "grey.200",
                    }}
                  >
                    {guides.length > 0 && (
                      <TableRow>
                        <TableCell>Nº</TableCell>
                        <TableCell>Titular</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {guides.map((guide, index: number) => (
                      <TableRow
                        key={guide.id}
                        sx={{
                          backgroundColor: guide.included
                            ? index % 2 > 0
                              ? "grey.100"
                              : undefined
                            : "grey.200",
                        }}
                      >
                        <TableCell
                        // sx={{
                        //   color: ,
                        // }}
                        >
                          <MDTypography
                            variant="body2"
                            color={!guide.included ? "#7b809a" : undefined}
                            sx={{
                              fontWeight: "500",
                            }}
                            component={Link}
                            to={`/guia/${guide.id}`}
                          >
                            {guide?.number}
                          </MDTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: !guide.included ? "#7b809a" : undefined,
                          }}
                        >
                          {guide.associateName}
                        </TableCell>
                        <TableCell>
                          <MDTypography
                            variant="body2"
                            color={guide.included ? "error" : "text"}
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {maskBRL(guide.amount)}
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <StatusBadge status={guide.status} size="xs" />
                        </TableCell>
                        <TableCell>
                          {guide.included ? (
                            <MDButton
                              variant="gradient"
                              color="error"
                              iconOnly
                              onClick={() => handlerChangeIncluded(guide, false)}
                            >
                              <Icon>close</Icon>
                            </MDButton>
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="success"
                              iconOnly
                              onClick={() => handlerChangeIncluded(guide, true)}
                            >
                              <Icon>add</Icon>
                            </MDButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {guides.length === 0 && (
                      <TableRow
                        sx={{
                          backgroundColor: "grey.300",
                        }}
                      >
                        <TableCell component="th" scope="row" colSpan={3}>
                          Esse prestador não tem nenhuma guia aguardando pagamento
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider sx={{ my: 3 }} />

              <MDInput
                variant="outlined"
                label="Valor"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={amount}
                onChange={(e: any) => setAmount(maskBRL(e.target.value))}
                required
              />
              <MDInput
                variant="outlined"
                label="Data"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={date}
                onChange={(e: any) => setDate(maskBirthDate(e.target.value))}
                required
              />

              <MDInput
                variant="outlined"
                label="Forma de pagamento/Como foi pago"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={paymentDescription}
                onChange={(e: any) => setPaymentDescription(e.target.value)}
                multiline
                rows={5}
              />
              <Divider sx={{ my: 3 }} />

              <MDTypography variant="body2">
                Você poderá <b>anexar arquivos</b> quando salvar este pagamento
              </MDTypography>
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={loadingCreateProviderPayment}
              >
                Salvar Pagamento
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
