import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";

const CREATE_PLAN = gql`
  mutation CreateProviderServiceType($data: CreateProviderServiceTypeInput!) {
    createProviderServiceType(data: $data) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const newProviderServiceTypeInitialData = {
  name: "",
  description: "",
};

export default function ModalNew({
  openModalNewProviderServiceType,
  setOpenModalNewProviderServiceType,
}: any) {
  const [createProviderServiceTypeError, setCreateProviderServiceTypeError] =
    useState<null | Error>(null);
  const [data, setData] = useState(newProviderServiceTypeInitialData);

  // on complete, reload GET_PLANS
  const [
    createProviderServiceTypeMutation,
    { error: ApolloCreateProviderServiceTypeError, loading: loadingCreateProviderServiceType },
  ] = useMutation(CREATE_PLAN, {
    onCompleted: () => {
      handlerCloseModal();
      setData(newProviderServiceTypeInitialData);
    },
    refetchQueries: ["ProviderServiceTypes"],
  });

  function handlerCreateProviderServiceType() {
    if (!data.name) return setCreateProviderServiceTypeError(new Error("Nome é obrigatório"));

    createProviderServiceTypeMutation({
      variables: {
        data: {
          ...data,
        },
      },
    });
  }

  function handlerCloseModal() {
    setOpenModalNewProviderServiceType(false);
  }

  useEffect(() => {
    setCreateProviderServiceTypeError(ApolloCreateProviderServiceTypeError);
  }, [ApolloCreateProviderServiceTypeError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModalNewProviderServiceType}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Novo Tipo de Serviço
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
        </DialogContent>
        {!createProviderServiceTypeError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setCreateProviderServiceTypeError(null)}
          >
            {createProviderServiceTypeError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerCreateProviderServiceType}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingCreateProviderServiceType}
          >
            Salvar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
