import { MONTHS_LABELS } from "config/constants";
import { decodeMonth } from "utils/codeMonth";
import Excel from "exceljs";
import { AssociateMontlyFeesReportInput } from ".";

// @ts-ignore
import template from "./template/mo_fe.xlsx";
async function getFileBuffer(url: string) {
  try {
    const res = await fetch(url);
    const blob = await res.blob();

    const arrayBuffer = await blob.arrayBuffer();
    // const buffer = Buffer.from(arrayBuffer);

    return arrayBuffer;
  } catch (error) {
    console.log(error);

    throw new Error("Error downloading file");
  }
}

function downloadXLSXFile(buffer: Excel.Buffer, fileName: string) {
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);

  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

import { CBSAUDE_URL } from "config/constants";
import { maskBRL } from "utils/mask";

export default async function generate(data: any, { month }: AssociateMontlyFeesReportInput) {
  const decodedMonth = decodeMonth(month);
  const items = data?.associateMontlyFees?.items ?? [];

  const rows = items.map(({ id, associate, items, month }: any, index: number) => {
    return [
      index + 1,
      associate?.person?.name,
      maskBRL(items.reduce((acc: any, curr: any) => acc + curr.amount, 0)),
    ];
  });

  const blob = await getFileBuffer(CBSAUDE_URL + template);

  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(blob);

  const worksheet = workbook.getWorksheet("GERAL");

  worksheet.insertRows(3, rows, "i+");

  worksheet.spliceRows(2, 1);

  // -------------------------- TITULO --------------------------------
  // const titleCell = worksheet.getCell("A6");
  const title = `MENSALIDADES REFERENTE A ${MONTHS_LABELS[decodedMonth.month].toUpperCase()} DE ${
    decodedMonth.year
  }`;
  // titleCell.value = title;
  // worksheet.mergeCells("A6", "G6");
  //-------------------------- TOTAL -----------------------------------
  const totalRowIndex = 2 + rows.length;

  const totalAmount = items.reduce(
    (acc: any, item: any) => acc + item.items.reduce((acc: any, curr: any) => acc + curr.amount, 0),
    0
  );
  const totalAmountCell = worksheet.getCell("C" + totalRowIndex);

  totalAmountCell.value = maskBRL(totalAmount);

  // worksheet.mergeCells("A" + totalRowIndex, "E" + totalRowIndex);
  // worksheet.mergeCells("F" + totalRowIndex, "H" + totalRowIndex);
  //---------------------------------------------------------------------

  const buffer = await workbook.xlsx.writeBuffer();
  downloadXLSXFile(buffer, `${title}.xlsx`);
}
