import { useState, useEffect } from "react";
import {
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";
import BootstrapDialog from "components/BootstrapDialog";
import { CalendarApi, DateSelectArg, EventClickArg } from "@fullcalendar/core";
import MDDatePicker from "components/MDDatePicker";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { XOR } from "types/XOR";
import Icon from "@mui/material/Icon";
import { v4 as uuid } from "uuid";
import { AppointmentOwner } from "layouts/appointments/components/OwnerSelector";
import usePermission from "hooks/usePermission";

const colors = [
  {
    value: "info",
    label: "Azul",
  },
  {
    value: "success",
    label: "Verde",
  },
  {
    value: "warning",
    label: "Amarelo",
  },
  {
    value: "error",
    label: "Vermelho",
  },
  {
    value: "secondary",
    label: "Cinza",
  },
  {
    value: "primary",
    label: "Magenta",
  },
  {
    value: "dark",
    label: "Preto",
  },
  {
    value: "light",
    label: "Branco",
  },
];

const initialData = {
  start: new Date(),
  duration: 30,
  color: "info",
  description: "",
};

export default function ModalEvent({
  open,
  setOpen,
  owner,
}: {
  open: XOR<DateSelectArg, EventClickArg> | null;
  setOpen: React.Dispatch<React.SetStateAction<XOR<DateSelectArg, EventClickArg> | null>>;
  owner: AppointmentOwner;
}) {
  const [error, setError] = useState<null | Error>(null);
  const [data, setData] = useState(initialData);

  function handlerSubmit() {
    if (!data.description) return setError(new Error("A descrição é obrigatória"));
    if (!owner?.id) return setError(new Error("Não foi possível identificar a agenda"));

    const end = new Date(data.start.getTime() + data.duration * 60 * 1000);

    if (open?.event?.id) {
      //update
      open.event.setStart(data.start);
      open.event.setEnd(end);
      open.event.setProp("classNames", [`event-${data.color}`]);
      open.event.setProp("title", data.description);
      open.event.setExtendedProp("color", data.color);
    } else {
      //create
      let calendarApi = open.view.calendar;
      calendarApi.unselect();
      calendarApi.addEvent({
        id: uuid(),
        title: data.description,
        start: data.start,
        end: end,
        className: `event-${data.color}`,
        extendedProps: {
          ownerId: owner.id,
          color: data.color,
        },
      });
    }

    handlerCloseModal();
  }

  function handlerDelete() {
    if (!confirm("Deseja realmente excluir?")) return;

    if (open?.event?.id) {
      //update
      open.event.remove();
    }

    handlerCloseModal();
  }

  function handlerCloseModal() {
    setOpen(null);
    setError(null);
  }

  useEffect(() => {
    if (open?.event?.id) {
      //update

      const duration = (open.event.end.getTime() - open.event.start.getTime()) / 1000 / 60;
      setData({
        color: open.event.extendedProps.color,
        start: open.event.start,
        duration,
        description: open.event.title,
      });
    } else if (open?.start) {
      //create
      const duration = (open.end.getTime() - open.start.getTime()) / 1000 / 60;
      setData({
        color: "info",
        start: open.start,
        duration,
        description: "",
      });
    }
  }, [open]);

  const { can } = usePermission();

  const canAction =
    (!open?.event?.id && can("CREATE_APPOINTMENT")) ||
    (!!open?.event?.id && can("UPDATE_APPOINTMENT"));

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={!!open}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          {open?.event?.id ? "Agendamento" : "NOVO Agendamento"}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box sx={{ flex: 1, mr: 2 }}>
              <InputLabel sx={{ mb: 1, ml: "1px" }}>Data/Hora</InputLabel>
              <MDDatePicker
                input={{
                  placeholder: "Selecione",
                  sx: { width: "100%", bgcolor: "#fafafa" },
                  disabled: !canAction,
                }}
                options={{
                  enableTime: true,
                  dateFormat: "d/m/Y H:i",
                  // time_24hr: true,
                  locale: Portuguese,
                  minuteIncrement: 30,
                }}
                value={data.start}
                onChange={([date]) => setData({ ...data, start: date })}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel sx={{ mb: 1, ml: "1px" }}>Duração</InputLabel>
              <Select
                value={data.duration}
                onChange={(e: any) => setData({ ...data, duration: e.target.value })}
                fullWidth
                sx={{ bgcolor: "#fafafa" }}
                disabled={!canAction}
              >
                <MenuItem value={30}>00:30</MenuItem>
                <MenuItem value={60}>01:00</MenuItem>
                <MenuItem value={90}>01:30</MenuItem>
                <MenuItem value={120}>02:00</MenuItem>
                <MenuItem value={150}>02:30</MenuItem>
                <MenuItem value={180}>03:00</MenuItem>
                <MenuItem value={210}>03:30</MenuItem>
                <MenuItem value={240}>04:00</MenuItem>
                <MenuItem value={270}>04:30</MenuItem>
                <MenuItem value={300}>05:00</MenuItem>
                <MenuItem value={330}>05:30</MenuItem>
                <MenuItem value={360}>06:00</MenuItem>
                <MenuItem value={390}>06:30</MenuItem>
                <MenuItem value={420}>07:00</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <InputLabel sx={{ mb: 1, ml: "1px" }}>Cor</InputLabel>
            <Select
              value={data.color}
              onChange={(e: any) => setData({ ...data, color: e.target.value })}
              fullWidth
              sx={{
                "& .MuiSelect-select": {
                  display: "flex !important",
                  flexDirection: "row",
                },
                bgcolor: "#fafafa",
              }}
              disabled={!canAction}
            >
              {colors.map((color) => (
                <MenuItem value={color.value} key={color.value}>
                  <Box
                    sx={{
                      p: 1,
                      bgcolor: color.value + ".main",
                      borderRadius: "20%",
                    }}
                  />
                  <Box flex={1} sx={{ ml: 1 }}>
                    {color.label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ mt: 2 }}>
            <InputLabel sx={{ mb: 1, ml: "1px" }}>Texto</InputLabel>
            <MDInput
              variant="outlined"
              // placeholder="Texto"
              fullWidth
              value={data?.description}
              onChange={(e: any) => setData({ ...data, description: e.target.value })}
              multiline
              rows={3}
              sx={{ bgcolor: "#fafafa" }}
              disabled={!canAction}
            />
          </Box>
        </DialogContent>
        {!error || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setError(null)}>
            {error.message}
          </MDAlert>
        )}
        <DialogActions>
          {can("DELETE_APPOINTMENT") && !!open?.event?.id && (
            <MDButton
              onClick={handlerDelete}
              variant="gradient"
              color="error"
              sx={{ m: 1, mr: "auto" }}
              iconOnly
            >
              <Icon>delete</Icon>
            </MDButton>
          )}

          {canAction && (
            <MDButton onClick={handlerSubmit} variant="gradient" color="info" sx={{ m: 1 }}>
              Salvar
            </MDButton>
          )}
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
