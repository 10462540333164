// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";
import { maskcpf } from "utils/mask";
import Actions from "./components/Actions";

const GET_PEOPLE = gql`
  query People($searchInput: String, $take: Float, $skip: Float) {
    people(searchInput: $searchInput, take: $take, skip: $skip) {
      total
      items {
        id
        email
        name
        cpf
        phone1
        phone2
        birthDate
        userGroups {
          id
          name
        }
        accessCredentials {
          id
          needsPasswordChange
        }
      }
    }
  }
`;

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

// function RoleBadge({ role }: { role: string }) {
//   switch (role) {
//     case "ADMIN":
//       return <MDBadge badgeContent="Administrador" color="primary" container />;
//     case "DIRECTOR":
//       return <MDBadge badgeContent="Diretor" color="error" container />;
//     case "MANAGER":
//       return <MDBadge badgeContent="Gerente" color="warning" container />;
//     case "RECEPTIONIST":
//       return <MDBadge badgeContent="Recepcionista" color="info" container />;
//     case "DOCTOR":
//       return <MDBadge badgeContent="Médico" color="dark" container />;
//     case "CUSTOMER":
//       return <MDBadge badgeContent="Básico" color="light" container />;
//     default:
//       return <MDBadge badgeContent={role} color="light" container />;
//   }
// }

// function StatusBadge({ status }: { status: string }) {
//   switch (status) {
//     case "ACTIVE":
//       return <MDBadge badgeContent="Ativo" color="success" container />;
//     case "INACTIVE":
//       return <MDBadge badgeContent="Inativo" color="secondary" container />;
//     case "DELETED":
//       return <MDBadge badgeContent="Excluído" color="error" container />;
//     default:
//       return <MDBadge badgeContent={status} color="light" container />;
//   }
// }

function People() {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [searchError, setSearchError] = useState<null | Error>(null);
  const [count, setCount] = useState(0);

  const {
    data,
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_PEOPLE, {
    variables: {
      searchInput,
      take,
      skip,
    },
  });

  useEffect(() => {
    if (data?.people?.total >= 0) {
      setCount(data?.people?.total);
    }
  }, [data]);

  useEffect(() => {
    setSearchError(ApolloError);
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {!searchError || (
          <MDAlert sx={{ mb: 5 }} color="error" dismissible onClose={() => setSearchError(null)}>
            {searchError.message}
          </MDAlert>
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Pessoas {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>
                  <Box ml="auto" />
                  {/* <MDButton
                    sx={{
                      ml: "auto",
                      px: 3,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="text"
                    color="white"
                    // onClick={() => setOpenModalNewPerson(true)}
                    component={Link}
                    to="/pessoa/cadastrar"
                  >
                    <Icon>add</Icon>&nbsp; Add pessoa
                  </MDButton>

                  <Divider orientation="vertical" light sx={{ height: 40, mx: 0 }} /> */}

                  <Actions />

                  <Divider orientation="vertical" light sx={{ height: 40, mr: 3, ml: 0 }} />
                  <Card
                    sx={{
                      px: 1.5,
                      borderRadius: "6px",
                    }}
                  >
                    <MDInput
                      label="Procurar"
                      type="search"
                      sx={{
                        minWidth: 200,
                        "& .MuiInput-underline:before": { border: "none" },
                        "& .MuiInput-underline:after": { border: "none" },
                        "& .MuiInput-underline:hover": { border: "none" },
                        "& .MuiInputBase-root:hover:before": { border: "none" },
                      }}
                      variant="standard"
                      value={searchInput}
                      onChange={(e: any) => {
                        setSearchInput(e.target.value);
                        setPage(0);
                        setSkip(0);
                      }}
                    />
                  </Card>
                </Stack>
              </MDBox>

              <MDBox sx={{ p: 3 }}>
                <TableContainer component="div" sx={{ boxShadow: "none" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        {/* <TableCell>E-mail</TableCell> */}
                        <TableCell>CPF</TableCell>
                        <TableCell>Níveis de Acesso</TableCell>
                        <TableCell>Acesso ao Sistema</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data?.people?.items ?? []).map((person: any) => (
                        <TableRow
                          key={person.id}
                          sx={{
                            ":hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                          // onClick={() => handlerOpenModalEdit(person)}
                        >
                          <TableCell component="th" scope="row">
                            {person.name}
                          </TableCell>
                          {/* <TableCell>{person.email}</TableCell> */}
                          <TableCell>{maskcpf(person.cpf)}</TableCell>
                          <TableCell>
                            {/* <RoleBadge role={person.role} /> */}
                            {person.userGroups.map((userGroup: any) => (
                              <MDBadge
                                key={userGroup.id}
                                badgeContent={userGroup.name}
                                color="dark"
                                container
                                sx={{ mr: 0.5 }}
                              />
                            ))}
                          </TableCell>
                          <TableCell>
                            {/* acesso ao sistema, sim não */}
                            {person.accessCredentials ? (
                              <MDBadge badgeContent="Sim" color="success" container />
                            ) : (
                              <MDBadge badgeContent="Não" color="light" container />
                            )}
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              iconOnly
                              component={Link}
                              to={`/pessoa/${person.id}`}
                            >
                              <Icon>arrow_forward_ios</Icon>
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* {loading && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                              <CircularProgress />
                            </MDBox>
                          </TableCell>
                        </TableRow>
                      )} */}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={5}
                          count={count}
                          rowsPerPage={take}
                          page={page}
                          labelRowsPerPage="Registros por página"
                          labelDisplayedRows={function defaultLabelDisplayedRows({
                            from,
                            to,
                            count,
                          }) {
                            return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                          }}
                          onPageChange={(event, newPage) => {
                            setPage(newPage);
                            setSkip(newPage * take);
                          }}
                          onRowsPerPageChange={(event) => {
                            setTake(parseInt(event.target.value, 10));
                            setPage(0);
                            setSkip(0);
                          }}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default People;
