import { Link, useLocation, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useQuery } from "@apollo/client";
import { CircularProgress, Icon, AppBar, Tabs, Tab } from "@mui/material";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import { useEffect } from "react";
import AlertBox, { AlertObject } from "components/AlertBox";
import TabInfo from "./components/TabInfo";
import TabServices from "./components/TabServices";
import TabFinancial from "./components/TabFinancial";

const GET_PROVIDER = gql`
  query Provider($providerId: String!) {
    provider(id: $providerId) {
      id
      name
      documentType
      document
      companyName
      description
      phone1
      phone2
      email
      status
      specialtyId
      serviceTypeId
      createdAt
      updatedAt
      specialty {
        id
        name
      }
      bankAccount {
        id
        bankId
        agency
        account
        type
        variation
      }
      address {
        # id
        street
        number
        neighborhood
        state
        city
        complement
        zipCode
      }
    }
  }
`;

export default function ViewProvider() {
  const { providerId } = useParams();

  const location = useLocation();
  const anchor = location.hash;
  const [tabValue, setTabValue] = useState(anchor || "#cadastro");
  useEffect(() => {
    if (anchor) {
      setTabValue(anchor);
    } else {
      setTabValue("#cadastro");
    }
  }, [anchor]);

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const handleSetTabValue = (event: any, newValue: any) => setTabValue(newValue);

  // -----------------------------------------------------------------------------

  const {
    data: { provider } = {},
    loading,
    error: getProviderError,
  } = useQuery(GET_PROVIDER, {
    variables: {
      providerId: providerId,
    },
  });

  useEffect(() => {
    if (getProviderError) {
      pushAlert({ type: "error", message: getProviderError.message });
    }
  }, [getProviderError]);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Provedor de Serviços" />

      <MDBox pt={2} pb={3}>
        <AlertBox alerts={alerts} />

        <MDTypography variant="h5" gutterBottom mb={3}>
          {provider?.name} {loading && <CircularProgress color="inherit" size={16} />}
        </MDTypography>

        <MDBox sx={{ mb: 3 }}>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleSetTabValue}>
              <Tab
                label="Cadastro"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    badge
                  </Icon>
                }
                component={Link}
                to="#cadastro"
                replace={true}
                value="#cadastro"
              />
              <Tab
                label="Serviços"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    category
                  </Icon>
                }
                component={Link}
                to="#servicos"
                replace={true}
                value="#servicos"
              />
              <Tab
                label="Financeiro"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    attach_money
                  </Icon>
                }
                component={Link}
                to="#financeiro"
                replace={true}
                value="#financeiro"
              />
            </Tabs>
          </AppBar>
        </MDBox>

        {tabValue === "#cadastro" && <TabInfo provider={provider} />}
        {tabValue === "#servicos" && <TabServices provider={provider} />}
        {tabValue === "#financeiro" && <TabFinancial provider={provider} />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
