import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  Box,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { getAge } from "utils/getAge";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBirthDate, maskBRL, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import usePermission from "hooks/usePermission";
import MDBadge from "components/MDBadge";
import itsBeenDays from "utils/itsBeenDays";
import MDAlert from "components/MDAlert";

const GET_PLANS = gql`
  query GetPlans($take: Float) {
    plans(take: $take) {
      total
      items {
        id
        name
        description
        price
        coefficient
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_HOLDERS = gql`
  query Associates($filters: FindAssociatesFilters, $take: Float) {
    associates(filters: $filters, take: $take) {
      total
      items {
        id
        person {
          id
          name
          cpf
        }
      }
    }
  }
`;

const UPDATE_ASSOCIATE = gql`
  mutation UpdateAssociate($data: UpdateAssociateInput!, $associateId: String!) {
    updateAssociate(data: $data, associateId: $associateId) {
      id
    }
  }
`;

const DELETE_ASSOCIATE = gql`
  mutation DeleteAssociate($associateId: String!) {
    deleteAssociate(associateId: $associateId)
  }
`;

const initialData = {
  planId: "",
  type: "",
  admissionDate: "",
  kinship: "",
  status: "ACTIVE",
  folder: "",
};

export default function TabInfo({ associate }: { associate: any }) {
  const [holdersOptions, setHoldersOptions] = useState<any[]>([]);
  const [selectedHolder, setSelectedHolder] = useState<any>(null);
  function getHolderACValue(holderId: number) {
    if (!holderId) return null;
    return holdersOptions.find((s) => s.value === holderId);
  }

  // console.log(JSON.stringify(associate, null, 2));

  const { data: { associates: holders } = {} } = useQuery(GET_HOLDERS, {
    variables: {
      take: 99999,
      filters: {
        types: ["HOLDER"],
      },
    },
    onCompleted: (data) => {
      setHoldersOptions(
        data.associates?.items.map((h: any) => ({
          value: h.id,
          label: `${h.person.name} | ${maskcpf(h.person.cpf)}`,
        }))
      );
      if (associate) {
        setSelectedHolder(data.associates?.items.find((h: any) => h.id === associate?.holder?.id));
      }
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success" | "warning" | "info"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (associate) {
      const admissionDate = associate.admissionDate.split("-").reverse().join("/");
      // const holderId = associate.holder?.id;
      setData({
        // holderId: holderId ?? "",
        planId: associate.plan.id,
        type: associate.type,
        admissionDate,
        kinship: associate.kinship,
        status: associate.status,
        folder: associate.folder ? "" + associate.folder : "",
      });

      setSelectedHolder(holders?.items.find((h: any) => h.id === associate?.holder?.id));
    }
  }, [associate]);

  const {
    data: plansData,
    loading: plansLoading,
    error: plansError,
  } = useQuery(GET_PLANS, {
    variables: {
      take: 100,
    },
  });

  useEffect(() => {
    if (plansError) {
      pushAlert({ type: "error", message: plansError.message });
    }
  }, [plansError]);

  const [
    updateAssociateMutation,
    { error: updateAssociateError, loading: updateAssociateLoading },
  ] = useMutation(UPDATE_ASSOCIATE, {
    onCompleted: () => {
      pushAlert({ type: "success", message: "Dados atualizados com sucesso!" });
    },
    refetchQueries: ["Associate"],
  });

  useEffect(() => {
    if (updateAssociateError) {
      pushAlert({ type: "error", message: updateAssociateError.message });
    }
  }, [updateAssociateError]);

  function handlerSubmit(event: any) {
    event.preventDefault();

    const admissionDate = data.admissionDate.split("/").reverse().join("-");
    updateAssociateMutation({
      variables: {
        data: {
          holderId: selectedHolder?.id ?? null,
          planId: data.planId,
          type: data.type,
          admissionDate,
          kinship: data.kinship,
          status: data.status,
          folder: data.folder ? parseInt(data.folder) : undefined,
        },
        associateId: associate.id,
      },
    });
  }

  const navigate = useNavigate();

  const [deleteAssociateMutation, { loading: deleteAssociateLoading }] = useMutation(
    DELETE_ASSOCIATE,
    {
      onCompleted: () => {
        pushAlert({ type: "success", message: "Associado excluído com sucesso!" });
        navigate(-1);
      },
      onError(error) {
        pushAlert({ type: "error", message: error.message });
      },
    }
  );

  function handlerDelete() {
    if (
      window
        .prompt(
          "ATENÇÃO, isso vai excluir PERMANENTEMENTE este associado e todas as guias vinculadas a ele.\n\ndigite 'CONFIRMAR' para continuar"
        )
        .toUpperCase() === "CONFIRMAR"
    ) {
      deleteAssociateMutation({
        variables: {
          associateId: associate.id,
        },
      });
    } else {
      pushAlert({ type: "warning", message: "Operação cancelada." });
    }
  }

  const { can } = usePermission();

  return (
    <>
      <AlertBox alerts={alerts} />
      <Grid container spacing={4} component="form" role="form" onSubmit={handlerSubmit}>
        {/* ----- */}
        <Grid item xs={12} xxl={6}>
          <Card sx={{ p: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={3}>
              Dados Pessoais
            </MDTypography>

            {/* <Divider sx={{ my: 2 }} /> */}

            <MDBox sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <MDTypography variant="subtitle2" color="dark" mr={2}>
                Nome: <strong>{associate?.person.name}</strong>
              </MDTypography>

              <MDTypography variant="subtitle2" color="dark" mr={2}>
                CPF: <strong>{associate?.person.cpf}</strong>
              </MDTypography>

              <MDTypography variant="subtitle2" color="dark">
                Idade:{" "}
                <strong>
                  {associate?.person.birthDate
                    ? getAge(associate?.person.birthDate) +
                      " anos (" +
                      getLocaleDateString(associate?.person.birthDate) +
                      ")"
                    : ""}
                </strong>
              </MDTypography>
            </MDBox>

            <Box
              sx={{
                display: "flex",
              }}
            >
              {can("READ_PERSON_LIST") && (
                <MDButton
                  variant="contained"
                  color="secondary"
                  sx={{ mr: "auto", alignItems: "center", justifyContent: "center" }}
                  to={`/pessoa/${associate?.person.id}`}
                  component={Link}
                >
                  Ver cadastro &nbsp;<Icon>person_outline</Icon>
                </MDButton>
              )}

              {associate?.type === "DEPENDENT" && (
                <MDButton
                  variant="contained"
                  color="success"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                  to={`/associado/${associate?.holder?.id}`}
                  component={Link}
                >
                  Ir para o titular &nbsp;<Icon>arrow_forward</Icon>
                </MDButton>
              )}
            </Box>
          </Card>

          <Card sx={{ p: 3, mt: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={3}>
              Status no sistema
            </MDTypography>
            <FormControl sx={{ bgcolor: "#fafafa", minWidth: 300 }}>
              <InputLabel id="select-status-label">Status</InputLabel>
              <Select
                labelId="select-status-label"
                value={data.status}
                label="Status"
                onChange={(e: any) => setData({ ...data, status: e.target.value })}
                MenuProps={{
                  sx: {
                    maxHeight: "250px",
                  },
                }}
              >
                <MenuItem value="ACTIVE">ATIVO</MenuItem>
                <MenuItem value="INACTIVE">INATIVO</MenuItem>
                {/* <MenuItem value="DEPENDENT">Dependente</MenuItem> */}
              </Select>
            </FormControl>
          </Card>
        </Grid>

        <Grid item xs={12} xxl={6}>
          <Card sx={{ p: 3 }}>
            <MDTypography variant="h5" gutterBottom mb={3}>
              Plano
            </MDTypography>

            {/* <Divider sx={{ my: 2 }} /> */}

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <FormControl fullWidth sx={{ bgcolor: "#fafafa", mr: 2, flex: 2 }} required>
                <InputLabel id="select-type-label">Tipo</InputLabel>
                <Select
                  labelId="select-type-label"
                  value={data.type}
                  label="Tipo"
                  onChange={(e: any) => setData({ ...data, type: e.target.value })}
                  required
                  sx={{
                    "& .MuiSelect-select": { color: "primary.main" },
                  }}
                >
                  <MenuItem value="HOLDER">TITULAR</MenuItem>
                  <MenuItem value="DEPENDENT">DEPENDENTE</MenuItem>
                </Select>
              </FormControl>

              <MDInput
                variant="outlined"
                label="Pasta"
                sx={{ bgcolor: "#fafafa", flex: 1 }}
                fullWidth
                disabled={data.type === "DEPENDENT"}
                // required
                value={data.folder}
                // inputProps={{ maxLength: 10, minLength: 10 }}
                onChange={(e: any) =>
                  setData({
                    ...data,
                    folder: e.target.value,
                  })
                }
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <FormControl fullWidth sx={{ bgcolor: "#fafafa", mr: 2 }} required>
                <InputLabel id="select-plan-label">Plano</InputLabel>
                <Select
                  labelId="select-plan-label"
                  value={data.planId}
                  label="Sexo"
                  onChange={(e: any) => setData({ ...data, planId: e.target.value })}
                >
                  {plansData?.plans.items.map((plan: any) => (
                    <MenuItem key={plan.id} value={plan.id}>
                      {plan.name} - {maskBRL(plan.price)} - {plan.coefficient * 100}%
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <MDInput
                variant="outlined"
                label="Data de Adesão"
                sx={{ bgcolor: "#fafafa" }}
                fullWidth
                required
                value={data.admissionDate}
                inputProps={{ maxLength: 10, minLength: 10 }}
                onChange={(e: any) =>
                  setData({
                    ...data,
                    admissionDate: maskBirthDate(e.target.value),
                  })
                }
              />
            </Box>

            {data.type === "DEPENDENT" && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {/* <MDInput
                  variant="outlined"
                  label="Id do Titular"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={data.holderId}
                  onChange={(e: any) =>
                    setData({
                      ...data,
                      holderId: e.target.value,
                    })
                  }
                  // inputProps={{ maxLength: 14 }}
                  required
                /> */}

                <Autocomplete
                  disablePortal
                  options={holdersOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  renderInput={(params) => <MDInput {...params} label="Titular" />}
                  value={getHolderACValue(selectedHolder?.id)}
                  onChange={(e: any, newValue: any) => {
                    setSelectedHolder(holders?.items?.find((h: any) => h?.id === newValue?.value));
                  }}
                />

                <FormControl fullWidth sx={{ bgcolor: "#fafafa" }}>
                  <InputLabel id="select-type-label">Parentesco</InputLabel>
                  <Select
                    labelId="select-type-label"
                    value={data.kinship}
                    label="Parentesco"
                    onChange={(e: any) => setData({ ...data, kinship: e.target.value })}
                  >
                    <MenuItem value="SPOUSE">Cônjuge</MenuItem>
                    <MenuItem value="CHILD">Filho(a)</MenuItem>
                    <MenuItem value="OTHER">Outro</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}

            {itsBeenDays(new Date(associate?.admissionDate), 365) && (
              <MDAlert color="primary">
                <Icon fontSize="small">error_outline</Icon>&nbsp; CARÊNCIA
              </MDAlert>
            )}
          </Card>

          <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {can("DELETE_ASSOCIATE") && (
              <MDButton
                variant="contained"
                color="error"
                // type="submit"
                loading={deleteAssociateLoading}
                sx={{ mr: "auto" }}
                onClick={handlerDelete}
              >
                EXCLUIR ASSOCIADO
              </MDButton>
            )}
            {can("UPDATE_ASSOCIATE") && (
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={updateAssociateLoading}
              >
                Salvar Dados
              </MDButton>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
}
