import { useEffect, useState } from "react";

export default function useUrlState<T>(key: string, initialValue: T) {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlValue = searchParams.get(key);
    if (urlValue) {
      setValue(JSON.parse(atob(urlValue)));
    }
  }, [key]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, btoa(JSON.stringify(value)));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  }, [key, value]);

  return [value, setValue] as const;
}
