import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import { AppointmentOwnerGroup } from "layouts/administration/configurations/appointmentOwnerGroups";
import BootstrapDialog from "components/BootstrapDialog";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";

const UPDATE = gql`
  mutation UpdateAppointmentOwnerGroup(
    $updateAppointmentOwnerGroupId: String!
    $data: CreateAppointmentOwnerGroupInput!
  ) {
    updateAppointmentOwnerGroup(id: $updateAppointmentOwnerGroupId, data: $data) {
      id
      name
      description
    }
  }
`;

const initialData = {
  name: "",
  description: "",
};

interface ModalEditProps {
  open: AppointmentOwnerGroup | null;
  setOpen: (value: AppointmentOwnerGroup | null) => void;
}

export default function ModalEdit({ open, setOpen }: ModalEditProps) {
  const [error, setError] = useState<null | Error>(null);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (!open?.id) return;
    setData({
      name: open.name,
      description: open.description ?? "",
    });
  }, [open]);

  const [mutationUpdate, { loading }] = useMutation(UPDATE, {
    onCompleted: () => {
      handlerCloseModalEdit();
    },
    onError(error) {
      setError(error);
    },
    refetchQueries: ["AppointmentOwnerGroups"],
  });

  function handlerSubmit() {
    if (!data.name) return setError(new Error("Nome é obrigatório"));

    mutationUpdate({
      variables: {
        updateAppointmentOwnerGroupId: open.id,
        data: {
          ...data,
        },
      },
    });
  }

  function handlerCloseModalEdit() {
    setOpen(null);
  }

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={!!open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Editar grupo
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
        </DialogContent>
        {!error || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setError(null)}>
            {error.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerSubmit}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loading}
          >
            Salvar alterações
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
