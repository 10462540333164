export const NODE_ENV = process.env.NODE_ENV ?? "development";

export const CBSAUDE_API_URL =
  NODE_ENV === "production" ? "https://api.cbsaude.com.br" : "http://192.168.1.10:3000";

export const CBSAUDE_URL =
  NODE_ENV === "production" ? "https://cbsaude.com.br" : "http://192.168.1.10:3001";

export const MONTHS_LABELS = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
} as {
  [key: number]: string;
};
