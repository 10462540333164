import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";

export interface AlertObject {
  type: "error" | "success" | "warning" | "info";
  message: string;
}

export default function AlertBox({ alerts }: { alerts: AlertObject[] }) {
  return (
    <MDBox
      sx={(theme) => ({
        position: "fixed",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        top: 100,
        right: 30,
        minWidth: "30%",
        maxWidth: "84%",
        [theme.breakpoints.up("md")]: {
          maxWidth: "50%",
        },
      })}
    >
      {alerts.map((alert: any, i: number) => (
        <MDAlert key={i} color={alert.type} sx={{ mb: 2 }} dismissible={true}>
          {alert.message}
        </MDAlert>
      ))}
    </MDBox>
  );
}
