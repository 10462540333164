import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";
import BootstrapDialog from "components/BootstrapDialog";

const CREATE_APPOINTMENT_OWNER = gql`
  mutation CreateAppointmentOwnerGroup($data: CreateAppointmentOwnerGroupInput!) {
    createAppointmentOwnerGroup(data: $data) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

const initialData = {
  name: "",
  description: "",
};

export default function ModalNew({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [error, setError] = useState<null | Error>(null);
  const [data, setData] = useState(initialData);

  const [mutationCreate, { loading }] = useMutation(CREATE_APPOINTMENT_OWNER, {
    onCompleted: () => {
      handlerCloseModal();
      setData(initialData);
    },
    refetchQueries: ["AppointmentOwnerGroups"],
    onError(error) {
      setError(error);
    },
  });

  function handlerSubmit() {
    if (!data.name) return setError(new Error("Nome é obrigatório"));

    mutationCreate({
      variables: {
        data: {
          ...data,
        },
      },
    });
  }

  function handlerCloseModal() {
    setOpen(false);
  }

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Novo Grupo de Agendas
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
        </DialogContent>
        {!error || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setError(null)}>
            {error.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerSubmit}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loading}
          >
            Salvar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
