import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useCallback } from "react";

export default function usePermission() {
  const permissions = useSelector((state: RootState) => state.session.user.permissions);

  const can = useCallback(
    (permission: string) => {
      return permissions.includes(permission);
    },
    [permissions]
  );

  const canSome = useCallback(
    (permissions: string[]) => {
      return permissions.some((permission) => can(permission));
    },
    [permissions]
  );

  const canAll = useCallback(
    (permissions: string[]) => {
      return permissions.every((permission) => can(permission));
    },
    [permissions]
  );

  return {
    can,
    canSome,
    canAll,
  };
}
