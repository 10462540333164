import { Box, Chip, FormControl, Icon, InputLabel, Menu, MenuItem, Select } from "@mui/material";
import MDButton from "components/MDButton";
import { useState } from "react";

export const availableTypes = [
  {
    label: "Titular",
    value: "HOLDER",
  },
  {
    label: "Dependente",
    value: "DEPENDENT",
  },
];

export const availableStatus = [
  {
    label: "Ativo",
    value: "ACTIVE",
  },
  {
    label: "Inativo",
    value: "INACTIVE",
  },
];

export const initialFilters = {
  types: availableTypes.map(({ value }) => value) as string[],
  status: availableStatus.map(({ value }) => value) as string[],
};

export interface FiltersMenuProps {
  filters: typeof initialFilters;
  setFilters: (filters: typeof initialFilters) => void;
}

export default function FiltersMenu({ filters, setFilters }: FiltersMenuProps) {
  //   const [filters, setFilters] = useState(initialFilters);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <MDButton
        variant="text"
        color="white"
        startIcon={<Icon>tune</Icon>}
        aria-controls={open ? "filters-menu-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Filtros
      </MDButton>

      <Menu
        id="filters-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "filters-menu-button",
        }}
      >
        <Box sx={{ px: 1, width: 300 }}>
          <FormControl sx={{ mt: 1 }} fullWidth error={filters.types.length === 0}>
            <InputLabel>Tipo de Associado</InputLabel>
            <Select
              multiple
              value={filters.types}
              onChange={(event) => {
                const value = event.target.value;
                setFilters({
                  ...filters,
                  types: typeof value === "string" ? value.split(",") : value,
                });
              }}
              label="Tipo de Associado"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={availableTypes.find((type) => type.value === value)?.label}
                    />
                  ))}
                </Box>
              )}
            >
              {availableTypes.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 3 }} fullWidth error={filters.status.length === 0}>
            <InputLabel>Status</InputLabel>
            <Select
              multiple
              value={filters.status}
              onChange={(event) => {
                const value = event.target.value;
                setFilters({
                  ...filters,
                  status: typeof value === "string" ? value.split(",") : value,
                });
              }}
              label="Status"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={availableStatus.find((type) => type.value === value)?.label}
                    />
                  ))}
                </Box>
              )}
            >
              {availableStatus.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Menu>
    </>
  );
}
