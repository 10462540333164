import { TablePagination as MUITablePagination } from "@mui/material";
import TablePaginationActions from "components/TablePaginationActions";
import { useEffect, useState } from "react";

export interface TablePaginationProps {
  count: number;
  take: number;
  setTake: React.Dispatch<React.SetStateAction<number>>;
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
}

export default function TablePagination({
  count,
  take,
  skip,
  setTake,
  setSkip,
}: TablePaginationProps) {
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (skip === 0) {
      setPage(0);
    }
  }, [skip]);

  return (
    <MUITablePagination
      rowsPerPageOptions={[10, 25, 50]}
      colSpan={99}
      count={count}
      rowsPerPage={take}
      page={page}
      labelRowsPerPage="Registros por página"
      labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
        return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
      }}
      onPageChange={(event, newPage) => {
        setPage(newPage);
        setSkip(newPage * take);
      }}
      onRowsPerPageChange={(event) => {
        setTake(parseInt(event.target.value, 10));
        setPage(0);
        setSkip(0);
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
}
