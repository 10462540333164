import MDButton from "components/MDButton";
import { useState } from "react";
import { Icon, Menu, MenuItem, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import ModalImportPeople from "./components/ModalImportPeople";
import usePermission from "hooks/usePermission";

export default function Actions() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { can, canSome } = usePermission();

  const [openModalImportPeople, setOpenModalImportPeople] = useState(false);
  return (
    <>
      <ModalImportPeople open={openModalImportPeople} setOpen={setOpenModalImportPeople} />
      {canSome(["READ_PERSON_LIST", "IMPORT_PEOPLE"]) && (
        <MDButton
          variant="text"
          color="white"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<Icon>menu</Icon>}
        >
          Ações
        </MDButton>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {can("CREATE_PERSON") && (
          <MenuItem component={Link} to="/pessoa/cadastrar">
            <Icon sx={{ mr: 1 }}>add</Icon>
            Adicionar Pessoa
          </MenuItem>
        )}

        <Divider sx={{ my: 0.5 }} />

        {can("IMPORT_PEOPLE") && (
          <MenuItem onClick={() => setOpenModalImportPeople(true)}>
            <Icon sx={{ mr: 1 }}>file_upload</Icon>Importar
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
