import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";
import { gql, useLazyQuery } from "@apollo/client";
import MDAlert from "components/MDAlert";
import Timeline from "@mui/lab/Timeline";
import { timelineContentClasses } from "@mui/lab/TimelineContent";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import AppointmentItem from "./components/AppointmentItem";
import AlertBox, { AlertObject } from "components/AlertBox";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import usePermission from "hooks/usePermission";

const GET_MEDICAL_APPOINTMENTS = gql`
  query MedicalAppointments($filters: FindMedicalAppointmentFilters, $take: Float, $skip: Float) {
    medicalAppointments(filters: $filters, take: $take, skip: $skip) {
      total
      items {
        id
        contents
        type
        associateId
        professionalId
        createdAt
        updatedAt
        professional {
          id
          name
        }
      }
    }
  }
`;

export interface Appointment {
  id?: string; // if not exist, it's a new appointment in creation
  contents?: string;
  type: string;
  associateId: string;
  professionalId: string;
  createdAt: Date;
  professional?: {
    id: string;
    name: string;
  };
}
export default function AppointmentsTimeline({ associate, type }: any) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const user = useSelector((state: RootState) => state.session.user);

  const [showAlertNotFound, setShowAlertNotFound] = useState(false);

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [getAppointments, { loading }] = useLazyQuery(GET_MEDICAL_APPOINTMENTS, {
    onCompleted(data) {
      setAppointments(
        data.medicalAppointments.items.map((item: any) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }))
      );

      if (data.medicalAppointments.items.length === 0) {
        setShowAlertNotFound(true);
      } else {
        setShowAlertNotFound(false);
      }
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  useEffect(() => {
    if (associate?.id && type) {
      getAppointments({
        variables: {
          filters: {
            associateId: associate.id,
            type,
          },
          take: 99999,
          skip: 0,
        },
      });
    }
  }, [associate, type]);

  const { can } = usePermission();

  return (
    <>
      <AlertBox alerts={alerts} />

      {can("CREATE_MEDICALAPPOINTMENT") && (
        <MDBox display="flex" mb={2}>
          {loading ? (
            <CircularProgress sx={{ mx: "auto" }} />
          ) : (
            <MDButton
              variant="gradient"
              color="primary"
              sx={{
                ml: "auto",
              }}
              onClick={() =>
                setAppointments([
                  {
                    type,
                    createdAt: new Date(),
                    associateId: associate.id,
                    professionalId: user.id,
                  },
                  ...appointments,
                ])
              }
              disabled={appointments[0] && typeof appointments[0].id === "undefined"}
            >
              Novo Atendimento
            </MDButton>
          )}
        </MDBox>
      )}

      {/* bgColor="secondary" color="white" p={3} borderRadius="lg" */}
      {showAlertNotFound && (
        <MDAlert my={5} color="warning" dismissible onClose={() => setShowAlertNotFound(false)}>
          Ainda não foi cadastrado nenhum atendimento deste tipo para este associado.
        </MDAlert>
      )}

      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: "initial",
            pl: 0,
          },
          [`& .${timelineContentClasses.root}`]: {
            pr: 0,
          },
          p: 0,
        }}
      >
        {appointments.map((item, index: number) => (
          <AppointmentItem
            key={item.id ?? "new"}
            appointment={item}
            dateColor={index === 0 ? "info" : "secondary"}
            setAppointments={setAppointments}
          />
        ))}
      </Timeline>
    </>
  );
}
