import { useState } from "react";
import { Autocomplete, SxProps, Theme } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import MDInput from "components/MDInput";

const GET_USER_GROUPS = gql`
  query UserGroups {
    userGroups {
      id
      name
    }
  }
`;

interface UserGroup {
  id: string;
  name: string;
}

export interface UserGroupsSelectorProps {
  userGroups: UserGroup[];
  setUserGroups: (userGroups: UserGroup[]) => void;
  sx?: SxProps<Theme>;
}

export default function UserGroupsSelector({
  userGroups,
  setUserGroups,
  sx,
}: UserGroupsSelectorProps) {
  const [userGroupOptions, setUserGroupOptions] = useState<UserGroup[]>([]);

  const { loading } = useQuery(GET_USER_GROUPS, {
    onCompleted: (data) => {
      setUserGroupOptions(data.userGroups);
    },
    onError(error) {
      alert(error.message);
    },
  });

  return (
    <Autocomplete
      multiple
      disablePortal
      options={userGroupOptions}
      getOptionLabel={(option) => option.name}
      fullWidth
      sx={sx}
      renderInput={(params) => <MDInput {...params} label="Níveis de Acesso" />}
      value={userGroups}
      onChange={(e: any, newValue: any) => {
        setUserGroups(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
    />
  );
}
