import { useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { maskBirthDate, maskBRL } from "utils/mask";

import { useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import { justNumbers } from "utils/justNumbers";
import CardFiles from "./components/CardFiles";

const UPDATE_EXPENSE = gql`
  mutation UpdateOperationalExpense(
    $operationalExpenseId: String!
    $data: UpdateOperationalExpenseInput!
  ) {
    updateOperationalExpense(operationalExpenseId: $operationalExpenseId, data: $data) {
      id
      amount
      date
      description
      updatedAt
      createdAt
    }
  }
`;

const GET_EXPENSE = gql`
  query OperationalExpense($operationalExpenseId: String!) {
    operationalExpense(id: $operationalExpenseId) {
      id
      description
      paymentDescription
      type {
        id
        name
      }
      date
      amount
      createdAt
      updatedAt
    }
  }
`;

const GET_TYPES = gql`
  query OperationalExpenseTypes {
    operationalExpenseTypes {
      id
      name
    }
  }
`;

export default function ViewOperationalExpense() {
  const { operationalExpenseId } = useParams();

  const [typesOptions, setTypesOptions] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<any>(null);
  function getTypeACValue(typeId: number) {
    if (!typeId) return null;
    return typesOptions.find((s) => s.value === typeId);
  }
  const { data: { operationalExpenseTypes: types } = {} } = useQuery(GET_TYPES, {
    onCompleted: (data) => {
      setTypesOptions(
        data.operationalExpenseTypes.map((p: any) => ({
          value: p.id,
          label: `${p.name}`,
        }))
      );
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  //   const navigate = useNavigate();
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  // const [guides, setGuides] = useState<Guide[]>([]);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [type, setType] = useState("");
  //

  const { data: { operationalExpense } = {}, loading } = useQuery(GET_EXPENSE, {
    variables: {
      operationalExpenseId: operationalExpenseId,
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
    onCompleted(data) {
      setAmount(maskBRL(data.operationalExpense.amount));
      setDate(data.operationalExpense.date.split("-").reverse().join("/"));
      setDescription(data.operationalExpense.description);
      setPaymentDescription(data.operationalExpense.paymentDescription);
      setType(data.operationalExpense.type);
      setSelectedType(data.operationalExpense.type);
    },
  });

  const [updateOperationalExpenseMutation, { loading: loadingUpdateOperationalExpense }] =
    useMutation(UPDATE_EXPENSE, {
      onCompleted: () => {
        pushAlert({ type: "success", message: "Dados salvos com sucesso!" });
      },
      onError(error) {
        pushAlert({ type: "error", message: error.message });
      },
      refetchQueries: ["OperationalExpenses"],
    });

  function handlerSubmit(event: any) {
    event.preventDefault();

    updateOperationalExpenseMutation({
      variables: {
        operationalExpenseId: operationalExpense?.id,
        data: {
          amount: +justNumbers(amount),
          description,
          paymentDescription,
          typeId: selectedType?.id,
          date: date.split("/").reverse().join("-"),
        },
      },
    });
  }

  // useEffect(() => {
  //   setAmount(
  //     maskBRL(guides.reduce((ac: number, item: any) => (item.included ? item.amount : 0) + ac, 0))
  //   );
  // }, [guides]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AlertBox alerts={alerts} />
        <Grid
          container
          spacing={4}

          // justifyContent="center"
        >
          {/* ----- */}
          <Grid item xs={12} xxl={6} component="form" role="form" onSubmit={handlerSubmit}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom>
                Pagamento {loading && <CircularProgress color="inherit" size={16} />}
              </MDTypography>
              <Divider sx={{ my: 1 }} />

              <MDInput
                variant="outlined"
                label="Valor"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={amount}
                onChange={(e: any) => setAmount(maskBRL(e.target.value))}
                required
              />
              <MDInput
                variant="outlined"
                label="Data"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={date}
                onChange={(e: any) => setDate(maskBirthDate(e.target.value))}
                required
              />

              {/* <FormControl fullWidth sx={{ bgcolor: "#fafafa", mt: 2 }} required>
                <InputLabel id="select-type-label">Situação</InputLabel>
                <Select
                  labelId="select-situation-label"
                  value={type}
                  label="Situação"
                  onChange={(e: any) => setType(e.target.value)}
                >
                  <MenuItem value="FUNCIONÁRIOS CBSAUDE">FUNCIONÁRIOS CBSAUDE</MenuItem>
                  <MenuItem value="GUARDA-VIDA">GUARDA-VIDA</MenuItem>
                  <MenuItem value="ESTAGIÁRIOS">ESTAGIÁRIOS</MenuItem>
                  <MenuItem value="OBRIGAÇÕES PATRONAIS">OBRIGAÇÕES PATRONAIS</MenuItem>
                  <MenuItem value="PRESTADORES DE SERVIÇO">PRESTADORES DE SERVIÇO</MenuItem>
                  <MenuItem value="MATERIAL DE CONSUMO, LIMPEZA E PERMANENTE">
                    MATERIAL DE CONSUMO, LIMPEZA E PERMANENTE
                  </MenuItem>
                  <MenuItem value="DESPESAS FIXAS">DESPESAS FIXAS</MenuItem>
                  <MenuItem value="RESSARCIAMENTO">RESSARCIAMENTO</MenuItem>
                  <MenuItem value="IMPOSTOS">IMPOSTOS</MenuItem>
                  <MenuItem value="DEVOLUÇÃO COTA CAPITAL">DEVOLUÇÃO COTA CAPITAL</MenuItem>
                </Select>
              </FormControl> */}

              <Autocomplete
                disablePortal
                options={typesOptions}
                fullWidth
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                renderInput={(params) => <MDInput {...params} label="Situação" />}
                value={getTypeACValue(selectedType?.id)}
                onChange={(e: any, newValue: any) => {
                  setSelectedType(types?.find((a: any) => a.id === newValue?.value));
                }}
              />

              <MDInput
                variant="outlined"
                label="Especificação da Despesa"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                multiline
                rows={3}
                required
              />

              <MDInput
                variant="outlined"
                label="Forma de pagamento/Como foi pago"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={paymentDescription}
                onChange={(e: any) => setPaymentDescription(e.target.value)}
                multiline
                rows={3}
              />
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={loadingUpdateOperationalExpense}
              >
                Salvar Dados
              </MDButton>
            </MDBox>
          </Grid>

          <Grid item xs={12} xxl={6}>
            <CardFiles operationalExpense={operationalExpense} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
