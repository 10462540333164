import Grid from "@mui/material/Grid";

import MDBox from "../../components/MDBox";

import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import Footer from "../../components/Footer";
import ComplexStatisticsCard from "../../components/Cards/StatisticsCards/ComplexStatisticsCard";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "redux/store";
// import { useEffect } from "react";

function Dashboard() {
  // const navigate = useNavigate();
  // const user = useSelector((state: RootState) => state.session.user);

  // useEffect(() => {
  //   if (user) {
  //     if (user.permissions.includes("READ_GUIDE_SELF")) {
  //       return navigate("/minhas-guias", { replace: true });
  //     }
  //   }
  // }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="search"
                title="Quadro 1"
                count={""}
                // loading={loading}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Descrição quadro 1",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person_add"
                title="Quadro 2"
                count={""}
                // loading={loading}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Descrição quadro 2",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="difference"
                title="Quadro 3"
                count={""}
                // loading={loading}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Descrição quadro 3",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="attach_money"
                title="Quadro 4"
                count={""}
                // loading={loading}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Descrição quadro 4",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
