import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { Card, Box, CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { DateSelectArg, EventClickArg, EventContentArg, EventInput } from "@fullcalendar/core";
import CalendarRoot from "layouts/appointments/components/CalendarRoot";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import ModalEvent from "layouts/appointments/components/ModalEvent";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { XOR } from "types/XOR";
import OwnerSelector, { AppointmentOwner } from "layouts/appointments/components/OwnerSelector";
import { useLocalStorage } from "hooks/useLocalStorage";

const GET = gql`
  query Appointments($filters: FindAppointmentsFilters) {
    appointments(filters: $filters) {
      id
      description
      color
      start
      end
      ownerId
    }
  }
`;

const CREATE = gql`
  mutation CreateAppointment($data: CreateAppointmentInput!) {
    createAppointment(data: $data) {
      id
      description
      color
      start
      end
      ownerId
    }
  }
`;

const UPDATE = gql`
  mutation UpdateAppointment($updateAppointmentId: String!, $data: UpdateAppointmentInput!) {
    updateAppointment(id: $updateAppointmentId, data: $data) {
      id
      description
      color
      start
      end
      ownerId
    }
  }
`;

const DELETE = gql`
  mutation DeleteAppointment($deleteAppointmentId: String!) {
    deleteAppointment(id: $deleteAppointmentId)
  }
`;

function renderEventContent(eventInfo: EventContentArg) {
  return (
    <>
      <MDTypography variant="button" fontWeight="bold">
        {eventInfo.timeText}
      </MDTypography>

      <MDTypography variant="button"> | {eventInfo.event.title}</MDTypography>
    </>
  );
}

export interface IAppointment {
  id: string | null;
  description: string;
  color: string;
  start: Date;
  end: Date;
}

export default function Appointments() {
  const [events, setEvents] = useState<EventInput[]>([]); // INITIAL_EVENTS
  const [openEventModal, setOpenEventModal] = useState<XOR<DateSelectArg, EventClickArg> | null>(
    null
  );
  const [owner, setOwner] = useState<AppointmentOwner>(null);
  const [datesFilter, setDatesFilter] = useState<{ start: Date; end: Date }>(null);
  const [showWeekends, setShowWeekends] = useLocalStorage("appointments:showWeekends", false);

  const [update] = useMutation(UPDATE, {
    onError(error) {
      alert(error);
    },
  });

  const [create] = useMutation(CREATE, {
    onError(error) {
      alert(error);
    },
  });

  const [mutationDelete] = useMutation(DELETE, {
    onError(error) {
      alert(error);
    },
  });

  function handleDateSelect(selectInfo: DateSelectArg) {
    setOpenEventModal(selectInfo);
  }

  function handleEventClick(clickInfo: EventClickArg) {
    setOpenEventModal(clickInfo);
  }

  const [getAppointments, { loading, data }] = useLazyQuery(GET, {
    onError(error) {
      alert(error);
    },
  });

  useEffect(() => {
    if (data) {
      if (data.appointments.length === 0) {
        return setEvents([{ id: "dummy", title: "Sim, precisa disso", start: "2000-01-01T00:00" }]);
      }
      setEvents(
        data.appointments.map((appointment: any) => {
          return {
            id: appointment.id,
            title: appointment.description,
            start: appointment.start,
            end: appointment.end,
            className: `event-${appointment.color}`,
            extendedProps: {
              ownerId: "bd7dea4b-a4c9-4ffc-be60-46264a6395a0",
              color: appointment.color,
            },
            resourceId: appointment.ownerId,
          } as EventInput;
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const { start, end } = datesFilter ?? {};
    if (owner && start && end) {
      getAppointments({
        variables: {
          filters: {
            start: {
              gte: start,
            },
            end: {
              lt: end,
            },
            ownerId: owner.id,
          },
        },
      });
    }
  }, [owner, datesFilter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ModalEvent open={openEventModal} setOpen={setOpenEventModal} owner={owner} />

      <MDBox pt={3} pb={3} sx={{ height: "93vh", display: "flex", flexDirection: "column" }}>
        <Card sx={{ flex: 1, p: 3, borderTop: "5px solid #606876" }}>
          <Box
            sx={{
              mb: -5,
            }}
          >
            <OwnerSelector
              ownerId={owner?.id ?? null}
              setOwner={setOwner}
              sx={{
                bgcolor: "white",
                width: "250px",
                "& .MuiAutocomplete-inputRoot": {
                  borderRadius: "50px",
                  pl: 1,
                },
              }}
            />
          </Box>
          <CalendarRoot>
            {loading && (
              <CircularProgress
                size={16}
                sx={{
                  position: "absolute",
                  top: "6px",
                  right: "6px",
                  zIndex: 99999999,
                }}
              />
            )}
            <FullCalendar
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
              headerToolbar={{
                left: "",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay prev,next",
              }}
              buttonText={{
                today: "Hoje",
                month: "Mês",
                week: "Semana",
                day: "Dia",
                list: "Lista",
              }}
              titleFormat={{
                year: "numeric",
                month: "long",
                day: "numeric",
              }}
              businessHours={{
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: "07:00",
                endTime: "14:00",
              }}
              nowIndicator
              initialView="timeGridWeek" //timeGridWeek
              locale="pt-br"
              // timeZone="America/Rio_Branco"
              height={"100%"}
              editable={true}
              selectable={true}
              selectMirror={true}
              // dayMaxEvents={true}
              weekends={showWeekends}
              allDaySlot={false}
              // weekends={this.state.weekendsVisible}
              events={events} // alternatively, use the `events` setting to fetch from a feed
              select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              //you can update a remote database when these fire:
              eventAdd={(arg) => {
                create({
                  variables: {
                    data: {
                      id: arg.event.id,
                      description: arg.event.title,
                      color: arg.event.extendedProps.color,
                      start: arg.event.start,
                      end: arg.event.end,
                      ownerId: arg.event.extendedProps.ownerId,
                    },
                  },
                });
              }}
              eventChange={(arg) => {
                update({
                  variables: {
                    updateAppointmentId: arg.event.id,
                    data: {
                      description: arg.event.title,
                      color: arg.event.extendedProps.color,
                      start: arg.event.start,
                      end: arg.event.end,
                    },
                  },
                });
              }}
              eventRemove={(arg) => {
                mutationDelete({
                  variables: {
                    deleteAppointmentId: arg.event.id,
                  },
                });
              }}
              datesSet={(arg) => {
                setDatesFilter({
                  start: arg.start,
                  end: arg.end,
                });
              }}
            />
          </CalendarRoot>
          <FormControlLabel
            control={
              <Switch checked={showWeekends} onChange={(e) => setShowWeekends(e.target.checked)} />
            }
            label={"Mostrar finais de semana"}
            sx={{
              mb: -2,
            }}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
