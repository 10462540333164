// Base Styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;
const { dark, grey } = colors;

// types
type Types = any;

const tab: Types = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.md,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: pxToRem(8),
      borderRadius: borderRadius.lg,
      color: `${dark.main} !important`,
      opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "&.MuiTab-root.Mui-selected.MuiTab-textColorPrimary": {
        color: `#ffffff !important`,
        transition: "color 500ms ease",
        // transitionDelay: "100ms",
      },

      "&.MuiTab-root.Mui-disabled": {
        // backgroundColor: "rgba(255, 255, 255, 0.30)",
        color: `${grey[400]} !important`,
      },
    },

    labelIcon: {
      // paddingTop: pxToRem(4),
    },
  },
};

export default tab;
