import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";

const CREATE_PLAN = gql`
  mutation CreatePlan($data: CreatePlanInput!) {
    createPlan(data: $data) {
      id
      name
      description
      price
      coefficient
      createdAt
      updatedAt
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const newPlanInitialData = {
  name: "",
  cod: "",
  description: "",
  price: "",
  coefficient: "",
};

export default function ModalNewPlan({ openModalNewPlan, setOpenModalNewPlan }: any) {
  const [createPlanError, setCreatePlanError] = useState<null | Error>(null);
  const [data, setData] = useState(newPlanInitialData);

  // on complete, reload GET_PLANS
  const [createPlanMutation, { error: ApolloCreatePlanError, loading: loadingCreatePlan }] =
    useMutation(CREATE_PLAN, {
      onCompleted: () => {
        handlerCloseModal();
        setData(newPlanInitialData);
      },
      refetchQueries: ["GetPlans"],
    });

  function handlerCreatePlan() {
    if (!data.name) return setCreatePlanError(new Error("Nome é obrigatório"));

    createPlanMutation({
      variables: {
        data: {
          ...data,
          price: +justNumbers(data.price),
          coefficient: +data.coefficient,
        },
      },
    });
  }

  function handlerCloseModal() {
    setOpenModalNewPlan(false);
  }

  useEffect(() => {
    setCreatePlanError(ApolloCreatePlanError);
  }, [ApolloCreatePlanError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModalNewPlan}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Novo plano
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Código"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.cod}
            onChange={(e: any) => setData({ ...data, cod: e.target.value })}
          />
          <MDInput
            variant="outlined"
            label="Valor da mensalidade"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.price}
            onChange={(e: any) => {
              setData({ ...data, price: maskBRL(e.target.value) });
            }}
            required
          />
          <MDInput
            variant="outlined"
            label="Coeficiente"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.coefficient}
            onChange={(e: any) => {
              setData({ ...data, coefficient: maskCoefficientInput(e.target.value) });
            }}
            helperText="O coeficiente é o valor que será multiplicado pelo preço do procedimento médico para obter o valor que será cobrado do associado."
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />

          {+data.coefficient >= 2 && (
            <MDAlert color="warning">
              O coeficiente está MUITO alto. Tem certeza que o valor está correto?
            </MDAlert>
          )}
        </DialogContent>
        {!createPlanError || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setCreatePlanError(null)}>
            {createPlanError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerCreatePlan}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingCreatePlan}
          >
            Criar plano
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
