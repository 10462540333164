import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { removeSession } from "redux/session";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeSession());
  }, []);

  return <Navigate to="/login" />;
}
