import { MONTHS_LABELS } from "config/constants";
import { decodeMonth } from "utils/codeMonth";
import * as xlsx from "xlsx-js-style";
import { AssociateCreditsReportInput } from ".";

// export default function generate(data: any, { month }: AssociateCreditsReportInput) {
//   const decodedMonth = decodeMonth(month);
//   const groupedCredits = data?.associateCredits?.items.reduce((acc: any, curr: any) => {
//     const index = acc.findIndex((credit: any) => credit.associate.id === curr.associate.id);

//     if (index !== -1) {
//       acc[index] = {
//         ...acc[index],
//         amount: acc[index].amount + curr.amount,
//       };
//     } else {
//       const [registration, ctr] = curr.associate.person.registration?.split("-") ?? [];
//       acc.push({
//         ...curr,
//         registration,
//         ctr,
//       });
//     }

//     return acc;
//   }, []);

//   const _credits = groupedCredits.map((c: any) => ({
//     registration: c.registration,
//     ctr: c.ctr,
//     name: c.associate.person.name.toUpperCase(),
//     amount: c.amount / 100,
//   }));

//   const worksheet = xlsx.utils.json_to_sheet(_credits);

//   for (let i = 2; i <= _credits.length + 1; i++) {
//     const cell = worksheet[`D${i}`];
//     cell.z = "[$R$-416]\\ #,##0.00;[RED]\\-[$R$-416]\\ #,##0.00'";
//   }

//   worksheet.A1.v = "Matrícula";
//   worksheet.B1.v = "Ctr";
//   worksheet.C1.v = "Servidor";
//   worksheet.D1.v = "Valor";

//   const name_max_width = _credits.reduce((w: any, r: any) => Math.max(w, r.name.length), 10);

//   worksheet["!cols"] = [{ wch: 10 }, { wch: 10 }, { wch: name_max_width + 5 }, { wch: 11 }];

//   const workbook = xlsx.utils.book_new();
//   xlsx.utils.book_append_sheet(workbook, worksheet, "Parcelas");
//   xlsx.writeFile(
//     workbook,
//     `PLANILHA COM PARCELAS - ${MONTHS_LABELS[decodedMonth.month]}_${decodedMonth.year}.xlsx`
//   );
// }

function cell(
  value: string | number,
  {
    t = "s",
    z = "",
    bold = false,
    textAlign = "left",
    fontSize = "12",
    border = "",
    borderTop = "",
  } = {}
) {
  return {
    v: value,
    t,
    z: z ? z : undefined,
    s: {
      font: {
        name: "Arial",
        sz: fontSize,
        bold,
      },
      alignment: { horizontal: textAlign },
      border: {
        top: borderTop || border ? { style: borderTop || border } : undefined,
        bottom: border ? { style: border } : undefined,
        left: border ? { style: border } : undefined,
        right: border ? { style: border } : undefined,
      },
    },
  };
}

function getHeader({
  monthLabel,
  yearLabel,
  total,
  cota,
  title,
}: {
  monthLabel: string;
  yearLabel: string;
  total: number;
  cota: string;
  title: string;
}) {
  return [
    [cell("GOVERNO DO ESTADO DO ACRE"), undefined, undefined, undefined, cell("SIARH - (FX24)")],
    [cell("CORPO DE BOMBEIROS MILITAR"), , , cell("Lancamento de Fichas Financeiras em Lote")],
    [cell("COMANDO GERAL"), , , cell("(N)ormal (C)omplementar (J)udicial (R)escisão")],
    [
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
      cell("", { borderTop: "thick" }),
    ],
    [
      cell("Ano Lote", { textAlign: "center", border: "thin" }),
      ,
      cell("Mês", { textAlign: "center", border: "thin" }),
      ,
      ,
      ,
      cell("Lançamento", { border: "thin" }),
    ],
    [
      cell(yearLabel, { textAlign: "center", bold: true, border: "thin" }),
      ,
      cell(monthLabel, { textAlign: "center", bold: true, border: "thin" }),
      cell("NORMAL", { border: "thin" }),
    ],
    [],
    [
      cell("", { borderTop: "thin" }),
      cell("", { borderTop: "thin" }),
      cell(title, { textAlign: "center", bold: true, border: "thin" }),
      cell("Verbas", { textAlign: "center", border: "thin" }),
      cell("Valor", { textAlign: "center", border: "thin" }),
      cell("Qtde", { textAlign: "center", border: "thin" }),
      cell("Perc.%", { textAlign: "center", border: "thin" }),
      cell("Cota", { textAlign: "center", border: "thin" }),
    ],
    [
      ,
      ,
      ,
      ,
      cell(total, {
        t: "n",
        z: "[$R$-416]\\ #,##0.00;[RED]\\-[$R$-416]\\ #,##0.00'",
        textAlign: "center",
        border: "thin",
      }),
      cell("0", { textAlign: "center", border: "thin" }),
      cell("0", { textAlign: "center", border: "thin" }),
      cell(cota, { textAlign: "center", border: "thin" }),
    ],
    [cell("PLAN 01", { textAlign: "center", border: "thin" })],
    [
      cell("Matrícula", { textAlign: "center", border: "thin" }),
      cell("Ctr", { textAlign: "center", border: "thin" }),
      cell("Servidor", { textAlign: "center", border: "thin" }),
      cell("Verba", { textAlign: "center", border: "thin" }),
      cell("Valor", { textAlign: "center", border: "thin" }),
      cell("Qtd", { textAlign: "center", border: "thin" }),
      cell("Perc", { textAlign: "center", border: "thin" }),
      cell("Cota", { textAlign: "center", border: "thin" }),
    ],
  ];
}

const espacings = [
  { wch: 10 },
  { wch: 5 },
  { wch: 50 },
  { wch: 15 },
  { wch: 12 },
  { wch: 7 },
  { wch: 12 },
  { wch: 7 },
];

interface ICredit {
  registration: string;
  ctr: string;
  name: string;
  amount: number;
}

function generateSheet(credits: ICredit[], { month, title }: { month: number; title: string }) {
  const decodedMonth = decodeMonth(month);
  //   const _credits = credits.map((c: any) => ());

  const total = credits.reduce((acc: any, curr: any) => acc + curr.amount, 0);

  // ==============================
  const worksheet = xlsx.utils.aoa_to_sheet(
    getHeader({
      monthLabel: MONTHS_LABELS[decodedMonth.month].toUpperCase(),
      yearLabel: decodedMonth.year.toString(),
      title,
      total,
      cota: "" + credits.length,
    })
  );
  worksheet["!cols"] = espacings;

  const newRows = credits.map(({ registration, ctr, name, amount }: any) => [
    cell(registration, { textAlign: "center", fontSize: "10", border: "thin" }),
    cell(ctr, { textAlign: "center", fontSize: "10", border: "thin" }),
    cell(name, { fontSize: "10", border: "thin" }),
    cell("2181", { textAlign: "center", fontSize: "10", border: "thin" }),
    cell(amount, {
      t: "n",
      z: "[$R$-416]\\ #,##0.00;[RED]\\-[$R$-416]\\ #,##0.00'",
      textAlign: "center",
      fontSize: "10",
      border: "thin",
    }),
    cell("", { textAlign: "center", fontSize: "10", border: "thin" }),
    cell("", { textAlign: "center", fontSize: "10", border: "thin" }),
    cell("1", { textAlign: "center", fontSize: "10", border: "thin" }),
  ]);

  xlsx.utils.sheet_add_aoa(worksheet, newRows, { origin: -1 });

  return worksheet;
}
export default function generate(data: any, { month }: AssociateCreditsReportInput) {
  const groupedCredits = data?.associateCredits?.items.reduce((acc: any, curr: any) => {
    const index = acc.findIndex((credit: any) => credit.associate.id === curr.associate.id);

    if (index !== -1) {
      acc[index] = {
        ...acc[index],
        amount: acc[index].amount + curr.amount,
      };
    } else {
      const [registration, ctr] = curr.associate.person.registration?.split("-") ?? [];
      acc.push({
        ...curr,
        registration,
        ctr,
      });
    }

    return acc;
  }, []);

  const sortedCredits = groupedCredits.sort((a: any, b: any) => {
    if (a.associate.person.name < b.associate.person.name) {
      return -1;
    } else if (a.associate.person.name > b.associate.person.name) {
      return 1;
    } else {
      return 0;
    }
  });

  const creditsBySituation = {
    ACTIVE: [] as ICredit[],
    INACTIVE: [] as ICredit[],
    PENSIONER: [] as ICredit[],
    CIVIL: [] as ICredit[],
    RECALLED: [] as ICredit[],
    NO_SITUATION: [] as ICredit[],
  };

  for (const credit of sortedCredits) {
    const situation = credit.associate.person.situation;
    if (!situation) {
      creditsBySituation.NO_SITUATION.push({
        registration: credit.registration,
        ctr: credit.ctr,
        name: credit.associate.person.name.toUpperCase(),
        amount: credit.amount / 100,
      });
    } else {
      try {
        creditsBySituation[situation as keyof typeof creditsBySituation].push({
          registration: credit.registration,
          ctr: credit.ctr,
          name: credit.associate.person.name.toUpperCase(),
          amount: credit.amount / 100,
        });
      } catch (error) {
        throw new Error(`Situação não cadastrada no client WEB: ${situation}`);
      }
    }
  }

  const workbook = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(
    workbook,
    generateSheet(creditsBySituation.ACTIVE, { month, title: "ATIVOS" }),
    "ATIVOS"
  );

  xlsx.utils.book_append_sheet(
    workbook,
    generateSheet(creditsBySituation.INACTIVE, { month, title: "INATIVOS" }),
    "INATIVOS"
  );

  xlsx.utils.book_append_sheet(
    workbook,
    generateSheet(creditsBySituation.CIVIL, { month, title: "FUNCIONÁRIOS CIVIS" }),
    "FUNC. CIVIS"
  );

  xlsx.utils.book_append_sheet(
    workbook,
    generateSheet(creditsBySituation.PENSIONER, { month, title: "PENSIONISTAS" }),
    "PENSIONISTAS"
  );

  xlsx.utils.book_append_sheet(
    workbook,
    generateSheet(creditsBySituation.RECALLED, { month, title: "RECONVOCADOS" }),
    "RECONVOCADOS"
  );

  xlsx.utils.book_append_sheet(
    workbook,
    generateSheet(creditsBySituation.NO_SITUATION, { month, title: "SEM SITUAÇÃO" }),
    "SEM SITUAÇÃO"
  );

  const decodedMonth = decodeMonth(month);
  xlsx.writeFile(
    workbook,
    `RECEITAS DE GUIAS - ${MONTHS_LABELS[decodedMonth.month].toUpperCase()}_${
      decodedMonth.year
    }.xlsx`
  );
}
