export function maskcpf(v: string) {
  if (!v) return "";
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return v;
}

export function maskcnpj(v: string) {
  if (!v) return "";
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");
  return v;
}

export function maskBirthDate(v: string) {
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{2})(\d)/, "$1/$2");
  v = v.replace(/(\d{2})(\d)/, "$1/$2");
  return v;
}

// export function maskmoney(v: string) {
//   v = v.replace(/\D/g, "");
//   v = v.replace(/(\d)(\d{2})$/, "$1,$2");
//   v = v.replace(/(?=(\d{3})+(\D))\B/g, ".");
//   return v;
// }

// function formatCurrency(value: string) {
//   const formatter = new Intl.NumberFormat("pt-BR", {
//     style: "currency",
//     currency: "BRL",
//   });

//   return formatter.format(+value);
// }

export function maskBRL(value: string | number) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  if (typeof value === "number") {
    value = value.toFixed(0);
  } else if (typeof value === "string") {
    //
  } else {
    return "";
  }

  const isNegative = +value < 0;

  let onlyNumbers = value.replace(/[^0-9]/g, "");
  if (!onlyNumbers) return "";
  if (onlyNumbers.length === 1) onlyNumbers = `0${onlyNumbers}`;
  const int = onlyNumbers.slice(0, -2);
  const decimal = onlyNumbers.slice(-2);
  let formattedValue = formatter.format(+`${int}.${decimal}`);

  if (isNegative) {
    formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
  }

  return formattedValue;
}

function removeLeadingZeros(value: string) {
  return value.replace(/^0+/, "");
}

export function maskCoefficientInput(value: string) {
  let onlyNumbers = ("" + value).replace(/[^0-9]/g, "");
  if (!onlyNumbers) return "";

  if (onlyNumbers.length === 1) onlyNumbers = `00${onlyNumbers}`;
  let int = removeLeadingZeros(onlyNumbers.slice(0, -2));
  if (!int) int = "0";
  const decimal = onlyNumbers.slice(-2);
  const formattedValue = `${int}.${decimal}`;

  return formattedValue;
}

export function maskCoefficient(value: string | number) {
  const number = parseFloat(("" + value).replace(/[^0-9.]/g, ""));
  if (isNaN(number)) return "";

  const formattedValue = number.toFixed(2);

  return formattedValue;
}
