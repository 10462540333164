import MDBadge from "components/MDBadge";

export function getStatusLabel(status: string): string {
  switch (status) {
    case "WAITING_APPROVAL":
      return "Aguardando aprovação";
    case "PENDING":
      return "Pendente";
    case "WAITING_PAYMENT":
      return "Aguardando pagamento";
    case "COMPLETED":
      return "Concluído";
    case "CANCELED":
      return "Cancelado";
    default:
      return "";
  }
}

export default function StatusBadge({ status, size }: { status: string; size?: any }) {
  switch (status) {
    case "WAITING_APPROVAL":
      return (
        <MDBadge badgeContent={getStatusLabel(status)} color="primary" container size={size} />
      );
    case "PENDING":
      return <MDBadge badgeContent={getStatusLabel(status)} color="info" container size={size} />;
    case "WAITING_PAYMENT":
      return (
        <MDBadge badgeContent={getStatusLabel(status)} color="warning" container size={size} />
      );
    case "COMPLETED":
      return (
        <MDBadge badgeContent={getStatusLabel(status)} color="success" container size={size} />
      );
    case "CANCELED":
      return (
        <MDBadge badgeContent={getStatusLabel(status)} color="secondary" container size={size} />
      );
    default:
      return <MDBadge badgeContent={status} color="light" container size={size} />;
  }
}
