import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PermissionsSelector from "layouts/administration/configurations/userGroups/components/PermissionsSelector";

const blankEditUserGroupData = {
  name: "",
  description: "",
  permissions: [] as string[],
};

const UPDATE_PLAN = gql`
  mutation UpdateUserGroup($data: UpdateUserGroupInput!, $userGroupId: String!) {
    updateUserGroup(data: $data, userGroupId: $userGroupId) {
      id
      name
      description
      permissions
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalEdit({
  selectedUserGroup,
  setSelectedUserGroup,
  openModalEdit,
  setOpenModalEdit,
}: any) {
  const [updateUserGroupError, setUpdateUserGroupError] = useState<null | Error>(null);
  const [data, setData] = useState(blankEditUserGroupData);

  useEffect(() => {
    if (!selectedUserGroup) return;
    setData({
      name: selectedUserGroup.name,
      description: selectedUserGroup.description,
      permissions: selectedUserGroup.permissions,
    });
  }, [selectedUserGroup]);

  const [updateUserGroupMutation, { loading }] = useMutation(UPDATE_PLAN, {
    onCompleted: () => {
      handlerCloseModalEdit();
    },
    onError: (error) => {
      setUpdateUserGroupError(error);
    },
  });

  function handlerUpdateUserGroup() {
    if (!data.name) return setUpdateUserGroupError(new Error("Nome é obrigatório"));

    updateUserGroupMutation({
      variables: {
        userGroupId: selectedUserGroup.id,
        data: {
          ...data,
        },
      },
    });
  }

  function handlerCloseModalEdit() {
    setOpenModalEdit(false);
    setSelectedUserGroup(null);
  }

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={openModalEdit}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Editar Nível de Acesso
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
          <MDTypography variant="subtitle2" sx={{ mt: 2 }}>
            Permissões
          </MDTypography>
          <PermissionsSelector
            permissions={data.permissions}
            setPermissions={(permissions) =>
              setData({
                ...data,
                permissions,
              })
            }
          />
        </DialogContent>
        {!updateUserGroupError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setUpdateUserGroupError(null)}
          >
            {updateUserGroupError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerUpdateUserGroup}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loading}
          >
            Salvar alterações
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
