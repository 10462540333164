import { codeMonth } from "./codeMonth";

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function getMonthObjects(numMonths: number): { value: number; label: string }[] {
  const monthObjects: { value: number; label: string }[] = [];
  const currentMonth = new Date();

  for (let i = 0; i < numMonths; i++) {
    const month = new Date(currentMonth);
    month.setMonth(month.getMonth() - i);

    const monthLabel = month.toLocaleDateString("pt-BR", { month: "long", year: "numeric" });
    const monthValue = codeMonth(month.getFullYear(), month.getMonth() + 1);

    monthObjects.push({ value: monthValue, label: capitalizeFirstLetter(monthLabel) });
  }

  return monthObjects;
}

export function getLastYears(numYears: number): number[] {
  const now = new Date();
  const thisYear = now.getFullYear();

  const years = [];
  for (let i = 0; i < numYears; i++) {
    years.push(thisYear - i);
  }

  return years;
}
