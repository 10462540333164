import { useNavigate } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { maskcnpj, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";

const CREATE_PROVIDER = gql`
  mutation CreateProvider($data: CreateProviderInput!) {
    createProvider(data: $data) {
      id
    }
  }
`;

const GET_SPECIALTIES_AND_BANKS = gql`
  query AvailableProviderSpecialtiesAndBanks {
    availableProviderSpecialties {
      id
      name
    }
    availableBanks {
      id
      code
      name
    }
    availableProviderServiceTypes {
      id
      name
    }
  }
`;

const initialData = {
  name: "",
  document: "",
  documentType: "CNPJ",
  companyName: "",
  specialtyId: null as number | null,
  serviceTypeId: null as number | null,
  description: "",
  email: "",
  phone1: "",
  phone2: "",
};
const addressInitialData = {
  street: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  zipCode: "",
};

const bankAccountInitialData = {
  bankId: null as number | null,
  agency: "",
  account: "",
  variation: "",
  type: "",
};

export default function NewProvider() {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [data, setData] = useState(initialData);
  const [address, setAddress] = useState(addressInitialData);
  const [bankAccount, setBankAccount] = useState(bankAccountInitialData);

  const [specialtiesOptions, setSpecialtiesOptions] = useState<any[]>([]);
  function getSpecialtyACValue(specialtyId: number) {
    if (!specialtyId) return null;
    return specialtiesOptions.find((s) => s.value === specialtyId);
  }

  const [serviceTypeOptions, setServiceTypeOptions] = useState<any[]>([]);
  function getServiceTypeACValue(serviceTypeId: number) {
    if (!serviceTypeId) return null;
    return serviceTypeOptions.find((s) => s.value === serviceTypeId);
  }

  const [banksOptions, setBanksOptions] = useState<any[]>([]);
  function getBankACValue(bankId: number) {
    if (!bankId) return null;
    return banksOptions.find((s) => s.value === bankId);
  }

  useQuery(GET_SPECIALTIES_AND_BANKS, {
    onCompleted: (data) => {
      setSpecialtiesOptions(
        data.availableProviderSpecialties.map((s: any) => ({ value: s.id, label: s.name }))
      );
      setBanksOptions(
        data.availableBanks.map((b: any) => ({ value: b.id, label: b.code + " - " + b.name }))
      );
      setServiceTypeOptions(
        data.availableProviderServiceTypes.map((s: any) => ({ value: s.id, label: s.name }))
      );
    },
  });

  function clearForm() {
    setData(initialData);
    setAddress(addressInitialData);
    setBankAccount(bankAccountInitialData);
  }

  const navigate = useNavigate();

  const [createProviderMutation, { error: createProviderError, loading: createProviderLoading }] =
    useMutation(CREATE_PROVIDER, {
      onCompleted: (data) => {
        pushAlert({ type: "success", message: "Associado cadastrado com sucesso!" });

        const providerId = data.createProvider.id;
        setTimeout(() => {
          clearForm();
          navigate(`/prestador/${providerId}`, { replace: true });
        }, 500);
      },
    });

  useEffect(() => {
    if (createProviderError) {
      pushAlert({ type: "error", message: createProviderError.message });
    }
  }, [createProviderError]);

  function handlerSubmit(event: any) {
    event.preventDefault();
    // const admissionDate = data.admissionDate.split("/").reverse().join("-");

    createProviderMutation({
      variables: {
        data: {
          ...data,
          address,
          bankAccount,
        },
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <AlertBox alerts={alerts} />

        <MDTypography variant="h5" gutterBottom mb={3}>
          Cadastrar prestador de serviço
        </MDTypography>
        <Grid container spacing={4} component="form" role="form" onSubmit={handlerSubmit}>
          {/* ----- */}
          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={2}>
                Dados Básicos
              </MDTypography>

              <MDInput
                variant="outlined"
                label="Nome"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={data.name}
                onChange={(e: any) => setData({ ...data, name: e.target.value })}
                required
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <FormControl fullWidth sx={{ bgcolor: "#fafafa", mr: 2 }} required>
                  <InputLabel id="select-type-label">Tipo</InputLabel>
                  <Select
                    labelId="select-type-label"
                    value={data.documentType}
                    label="Parentesco"
                    onChange={(e: any) => setData({ ...data, documentType: e.target.value })}
                    required
                  >
                    <MenuItem value="CNPJ">Pessoa Jurídica</MenuItem>
                    <MenuItem value="CPF">Pessoa Física</MenuItem>
                  </Select>
                </FormControl>
                <MDInput
                  variant="outlined"
                  label={data.documentType === "CPF" ? "CPF" : "CNPJ"}
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={data.document}
                  onChange={(e: any) =>
                    setData({
                      ...data,
                      document:
                        data.documentType === "CPF"
                          ? maskcpf(e.target.value)
                          : maskcnpj(e.target.value),
                    })
                  }
                  inputProps={{ maxLength: data.documentType === "CPF" ? 14 : 18 }}
                  required
                />
              </Box>

              {data.documentType === "CNPJ" && (
                <MDInput
                  variant="outlined"
                  label="Razão Social"
                  sx={{ bgcolor: "#fafafa", mb: 2 }}
                  fullWidth
                  value={data.companyName}
                  onChange={(e: any) => setData({ ...data, companyName: e.target.value })}
                />
              )}
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Autocomplete
                  disablePortal
                  options={serviceTypeOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  renderInput={(params) => <MDInput {...params} label="Tipo de Serviço" />}
                  value={getServiceTypeACValue(data.serviceTypeId)}
                  onChange={(e: any, newValue: any) => {
                    setData({ ...data, serviceTypeId: newValue?.value ?? null });
                  }}
                />

                <Autocomplete
                  disablePortal
                  options={specialtiesOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa" }}
                  renderInput={(params) => <MDInput {...params} label="Especialidade" />}
                  value={getSpecialtyACValue(data.specialtyId)}
                  onChange={(e: any, newValue: any) => {
                    setData({ ...data, specialtyId: newValue?.value ?? null });
                  }}
                />
              </Box>

              <MDInput
                variant="outlined"
                label="Descrição"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={data.description}
                onChange={(e: any) => setData({ ...data, description: e.target.value })}
              />
            </Card>

            <Card sx={{ p: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Contato
              </MDTypography>
              <MDInput
                variant="outlined"
                label="E-mail"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={data.email}
                onChange={(e: any) => setData({ ...data, email: e.target.value })}
                type="email"
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="Telefone"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={data.phone1}
                  onChange={(e: any) => setData({ ...data, phone1: e.target.value })}
                  type="tel"
                />
                <MDInput
                  variant="outlined"
                  label="Celular"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={data.phone2}
                  onChange={(e: any) => setData({ ...data, phone2: e.target.value })}
                  type="tel"
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Endereço
              </MDTypography>
              <MDInput
                variant="outlined"
                label="Logradouro"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={address.street}
                onChange={(e: any) => setAddress({ ...address, street: e.target.value })}
              />

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="Número"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.number}
                  onChange={(e: any) => setAddress({ ...address, number: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Bairro"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={address.neighborhood}
                  onChange={(e: any) =>
                    setAddress({
                      ...address,
                      neighborhood: e.target.value,
                    })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="CEP"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.zipCode}
                  onChange={(e: any) => setAddress({ ...address, zipCode: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Estado"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.state}
                  onChange={(e: any) => setAddress({ ...address, state: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Cidade"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={address.city}
                  onChange={(e: any) => setAddress({ ...address, city: e.target.value })}
                />
              </Box>
              <MDInput
                variant="outlined"
                label="Complemento"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={address.complement}
                onChange={(e: any) => setAddress({ ...address, complement: e.target.value })}
              />
            </Card>

            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={3}>
                Dados Bancários
              </MDTypography>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {/* <MDInput
                  variant="outlined"
                  label="Banco"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={address.number}
                  onChange={(e: any) => setAddress({ ...address, number: e.target.value })}
                /> */}
                <Autocomplete
                  disablePortal
                  options={banksOptions}
                  fullWidth
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  renderInput={(params) => <MDInput {...params} label="Banco" />}
                  value={getBankACValue(bankAccount.bankId)}
                  onChange={(e: any, newValue: any) => {
                    setBankAccount({ ...bankAccount, bankId: newValue?.value ?? null });
                  }}
                />
                <FormControl fullWidth sx={{ bgcolor: "#fafafa" }}>
                  <InputLabel id="select-account-type-label">Tipo</InputLabel>
                  <Select
                    labelId="select-account-type-label"
                    value={bankAccount.type}
                    label="Tipo"
                    onChange={(e: any) => setBankAccount({ ...bankAccount, type: e.target.value })}
                  >
                    <MenuItem value="CHECKING">Corrente</MenuItem>
                    <MenuItem value="SAVINGS">Poupança</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <MDInput
                  variant="outlined"
                  label="Agência"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={bankAccount.agency}
                  onChange={(e: any) => setBankAccount({ ...bankAccount, agency: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Conta"
                  sx={{ bgcolor: "#fafafa", mr: 2 }}
                  fullWidth
                  value={bankAccount.account}
                  onChange={(e: any) => setBankAccount({ ...bankAccount, account: e.target.value })}
                />
                <MDInput
                  variant="outlined"
                  label="Variação"
                  sx={{ bgcolor: "#fafafa" }}
                  fullWidth
                  value={bankAccount.variation}
                  onChange={(e: any) =>
                    setBankAccount({ ...bankAccount, variation: e.target.value })
                  }
                />
              </Box>
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton variant="contained" color="secondary" sx={{ mr: 2 }} onClick={clearForm}>
                Limpar
              </MDButton>
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={createProviderLoading}
              >
                Salvar Dados
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
