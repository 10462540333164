// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
  Autocomplete,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import { Link, useLocation } from "react-router-dom";
import { maskcpf } from "utils/mask";
import { getAge } from "utils/getAge";
import { getLocaleDateString } from "utils/getLocaleDateString";
import AppointmentsTimeline from "./components/AppointmentsTimeline";

const GET_ASSOCIATES = gql`
  query AssociatesAndProviders($take: Float) {
    associates(take: $take) {
      total
      items {
        id
        person {
          id
          name
          cpf
        }
        type
        plan {
          id
          name
          coefficient
          price
          description
        }
        holder {
          id
          person {
            id
            name
            cpf
          }
        }
      }
    }
    providers(take: $take) {
      total
      items {
        id
        name
      }
    }
  }
`;

export default function MedicalRecord() {
  const [type, setType] = useState("");

  const queryParams = new URLSearchParams(useLocation().search);
  const urlAssociateId = queryParams.get("associateId");

  const [selectedAssociate, setSelectedAssociate] = useState<any>(null);

  const [associatesOptions, setAssociatesOptions] = useState<any[]>([]);
  function getAssociateACValue(associateId: number) {
    if (!associateId) return null;
    return associatesOptions.find((s) => s.value === associateId);
  }

  const { data: { associates } = {}, loading } = useQuery(GET_ASSOCIATES, {
    variables: {
      take: 99999,
    },

    onCompleted: (data) => {
      setAssociatesOptions(
        data.associates.items.map((a: any) => ({
          value: a.id,
          label: `${a.person.name} | ${maskcpf(a.person.cpf)}`,
        }))
      );
      if (urlAssociateId) {
        setSelectedAssociate(data.associates.items.find((a: any) => a.id === urlAssociateId));
      }
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} mt={0}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Prontuário {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>
                </Stack>
              </MDBox>

              <MDBox sx={{ px: 3, mt: 3 }}>
                <MDTypography variant="h6">Buscar paciente:</MDTypography>
                <MDBox sx={{ display: "flex", alignItems: "center", my: 3 }}>
                  <Autocomplete
                    disablePortal
                    options={associatesOptions}
                    fullWidth
                    sx={{ bgcolor: "#fafafa" }}
                    renderInput={(params) => <MDInput {...params} label="Associado" />}
                    value={getAssociateACValue(selectedAssociate?.id)}
                    onChange={(e: any, newValue: any) => {
                      setSelectedAssociate(
                        associates?.items?.find((a: any) => a.id === newValue?.value)
                      );
                    }}
                  />
                  <FormControl fullWidth sx={{ bgcolor: "#fafafa", ml: 2 }} required>
                    <InputLabel id="select-type-label">Tipo de atendimento</InputLabel>
                    <Select
                      labelId="select-type-label"
                      label="Tipo de atendimento"
                      required
                      value={type}
                      onChange={(e: any) => setType(e.target.value)}
                    >
                      <MenuItem value="MEDICAL">MÉDICO</MenuItem>
                      <MenuItem value="DENTAL">ODONTOLÓGICO</MenuItem>
                      <MenuItem value="PHYSICAL_THERAPY">FISIOTERAPÊUTICO</MenuItem>
                      <MenuItem value="NUTRITION">NUTRICIONAL</MenuItem>
                      <MenuItem value="PSYCHOLOGICAL">PSICOLÓGICO</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              </MDBox>

              {!!selectedAssociate && !!type && (
                <MDBox sx={{ p: 3, pt: 0 }}>
                  <MDTypography variant="h6" sx={{ mb: 2 }}>
                    Dados do paciente {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>
                  <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDTypography variant="subtitle2" color="dark" mr={2}>
                      Nome: <strong>{selectedAssociate?.person.name}</strong>
                    </MDTypography>

                    <MDTypography variant="subtitle2" color="dark" mr={2}>
                      CPF: <strong>{selectedAssociate?.person.cpf}</strong>
                    </MDTypography>

                    <MDTypography variant="subtitle2" color="dark">
                      Idade:{" "}
                      <strong>
                        {selectedAssociate?.person.birthDate
                          ? getAge(selectedAssociate?.person.birthDate) +
                            " anos (" +
                            getLocaleDateString(selectedAssociate?.person.birthDate) +
                            ")"
                          : ""}
                      </strong>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>

          {!!selectedAssociate && !!type && (
            <Grid item xs={12}>
              <AppointmentsTimeline associate={selectedAssociate} type={type} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
