import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { justNumbers } from "utils/justNumbers";
import { maskBRL, maskCoefficient, maskCoefficientInput } from "utils/mask";
import MDInput from "components/MDInput";

const CREATE_SERVICE = gql`
  mutation CreateService($data: CreateServiceInput!) {
    createService(data: $data) {
      id
      name
      description
      price
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const newServiceInitialData = {
  name: "",
  description: "",
  price: "",
};

export default function ModalNewService({
  openModalNewService,
  setOpenModalNewService,
  provider,
}: any) {
  const [createServiceError, setCreateServiceError] = useState<null | Error>(null);
  const [data, setData] = useState(newServiceInitialData);

  // on complete, reload GET_SERVICES
  const [
    createServiceMutation,
    { error: ApolloCreateServiceError, loading: loadingCreateService },
  ] = useMutation(CREATE_SERVICE, {
    onCompleted: () => {
      handlerCloseModal();
      setData(newServiceInitialData);
    },
    refetchQueries: ["GetServices"],
  });

  function handlerCreateService() {
    if (!data.name) return setCreateServiceError(new Error("Nome é obrigatório"));

    createServiceMutation({
      variables: {
        data: {
          ...data,
          price: +justNumbers(data.price) || null,
          providerId: provider.id,
        },
      },
    });
  }

  function handlerCloseModal() {
    setOpenModalNewService(false);
  }

  useEffect(() => {
    setCreateServiceError(ApolloCreateServiceError);
  }, [ApolloCreateServiceError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModalNewService}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Novo serviço
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Valor"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.price}
            onChange={(e: any) => {
              setData({ ...data, price: maskBRL(e.target.value) });
            }}
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
        </DialogContent>
        {!createServiceError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setCreateServiceError(null)}
          >
            {createServiceError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerCreateService}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingCreateService}
          >
            Criar serviço
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
