import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TableContainer,
  TableFooter,
  Stack,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import ModalNew from "./components/ModalNew";
import ModalEdit from "./components/ModalEdit";
import TablePagination from "components/TablePagination";
import DeleteButton from "layouts/administration/configurations/appointmentOwnerGroups/components/DeleteButton";
import usePermission from "hooks/usePermission";
import removeAccents from "utils/removeAccents";

const GET = gql`
  query AppointmentOwnerGroups {
    appointmentOwnerGroups {
      id
      name
      description
    }
  }
`;

export interface AppointmentOwnerGroup {
  id: string;
  name: string;
  description: string | null;
}

function AppointmentOwnerGroups() {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);

  const [searchError, setSearchError] = useState<null | Error>(null);

  const [openModalEdit, setOpenModalEdit] = useState<AppointmentOwnerGroup | null>(null);
  const [openModalNew, setOpenModalNew] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<{ appointmentOwnerGroups: AppointmentOwnerGroup[] }>(
    GET,
    {
      onError(error) {
        setSearchError(error);
      },
      onCompleted(data) {
        setCount(data.appointmentOwnerGroups.length);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  const { can } = usePermission();
  const canEdit = can("UPDATE_APPOINTMENTOWNERGROUP");
  const canDelete = can("DELETE_APPOINTMENTOWNERGROUP");

  // get search
  const filtered = (data?.appointmentOwnerGroups ?? []).filter((appointmentOwnerGroup: any) => {
    return (
      removeAccents(appointmentOwnerGroup.name.toLowerCase()).includes(
        removeAccents(searchInput.toLowerCase())
      ) ||
      removeAccents(appointmentOwnerGroup?.description.toLowerCase() ?? "").includes(
        removeAccents(searchInput.toLowerCase())
      )
    );
  });

  const rows = filtered.slice(skip, skip + take);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <ModalEdit open={openModalEdit} setOpen={setOpenModalEdit} />
        <ModalNew open={openModalNew} setOpen={setOpenModalNew} />
        {!searchError || (
          <MDAlert sx={{ mb: 5 }} color="error" dismissible onClose={() => setSearchError(null)}>
            {searchError.message}
          </MDAlert>
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Grupos de Agenda {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }} />
                  {can("CREATE_APPOINTMENTOWNERGROUP") && (
                    <MDButton
                      sx={{
                        px: 3,
                      }}
                      variant="text"
                      color="white"
                      onClick={() => setOpenModalNew(true)}
                      startIcon={<Icon>add</Icon>}
                    >
                      ADD NOVO
                    </MDButton>
                  )}

                  <Divider orientation="vertical" light sx={{ height: 40, mr: 3, ml: 0 }} />
                  <Card
                    sx={{
                      px: 1.5,
                      borderRadius: "6px",
                    }}
                  >
                    <MDInput
                      label="Procurar"
                      type="search"
                      sx={{
                        minWidth: 200,
                        "& .MuiInput-underline:before": { border: "none" },
                        "& .MuiInput-underline:after": { border: "none" },
                        "& .MuiInput-underline:hover": { border: "none" },
                        "& .MuiInputBase-root:hover:before": { border: "none" },
                      }}
                      variant="standard"
                      value={searchInput}
                      onChange={(e: any) => {
                        setSearchInput(e.target.value);
                        // setPage(0);
                        setSkip(0);
                      }}
                    />
                  </Card>
                </Stack>
              </MDBox>

              <MDBox sx={{ p: 3 }}>
                <TableContainer component="div" sx={{ boxShadow: "none" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((appointmentOwnerGroup: any) => (
                        <TableRow
                          key={appointmentOwnerGroup.id}
                          sx={{
                            ":hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {appointmentOwnerGroup.name}
                          </TableCell>
                          <TableCell>{appointmentOwnerGroup.description}</TableCell>
                          <TableCell>
                            {canEdit && (
                              <MDButton
                                variant="gradient"
                                color="warning"
                                iconOnly
                                onClick={() => setOpenModalEdit(appointmentOwnerGroup)}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            )}

                            {canDelete && <DeleteButton resource={appointmentOwnerGroup} />}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={count}
                          take={take}
                          skip={skip}
                          setTake={setTake}
                          setSkip={setSkip}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AppointmentOwnerGroups;
