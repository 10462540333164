import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

interface User {
  id: string;
  name: string;
  permissions: string[];
}
const initialState = {
  user: {
    id: "",
    name: "",
    permissions: [],
  } as User,
  token: "",
  isLogged: false,
};

const store = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    setSession: (state, action) => {
      state = {
        ...state,
        ...action.payload,
        isLogged: true,
      };
      return state;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.user.permissions = action.payload;
      return state;
    },
    removeSession: (state: any) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key as keyof typeof initialState];
      });
      return state;
    },
  },
});

export const { setSession, removeSession } = store.actions;

const persistConfig = {
  key: "session",
  storage,
};

const sessionReducer = persistReducer(persistConfig, store.reducer);
export default sessionReducer;
