// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import ModalNew from "./components/ModalNew";
import ModalEdit from "./components/ModalEdit";
import { maskBRL } from "utils/mask";
import { maskCoefficient } from "utils/mask";

const GET_PLANS = gql`
  query ProviderServiceTypes($searchInput: String, $take: Float, $skip: Float) {
    providerServiceTypes(searchInput: $searchInput, take: $take, skip: $skip) {
      total
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_PLAN = gql`
  mutation DeleteProviderServiceType($providerServiceTypeId: String!) {
    deleteProviderServiceType(providerServiceTypeId: $providerServiceTypeId)
  }
`;

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function ProviderServiceTypes() {
  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [searchError, setSearchError] = useState<null | Error>(null);
  const [count, setCount] = useState(0);

  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [selectedProviderServiceType, setSelectedProviderServiceType] = useState<any>(null);
  function handlerOpenModalEdit(providerServiceType: any) {
    setOpenModalEdit(true);
    setSelectedProviderServiceType(providerServiceType);
  }

  const [openModalNewProviderServiceType, setOpenModalNewProviderServiceType] =
    useState<boolean>(false);

  const {
    data,
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_PLANS, {
    variables: {
      searchInput,
      take,
      skip,
    },
  });

  const [
    deleteProviderServiceTypeMutation,
    { error: ApolloDeleteProviderServiceTypeError, loading: loadingDeleteProviderServiceType },
  ] = useMutation(DELETE_PLAN, {
    refetchQueries: ["ProviderServiceTypes"],
  });

  function handlerDelete(providerServiceType: any) {
    deleteProviderServiceTypeMutation({
      variables: {
        providerServiceTypeId: providerServiceType.id,
      },
    });
  }

  useEffect(() => {
    if (data?.providerServiceTypes?.total >= 0) {
      setCount(data?.providerServiceTypes?.total);
    }
  }, [data]);

  useEffect(() => {
    setSearchError(ApolloError);
  }, [ApolloError]);

  useEffect(() => {
    setSearchError(ApolloDeleteProviderServiceTypeError);
  }, [ApolloDeleteProviderServiceTypeError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <ModalEdit
          {...{
            selectedProviderServiceType,
            setSelectedProviderServiceType,
            openModalEdit,
            setOpenModalEdit,
          }}
        />
        <ModalNew {...{ openModalNewProviderServiceType, setOpenModalNewProviderServiceType }} />
        {!searchError || (
          <MDAlert sx={{ mb: 5 }} color="error" dismissible onClose={() => setSearchError(null)}>
            {searchError.message}
          </MDAlert>
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Tipos de Serviço de Prestadores de Serviço{" "}
                    {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>
                  <MDButton
                    sx={{
                      ml: "auto",
                      px: 3,
                    }}
                    variant="text"
                    color="white"
                    onClick={() => setOpenModalNewProviderServiceType(true)}
                    startIcon={<Icon>add</Icon>}
                  >
                    Add Tipo de Serviço
                  </MDButton>
                  <Divider orientation="vertical" light sx={{ height: 40, mr: 3, ml: 0 }} />
                  <Card
                    sx={{
                      px: 1.5,
                      borderRadius: "6px",
                    }}
                  >
                    <MDInput
                      label="Procurar"
                      type="search"
                      sx={{
                        minWidth: 200,
                        "& .MuiInput-underline:before": { border: "none" },
                        "& .MuiInput-underline:after": { border: "none" },
                        "& .MuiInput-underline:hover": { border: "none" },
                        "& .MuiInputBase-root:hover:before": { border: "none" },
                      }}
                      variant="standard"
                      value={searchInput}
                      onChange={(e: any) => {
                        setSearchInput(e.target.value);
                        setPage(0);
                        setSkip(0);
                      }}
                    />
                  </Card>
                </Stack>
              </MDBox>

              <MDBox sx={{ p: 3 }}>
                <TableContainer component="div" sx={{ boxShadow: "none" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data?.providerServiceTypes?.items ?? []).map((providerServiceType: any) => (
                        <TableRow
                          key={providerServiceType.id}
                          sx={{
                            ":hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {providerServiceType.name}
                          </TableCell>
                          <TableCell>{providerServiceType.description}</TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="warning"
                              iconOnly
                              onClick={() => handlerOpenModalEdit(providerServiceType)}
                            >
                              <Icon>edit</Icon>
                            </MDButton>

                            <MDButton
                              variant="gradient"
                              color="error"
                              iconOnly
                              onClick={() => handlerDelete(providerServiceType)}
                              loading={loadingDeleteProviderServiceType}
                              sx={{
                                ml: 1,
                              }}
                            >
                              <Icon>delete</Icon>
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* {loading && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                              <CircularProgress />
                            </MDBox>
                          </TableCell>
                        </TableRow>
                      )} */}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={5}
                          count={count}
                          rowsPerPage={take}
                          page={page}
                          labelRowsPerPage="Registros por página"
                          labelDisplayedRows={function defaultLabelDisplayedRows({
                            from,
                            to,
                            count,
                          }) {
                            return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                          }}
                          onPageChange={(event, newPage) => {
                            setPage(newPage);
                            setSkip(newPage * take);
                          }}
                          onRowsPerPageChange={(event) => {
                            setTake(parseInt(event.target.value, 10));
                            setPage(0);
                            setSkip(0);
                          }}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProviderServiceTypes;
