import { useState } from "react";
import { Autocomplete, SxProps, Theme } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import MDInput from "components/MDInput";

const GET = gql`
  query AppointmentOwnerGroups {
    appointmentOwnerGroups {
      id
      name
    }
  }
`;

interface AppointmentOwnerGroup {
  id: string;
  name: string;
}

export interface AppointmentOwnerGroupSelectorProps {
  appointmentOwnerGroupId: string;
  setAppointmentOwnerGroup: (appointmentOwnerGroup: AppointmentOwnerGroup) => void;
  sx?: SxProps<Theme>;
}

export default function AppointmentOwnerGroupSelector({
  appointmentOwnerGroupId,
  setAppointmentOwnerGroup,
  sx,
}: AppointmentOwnerGroupSelectorProps) {
  const [appointmentOwnerGroupOptions, setAppointmentOwnerGroupOptions] = useState<
    AppointmentOwnerGroup[]
  >([]);

  function getOptionValueById(id: string) {
    return appointmentOwnerGroupOptions.find((option) => option.id === id);
  }

  const { loading } = useQuery(GET, {
    onCompleted: (data) => {
      setAppointmentOwnerGroupOptions(data.appointmentOwnerGroups);
    },
    onError(error) {
      alert(error.message);
    },
  });

  return (
    <Autocomplete
      disablePortal
      options={appointmentOwnerGroupOptions}
      getOptionLabel={(option) => option.name}
      fullWidth
      sx={sx}
      renderInput={(params) => <MDInput {...params} label="Grupo de Agenda" />}
      value={getOptionValueById(appointmentOwnerGroupId) ?? null}
      onChange={(e: any, newValue: any) => {
        setAppointmentOwnerGroup(newValue);
      }}
      //   isOptionEqualToValue={(option, value) => option.id === value?.id}
      loading={loading}
    />
  );
}
