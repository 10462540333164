import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  ImageList,
  ImageListItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  ImageListItemBar,
  CircularProgress,
  Box,
  Icon,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { getAge } from "utils/getAge";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBirthDate, maskBRL, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import MDBadge from "components/MDBadge";
import StatusBadge from "layouts/guides/components/StatusBadge";
import { justNumbers } from "utils/justNumbers";
import CardFiles from "./components/CardFiles";

const CREATE_PROVIDER_PAYMENT = gql`
  mutation CreateProviderPayment($data: CreateProviderPaymentInput!) {
    createProviderPayment(data: $data) {
      id
      paymentDescription
      amount
      date
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_PROVIDER_PAYMENT = gql`
  mutation UpdateProviderPayment($providerPaymentId: String!, $data: UpdateProviderPaymentInput!) {
    updateProviderPayment(providerPaymentId: $providerPaymentId, data: $data) {
      id
      amount
      date
      paymentDescription
      updatedAt
      createdAt
    }
  }
`;

const GET_PROVIDER_PAYMENT = gql`
  query ProviderPayment($providerPaymentId: String!) {
    providerPayment(id: $providerPaymentId) {
      id
      paymentDescription
      date
      amount
      createdAt
      updatedAt
      provider {
        id
        name
        bankAccount {
          id
          agency
          account
          type
          variation
          bank {
            id
            name
            code
          }
        }
      }
      guides {
        id
        status
        items {
          id
          price
        }
        associate {
          id
          type
          person {
            id
            name
          }
          holder {
            id
            person {
              id
              name
            }
          }
        }
      }
    }
  }
`;

// const GET_GUIDES = gql`
//   query Guides($filters: FindGuideFilters, $take: Float, $skip: Float) {
//     guides(filters: $filters, take: $take, skip: $skip) {
//       total
//       items {
//         id
//         status
//         associate {
//           id
//           type
//           holder {
//             id
//             person {
//               id
//               name
//             }
//           }
//           person {
//             id
//             name
//           }
//         }
//         items {
//           id
//           price
//           service {
//             id
//             name
//             provider {
//               id
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// `;

interface Guide {
  id: number;
  number: number | null;
  amount: number;
  status: string;
  associateName: string;
  included: boolean;
}

const bankAccountTypeLabel = {
  CHECKING: "Corrente",
  SAVINGS: "Poupança",
} as any;
export default function ViewProviderPayment() {
  const { providerPaymentId } = useParams();
  //   const navigate = useNavigate();
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [guides, setGuides] = useState<Guide[]>([]);
  const [amount, setAmount] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [date, setDate] = useState("");
  // const [getGuides] = useLazyQuery(GET_GUIDES, {
  //   onCompleted(data) {
  //     if (data?.guides?.items?.length > 0) {
  //       setGuides(
  //         data.guides.items.map((_guide: any) => ({
  //           id: _guide.id,
  //           amount: _guide.items.reduce((ac: number, item: any) => (item.price ?? 0) + ac, 0),
  //           status: _guide.status,
  //           associateName:
  //             _guide.associate.type === "HOLDER"
  //               ? _guide.associate.person.name
  //               : _guide.associate.holder.person.name,
  //           included: false,
  //         }))
  //       );
  //     }
  //   },
  //   onError(error) {
  //     pushAlert({ type: "error", message: error.message });
  //   },
  // });

  const { data: { providerPayment } = {}, loading } = useQuery(GET_PROVIDER_PAYMENT, {
    variables: {
      providerPaymentId: providerPaymentId,
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
    onCompleted(data) {
      setGuides(
        data.providerPayment.guides.map((_guide: any) => ({
          id: _guide.id,
          amount: _guide.items.reduce((ac: number, item: any) => (item.price ?? 0) + ac, 0),
          status: _guide.status,
          associateName:
            _guide.associate.type === "HOLDER"
              ? _guide.associate.person.name
              : _guide.associate.holder.person.name,
          included: true,
        }))
      );

      setAmount(maskBRL(data.providerPayment.amount));
      setDate(data.providerPayment.date.split("-").reverse().join("/"));
      setPaymentDescription(data.providerPayment.paymentDescription);
    },
  });

  // function handlerChangeIncluded(guide: Guide, newIncluded: boolean) {
  //   const newArray = [...guides];

  //   for (let i = 0; i < newArray.length; i++) {
  //     const _guide = newArray[i];
  //     if (_guide.id === guide.id) {
  //       _guide.included = newIncluded;
  //       break; // para interromper a iteração assim que encontrar o objeto desejado
  //     }
  //   }
  //   setGuides(newArray);
  // }

  const [updateProviderPaymentMutation, { loading: loadingUpdateProviderPayment }] = useMutation(
    UPDATE_PROVIDER_PAYMENT,
    {
      onCompleted: () => {
        pushAlert({ type: "success", message: "Dados salvos com sucesso!" });
      },
      onError(error) {
        pushAlert({ type: "error", message: error.message });
      },
      refetchQueries: ["ProviderPayments"],
    }
  );

  function handlerSubmit(event: any) {
    event.preventDefault();

    updateProviderPaymentMutation({
      variables: {
        providerPaymentId: providerPayment?.id,
        data: {
          amount: +justNumbers(amount),
          paymentDescription: paymentDescription,
          date: date.split("/").reverse().join("-"),
        },
      },
    });
  }

  // useEffect(() => {
  //   setAmount(
  //     maskBRL(guides.reduce((ac: number, item: any) => (item.included ? item.amount : 0) + ac, 0))
  //   );
  // }, [guides]);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Pagamento" />
      <MDBox pt={6} pb={3}>
        <AlertBox alerts={alerts} />
        <Grid
          container
          spacing={4}

          // justifyContent="center"
        >
          {/* ----- */}
          <Grid item xs={12} xxl={6} component="form" role="form" onSubmit={handlerSubmit}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom>
                Pagamento ({providerPayment?.provider.name}){" "}
                {loading && <CircularProgress color="inherit" size={16} />}
              </MDTypography>

              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Banco: <strong>{providerPayment?.provider.bankAccount?.bank?.name}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Agência: <strong>{providerPayment?.provider.bankAccount?.agency}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Conta: <strong>{providerPayment?.provider.bankAccount?.account}</strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block", mr: 2 }}
                >
                  Tipo:{" "}
                  <strong>
                    {bankAccountTypeLabel[providerPayment?.provider.bankAccount?.type as any]}
                  </strong>
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  color="dark"
                  sx={{ whiteSpace: "nowrap", display: "inline-block" }}
                >
                  Variação: <strong>{providerPayment?.provider.bankAccount?.variation}</strong>
                </MDTypography>
              </MDBox>
              <Divider sx={{ my: 3 }} />

              {/* <MDBox
                sx={{
                  textAlign: "right",
                  px: 2,
                }}
              >
                <MDTypography
                  variant="body2"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Total:
                </MDTypography>
                <MDTypography
                  variant="h3"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {maskBRL(
                    guides.reduce(
                      (ac: number, item: any) => (item.included ? item.amount : 0) + ac,
                      0
                    )
                  )}
                </MDTypography>
              </MDBox>

              <Divider sx={{ my: 3 }} /> */}

              <MDTypography variant="h6" gutterBottom mb={2}>
                Guias incluídas
              </MDTypography>

              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "grey.200",
                    }}
                  >
                    {guides.length > 0 && (
                      <TableRow>
                        <TableCell>Nº</TableCell>
                        <TableCell>Titular</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Status</TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {guides.map((guide, index: number) => (
                      <TableRow
                        key={guide.id}
                        sx={{
                          backgroundColor: guide.included
                            ? index % 2 > 0
                              ? "grey.100"
                              : undefined
                            : "grey.200",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: !guide.included ? "#7b809a" : undefined,
                          }}
                        >
                          {guide?.number}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: !guide.included ? "#7b809a" : undefined,
                          }}
                        >
                          {guide.associateName}
                        </TableCell>
                        <TableCell>
                          <MDTypography
                            variant="body2"
                            color={guide.included ? "error" : "text"}
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {maskBRL(guide.amount)}
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <StatusBadge status={guide.status} size="xs" />
                        </TableCell>
                        {/* <TableCell>
                          {guide.included ? (
                            <MDButton
                              variant="gradient"
                              color="error"
                              iconOnly
                              onClick={() => handlerChangeIncluded(guide, false)}
                            >
                              <Icon>close</Icon>
                            </MDButton>
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="success"
                              iconOnly
                              onClick={() => handlerChangeIncluded(guide, true)}
                            >
                              <Icon>add</Icon>
                            </MDButton>
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))}
                    {guides.length === 0 && (
                      <TableRow
                        sx={{
                          backgroundColor: "grey.200",
                        }}
                      >
                        <TableCell component="th" scope="row" colSpan={3}>
                          Ainda não foi adicionado nenhuma guia à lista
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider sx={{ my: 3 }} />

              <MDInput
                variant="outlined"
                label="Valor"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={amount}
                onChange={(e: any) => setAmount(maskBRL(e.target.value))}
                required
              />
              <MDInput
                variant="outlined"
                label="Data"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={date}
                onChange={(e: any) => setDate(maskBirthDate(e.target.value))}
                required
              />

              <MDInput
                variant="outlined"
                label="Forma de pagamento/Como foi pago"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={paymentDescription}
                onChange={(e: any) => setPaymentDescription(e.target.value)}
                multiline
                rows={5}
              />
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <MDButton
                variant="contained"
                color="info"
                type="submit"
                loading={loadingUpdateProviderPayment}
              >
                Salvar Dados
              </MDButton>
            </MDBox>
          </Grid>

          <Grid item xs={12} xxl={6}>
            <CardFiles providerPayment={providerPayment} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
