import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import GuidesTable from "./components/GuidesTable";
import getMonthObjects from "utils/getMonthObjects";
import usePermission from "hooks/usePermission";

const GET_GUIDES = gql`
  query Guides($filters: FindGuideFilters, $take: Float, $skip: Float) {
    guides(filters: $filters, take: $take, skip: $skip) {
      total
      items {
        id
        number
        status
        date
        createdAt
        updatedAt
        associate {
          id
          type
          holder {
            id
            person {
              id
              name
              cpf
            }
          }
          person {
            id
            name
            cpf
          }
        }
        items {
          id
          price
          coefficient
          service {
            id
            name
            provider {
              id
              name
            }
          }
        }
      }
    }
  }
`;

function Guides() {
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState("ALL");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [searchError, setSearchError] = useState<null | Error>(null);
  const [count, setCount] = useState(0);
  const [month, setMonth] = useState<number | "ALL">("ALL");

  const {
    data,
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_GUIDES, {
    variables: {
      filters: {
        search: searchInput,
        status: status !== "ALL" ? [status] : undefined,
        month: month !== "ALL" ? month : undefined,
      },
      take,
      skip,
    },
  });

  useEffect(() => {
    if (data?.guides?.total >= 0) {
      setCount(data?.guides?.total);
    }
  }, [data]);

  useEffect(() => {
    setSearchError(ApolloError);
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  const { can } = usePermission();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {!searchError || (
          <MDAlert sx={{ mb: 5 }} color="error" dismissible onClose={() => setSearchError(null)}>
            {searchError.message}
          </MDAlert>
        )}
        <MDBox sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <MDTypography variant="body1" sx={{ mr: 2 }}>
            Filtro:{" "}
          </MDTypography>

          <FormControl sx={{ bgcolor: "#fafafa", minWidth: 300 }}>
            <InputLabel id="select-type-label">Status</InputLabel>
            <Select
              labelId="select-type-label"
              value={status}
              label="Status"
              onChange={(e: any) => setStatus(e.target.value)}
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                },
              }}
            >
              <MenuItem value="ALL">Todos</MenuItem>
              <MenuItem value="WAITING_APPROVAL">Aguardando aprovação</MenuItem>
              <MenuItem value="PENDING">Pendente</MenuItem>
              <MenuItem value="WAITING_PAYMENT">Aguardando pagamento</MenuItem>
              <MenuItem value="COMPLETED">Concluído</MenuItem>
              <MenuItem value="CANCELED">Cancelado</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ bgcolor: "#fafafa", minWidth: 300, ml: 3 }}>
            <InputLabel id="select-type-label">Mês</InputLabel>
            <Select
              labelId="select-type-label"
              value={month}
              label="Mês"
              onChange={(e: any) => setMonth(e.target.value)}
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                },
              }}
            >
              <MenuItem key="ALL" value="ALL">
                Todos
              </MenuItem>
              {getMonthObjects(24).map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
        <Grid container spacing={6} mt={0}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Guias {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>

                  <MDBox
                    sx={{
                      ml: "auto",
                    }}
                  />
                  {can("CREATE_GUIDE") && (
                    <MDButton
                      sx={{
                        px: 3,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      variant="text"
                      color="white"
                      // onClick={() => setOpenModalNewGuide(true)}
                      to="/guia/nova"
                      component={Link}
                    >
                      <Icon>add</Icon>&nbsp; Nova guia
                    </MDButton>
                  )}

                  <Divider orientation="vertical" light sx={{ height: 40 }} />
                  <Card
                    sx={{
                      px: 1.5,
                      borderRadius: "6px",
                    }}
                  >
                    <MDInput
                      label="Procurar"
                      type="search"
                      sx={{
                        minWidth: 200,
                        "& .MuiInput-underline:before": { border: "none" },
                        "& .MuiInput-underline:after": { border: "none" },
                        "& .MuiInput-underline:hover": { border: "none" },
                        "& .MuiInputBase-root:hover:before": { border: "none" },
                      }}
                      variant="standard"
                      value={searchInput}
                      onChange={(e: any) => {
                        setSearchInput(e.target.value);
                        setPage(0);
                        setSkip(0);
                      }}
                    />
                  </Card>
                </Stack>
              </MDBox>

              <MDBox sx={{ p: 3 }}>
                <GuidesTable
                  {...{
                    items: data?.guides?.items,
                    loading,
                    count,
                    take,
                    page,
                    setPage,
                    setSkip,
                    setTake,
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Guides;
