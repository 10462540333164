import PageLayout from "components/LayoutContainers/PageLayout";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import GuideInPaper from "./components/GuideInPaper";

import style from "./components/guide.module.css";
import MDButton from "components/MDButton";
import { Box, Icon } from "@mui/material";
import { usePDF } from "react-to-pdf";

export default function PrintGuide() {
  const { guideId } = useParams();

  const { toPDF, targetRef } = usePDF({
    filename: `Guia de Autorização - ${guideId}.pdf`,
    // method: "open",
    resolution: 5,
    page: {
      format: "A5",
    },
  });

  // const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  return (
    <PageLayout>
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <MDButton onClick={() => toPDF()} color="error" sx={{ mr: 2 }}>
          <Icon>insert_drive_file</Icon>&nbsp;Baixar PDF
        </MDButton>
        <MDButton onClick={handlePrint} color="info">
          <Icon>print</Icon>&nbsp;Imprimir
        </MDButton>
      </Box>

      <GuideInPaper guideId={guideId} ref={targetRef} />
      <style type="text/css" media="print">
        {`
        .${style["gc"]} {
          transform: rotate(90deg);
          margin-top: -31mm;
        }
        .${style["gc"]} table {
          border-collapse: collapse;
        }
        `}
      </style>
    </PageLayout>
  );
}
