import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  ImageList,
  ImageListItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  ImageListItemBar,
  CircularProgress,
  Box,
  Icon,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { getAge } from "utils/getAge";
import { getLocaleDateString } from "utils/getLocaleDateString";
import { maskBirthDate, maskBRL, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import MDBadge from "components/MDBadge";
import StatusBadge from "layouts/guides/components/StatusBadge";
import { justNumbers } from "utils/justNumbers";

const CREATE_EXPENSE = gql`
  mutation CreateOperationalExpense($data: CreateOperationalExpenseInput!) {
    createOperationalExpense(data: $data) {
      id
      description
      amount
      date
      createdAt
      updatedAt
    }
  }
`;

const GET_TYPES = gql`
  query OperationalExpenseTypes {
    operationalExpenseTypes {
      id
      name
    }
  }
`;

export default function NewOperationalExpense() {
  const [typesOptions, setTypesOptions] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<any>(null);
  function getTypeACValue(typeId: number) {
    if (!typeId) return null;
    return typesOptions.find((s) => s.value === typeId);
  }
  const { data: { operationalExpenseTypes: types } = {} } = useQuery(GET_TYPES, {
    onCompleted: (data) => {
      setTypesOptions(
        data.operationalExpenseTypes.map((p: any) => ({
          value: p.id,
          label: `${p.name}`,
        }))
      );
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [paymentDescription, setPaymentDescription] = useState(
    "TED | BANCO X | AG XXXX-X | CC XXXXX-X"
  );
  // const [type, setType] = useState("");
  const [date, setDate] = useState(getLocaleDateString(new Date()));

  const [createOperationalExpenseMutation, { loading }] = useMutation(CREATE_EXPENSE, {
    onCompleted: (data) => {
      navigate(`/financeiro/despesas-operacionais/${data.createOperationalExpense.id}`);
    },
    onError(error) {
      pushAlert({ type: "error", message: error.message });
    },
  });

  function handlerSubmit(event: any) {
    event.preventDefault();

    createOperationalExpenseMutation({
      variables: {
        data: {
          amount: +justNumbers(amount),
          description,
          paymentDescription,
          typeId: selectedType?.id,
          date: date.split("/").reverse().join("-"),
        },
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AlertBox alerts={alerts} />
        <Grid
          container
          spacing={4}
          component="form"
          role="form"
          onSubmit={handlerSubmit}
          justifyContent="center"
        >
          {/* ----- */}
          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom>
                Nova Despesa Operacional
              </MDTypography>
              <Divider sx={{ my: 1 }} />

              <MDInput
                variant="outlined"
                label="Valor"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={amount}
                onChange={(e: any) => setAmount(maskBRL(e.target.value))}
                required
              />
              <MDInput
                variant="outlined"
                label="Data"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={date}
                onChange={(e: any) => setDate(maskBirthDate(e.target.value))}
                required
              />

              {/* <FormControl fullWidth sx={{ bgcolor: "#fafafa", mt: 2 }} required>
                <InputLabel id="select-type-label">Situação</InputLabel>
                <Select
                  labelId="select-situation-label"
                  value={type}
                  label="Situação"
                  onChange={(e: any) => setType(e.target.value)}
                >
                  <MenuItem value="FUNCIONÁRIOS CBSAUDE">FUNCIONÁRIOS CBSAUDE</MenuItem>
                  <MenuItem value="GUARDA-VIDA">GUARDA-VIDA</MenuItem>
                  <MenuItem value="ESTAGIÁRIOS">ESTAGIÁRIOS</MenuItem>
                  <MenuItem value="OBRIGAÇÕES PATRONAIS">OBRIGAÇÕES PATRONAIS</MenuItem>
                  <MenuItem value="PRESTADORES DE SERVIÇO">PRESTADORES DE SERVIÇO</MenuItem>
                  <MenuItem value="MATERIAL DE CONSUMO, LIMPEZA E PERMANENTE">
                    MATERIAL DE CONSUMO, LIMPEZA E PERMANENTE
                  </MenuItem>
                  <MenuItem value="DESPESAS FIXAS">DESPESAS FIXAS</MenuItem>
                  <MenuItem value="RESSARCIAMENTO">RESSARCIAMENTO</MenuItem>
                  <MenuItem value="IMPOSTOS">IMPOSTOS</MenuItem>
                  <MenuItem value="DEVOLUÇÃO COTA CAPITAL">DEVOLUÇÃO COTA CAPITAL</MenuItem>
                </Select>
              </FormControl> */}

              <Autocomplete
                disablePortal
                options={typesOptions}
                fullWidth
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                renderInput={(params) => <MDInput {...params} label="Situação" />}
                value={getTypeACValue(selectedType?.id)}
                onChange={(e: any, newValue: any) => {
                  setSelectedType(types?.find((a: any) => a.id === newValue?.value));
                }}
              />

              <MDInput
                variant="outlined"
                label="Especificação da Despesa"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                multiline
                rows={3}
                required
              />

              <MDInput
                variant="outlined"
                label="Forma de pagamento/Como foi pago"
                sx={{ bgcolor: "#fafafa", mt: 2 }}
                fullWidth
                value={paymentDescription}
                onChange={(e: any) => setPaymentDescription(e.target.value)}
                multiline
                rows={3}
              />
              <Divider sx={{ my: 3 }} />

              <MDTypography variant="body2">
                Você poderá <b>anexar arquivos</b> quando salvar este registro
              </MDTypography>
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <MDButton variant="contained" color="info" type="submit" loading={loading}>
                Salvar
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
