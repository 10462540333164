import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { gql, useMutation } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useDispatch, useSelector } from "react-redux";
import { setEmail as rememberMeSetEmail } from "redux/rememberme";
import { RootState } from "redux/store";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "utils/jwt";

import cbsaude_logo from "assets/images/cbsaude-logo.png";
import { Divider } from "@mui/material";
import { setSession } from "redux/session";

const LOGIN = gql`
  mutation Login($password: String!, $username: String!) {
    login(password: $password, username: $username) {
      token
      user {
        id
        permissions
        name
      }
    }
  }
`;

function Basic() {
  const rememberMe = useSelector((state: RootState) => state.rememberMe);
  const session = useSelector((state: RootState) => state.session);
  const [rememberMeSwitch, setRememberMeSwitch] = useState(!!rememberMe.email);

  const handleSetRememberMe = () => {
    const nextValue = !rememberMeSwitch;
    setRememberMeSwitch(nextValue);

    if (nextValue === false) {
      dispatch(rememberMeSetEmail(null));
    }
  };

  const [email, setEmail] = useState(rememberMe.email ?? "");
  const [password, setPassword] = useState("");

  const [mutationLogin, { data, loading, error }] = useMutation(LOGIN);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenValid(session.token)) {
      return navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (data?.login) {
      const { token, ...rest } = data.login;
      const permissions = rest.user.permissions;

      dispatch(
        setSession({
          token,
          user: {
            ...rest.user,
          },
        })
      );
      if (rememberMeSwitch) dispatch(rememberMeSetEmail(email));

      if (permissions.includes("READ_GUIDE_LIST")) {
        return navigate("/guias", { replace: true });
      } else if (permissions.includes("READ_GUIDE_SELF")) {
        return navigate("/minhas-guias", { replace: true });
      } else {
        return navigate("/", { replace: true });
      }
    }
  }, [data]);

  function handlerLogin(event: any) {
    event.preventDefault();
    // mutationLogin({
    //   variables: {
    //     username: email,
    //     password,
    //   },
    // });
  }

  return (
    <BasicLayout>
      <Card>
        <MDBox
          // variant="gradient"
          // bgColor="dark"
          // borderRadius="lg"
          // coloredShadow="info"
          mx={2}
          // mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          pt={4}
          pb={2}
        >
          <MDBox component="img" src={cbsaude_logo} width="30%" />

          <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            CBSAÚDE
          </MDTypography>
          <Divider>Acessar o sistema</Divider>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handlerLogin}>
            {error && (
              <MDAlert color="error" dismissible>
                {error.message}
              </MDAlert>
            )}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="CPF / E-mail"
                fullWidth
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                fullWidth
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMeSwitch} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Lembrar-me
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDAlert color="error">Sistema desativado</MDAlert>

              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                loading={loading}
                type="submit"
                disabled
              >
                Entrar
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Não tem acesso? faça o seu{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  cadastro
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
