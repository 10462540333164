export function codeMonth(year: number, month: number): number {
  if (month > 12) throw new Error("O mês deve ser menor que 12");
  return year * 12 + (month - 1);
}

export function decodeMonth(codedMonth: number) {
  let year = Math.floor(codedMonth / 12);
  let month = (codedMonth % 12) + 1;
  return { month, year };
}
