import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import MDTypography from "components/MDTypography";

const DELETE_APPOINTMENT = gql`
  mutation DeleteMedicalAppointment($medicalAppointmentId: String!) {
    deleteMedicalAppointment(medicalAppointmentId: $medicalAppointmentId)
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalDeleteAppointment({ item, setItem }: any) {
  const [deleteAppointmentError, setDeleteAppointmentError] = useState<null | Error>(null);

  const [deleteMedicalAppointment, { loading }] = useMutation(DELETE_APPOINTMENT, {
    onError(error) {
      // alert(error.message);
      setDeleteAppointmentError(error);
    },
  });

  function handlerDeleteAppointment() {
    deleteMedicalAppointment({
      variables: { medicalAppointmentId: item.id },
      refetchQueries: ["MedicalAppointments"],
      onCompleted(data) {
        // setIsEditing(false);
      },
    });
  }

  function handlerCloseModalEdit() {
    setItem(null);
  }

  // useEffect(() => {
  //   setDeleteAppointmentError(ApolloDeleteAppointmentError);
  // }, [ApolloDeleteAppointmentError]);

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModalEdit}
        aria-labelledby="customized-dialog-title"
        open={!!item}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModalEdit}>
          Atenção
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDBox
            sx={{
              py: 3,
              textAlign: "center",
            }}
          >
            <MDTypography variant="body1" color="dark" mb={1}>
              Você tem certeza que deseja excluir?
            </MDTypography>
            <MDTypography variant="body1" color="dark" mb={1}>
              Esta ação é irreversível!
            </MDTypography>
          </MDBox>
        </DialogContent>
        {!deleteAppointmentError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setDeleteAppointmentError(null)}
          >
            {deleteAppointmentError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerDeleteAppointment}
            variant="gradient"
            color="error"
            sx={{ m: 1 }}
            loading={loading}
          >
            Excluir
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
