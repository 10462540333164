// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
import { maskBRL } from "utils/mask";
import AlertBox, { AlertObject } from "components/AlertBox";
import ModalAddCredit from "./components/ModalAddCredit";
import ModalDeleteCredit from "./components/ModalDeleteCredit";
import usePermission from "hooks/usePermission";

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const GET_ASSOCIATE_CREDITS = gql`
  query AssociateCredits($take: Float, $skip: Float, $filters: FindAssociateCreditFilters) {
    associateCredits(take: $take, skip: $skip, filters: $filters) {
      total
      items {
        id
        description
        amount
        createdAt
        updatedAt
        associateId
        date
      }
    }
  }
`;
export default function CreditsTable({ associate }: { associate: any }) {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const [searchInput, setSearchInput] = useState("");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [openModalNew, setOpenModalNew] = useState<null | number>(null);
  const [creditToDelete, setCreditToDelete] = useState<any>(null);

  const {
    data: { associateCredits } = {},
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_ASSOCIATE_CREDITS, {
    variables: {
      filters: {
        search: searchInput,
        associateId: associate?.id,
      },
      take,
      skip,
    },
  });

  useEffect(() => {
    if (associateCredits?.total >= 0) {
      setCount(associateCredits?.total);
    }
  }, [associateCredits]);

  useEffect(() => {
    if (ApolloError) pushAlert({ type: "error", message: ApolloError.message });
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  const { can } = usePermission();

  const canDelete = can("DELETE_ASSOCIATE_CREDIT");

  return (
    <Card sx={{ p: 3 }}>
      <AlertBox alerts={alerts} />
      <ModalAddCredit open={openModalNew} setOpen={setOpenModalNew} />
      <ModalDeleteCredit {...{ creditToDelete, setCreditToDelete }} />
      <Stack direction="row" alignItems="center" mb={3}>
        <MDTypography variant="h5" gutterBottom>
          Créditos {loading && <CircularProgress color="inherit" size={16} />}
        </MDTypography>
        <Box sx={{ ml: "auto" }} />
        {can("CREATE_ASSOCIATE_CREDIT") && (
          <MDButton
            sx={{
              px: 3,
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="text"
            color="secondary"
            onClick={() => setOpenModalNew(associate?.id)}
          >
            <Icon>add</Icon>&nbsp; Add crédito
          </MDButton>
        )}

        <Divider orientation="vertical" sx={{ height: 40, mr: 3, ml: 0 }} />
        <MDInput
          label="Procurar"
          type="search"
          sx={{
            minWidth: 200,
          }}
          value={searchInput}
          onChange={(e: any) => {
            setSearchInput(e.target.value);
            setPage(0);
            setSkip(0);
          }}
        />
      </Stack>

      <MDBox sx={{ mb: -2 }}>
        <TableContainer component="div" sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Data</TableCell>
                {canDelete && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {associateCredits?.items.map((item: any) => (
                <TableRow
                  key={item.id}
                  sx={{
                    ":hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <MDTypography variant="body2" sx={{ fontWeight: "400" }}>
                      {item.description}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="body2" sx={{ fontWeight: "500", color: "success.main" }}>
                      {maskBRL(item.amount)}
                    </MDTypography>
                  </TableCell>

                  <TableCell>{item.date?.split("-").reverse().join("/")}</TableCell>

                  {canDelete && (
                    <TableCell>
                      <MDButton
                        variant="gradient"
                        color="error"
                        iconOnly
                        onClick={() => setCreditToDelete(item)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}

              {/* {loading && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </MDBox>
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={4}
                  count={count}
                  rowsPerPage={take}
                  page={page}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                    return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                  }}
                  onPageChange={(event, newPage) => {
                    setPage(newPage);
                    setSkip(newPage * take);
                  }}
                  onRowsPerPageChange={(event) => {
                    setTake(parseInt(event.target.value, 10));
                    setPage(0);
                    setSkip(0);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </MDBox>
    </Card>
  );
}
