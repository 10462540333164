import associateCreditsReport from "./associateCredits";
import operationalExpensesReport from "./operationalExpenses";
import providerPaymentsReport from "./providerPayments";
import associateMontlyFeesReport from "./montlyFees";
import externalRevenuesReport from "./externalRevenues";
const reports = {
  associateCreditsReport,
  operationalExpensesReport,
  providerPaymentsReport,
  associateMontlyFeesReport,
  externalRevenuesReport,
};

export default reports;
