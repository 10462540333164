import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { maskBRL } from "utils/mask";
import * as xlsx from "xlsx";
import MDTypography from "components/MDTypography";
import { justNumbers } from "utils/justNumbers";
import isValidCPF from "utils/isValidCPF";
import isValidIsoDateString from "utils/isValidIsoDateString";

const IMPORT_PEOPLE = gql`
  mutation ImportPeople($data: [CreatePersonInput!]!) {
    importPeople(data: $data) {
      id
      name
      cpf
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function parseMoneyFromExcel(value: string | number) {
  if (typeof value === "number") return value;

  if (typeof value === "string") {
    const numericValue = value.replace(/[^0-9,]/g, "");
    const numericValueWithDot = numericValue.replace(",", ".");
    return Number(numericValueWithDot);
  }

  return value;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function formatExcelDate(number: number): string {
  const baseDate = new Date(1900, 0, 1);
  const daysFromBaseDate = Math.floor(number - 1);
  const milissecondsFromDataBase = daysFromBaseDate * 86400000;
  const date = new Date(baseDate.getTime() + milissecondsFromDataBase);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

export default function ModalImportPeople({ open, setOpen }: any) {
  const [importServicesError, setImportServicesError] = useState<null | Error>(null);
  // const [data, setData] = useState(newServiceInitialData);

  // on complete, reload GET_SERVICES

  const [fileData, setFileData] = useState<
    {
      name: string;
      cpf: string;
      birthDate: string;
      registration: string;
      rg: string;
      rgIssuer: string;
    }[]
  >([]);
  const [fileName, setFileName] = useState("");
  const handleFileUpload = (event: any) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result as ArrayBuffer);
        const workbook = xlsx.read(data, { type: "array", cellNF: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = xlsx.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];
        const newFileData = [];
        for (let i = 0; i < rows.length; i++) {
          if (i === 0 && justNumbers(rows[i][1]) === "") continue;
          if (!rows[i][0] && !rows[i][1]) continue;
          newFileData.push({
            name: rows[i][0],
            cpf: rows[i][1],
            birthDate: isNaN(rows[i][2] as any) ? rows[i][2] : formatExcelDate(+rows[i][2]),
            registration: rows[i][3],
            rg: rows[i][4],
            rgIssuer: rows[i][5],
          });
        }
        setFileData(newFileData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  function handlerCloseModal() {
    setOpen(false);
    setFileData([]);
    setFileName("");
  }

  const [importPeopleMutation, { loading: loadingImportServices }] = useMutation(IMPORT_PEOPLE, {
    onCompleted: () => {
      handlerCloseModal();
    },
    onError: (error) => {
      setImportServicesError(error);
    },
    refetchQueries: ["People"],
  });

  function handlerImportServices() {
    // for (const person of fileData) {
    //   const birthDate = person.birthDate?.split("/").reverse().join("-") ?? "";

    //   // if (!isValidCPF("" + person.cpf)) {
    //   //   setImportServicesError(
    //   //     new Error("Algum CPF é inválido, verifique o documento e tente novamente")
    //   //   );
    //   //   return;
    //   // }

    //   if (birthDate && !isValidIsoDateString(birthDate)) {
    //     setImportServicesError(
    //       new Error("Alguma Data de Nascimento é inválida, verifique o documento e tente novamente")
    //     );
    //     return;
    //   }
    // }
    importPeopleMutation({
      variables: {
        data: fileData.map((person) => {
          const birthDate = person.birthDate?.split("/").reverse().join("-") ?? "";
          return {
            ...person,
            cpf: "" + person.cpf,
            birthDate: isValidIsoDateString(birthDate) ? birthDate : undefined,
          };
        }),
      },
    });
  }

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Importar pessoas
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDBox sx={{ px: 3 }}>
            {fileData.length === 0 && (
              <MDBox my={3} sx={{ textAlign: "center" }}>
                <MDBox>
                  <input
                    accept=".xlsx,.xls"
                    // className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                    style={{
                      display: "none",
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <MDButton
                      variant="contained"
                      color="primary"
                      component="span"
                      // className={classes.button}
                    >
                      Selecionar arquivo
                    </MDButton>
                    {fileName && <MDBox sx={{ display: "inline-block", ml: 1 }}>{fileName}</MDBox>}
                  </label>
                </MDBox>
                <MDBox mt={1}>
                  <MDTypography variant="caption" gutterBottom>
                    Só é permitido arquivos Excel (.xlsx, .xls)
                  </MDTypography>
                  <MDTypography variant="caption" gutterBottom display="block">
                    (Nome | CPF | Nascimento | Matrícula | RG | Emissor do RG)
                  </MDTypography>
                </MDBox>
              </MDBox>
            )}

            {fileData.length > 0 && (
              <>
                {/* <MDTypography variant="h5" gutterBottom mb={2}>
                  Confira os dados:
                </MDTypography> */}

                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <thead style={{ textAlign: "left" }}>
                    <tr>
                      <MDTypography variant="h6" component="th">
                        Nome *
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        CPF *
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        Nascimento *
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        Matrícula
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        RG
                      </MDTypography>
                      <MDTypography variant="h6" component="th">
                        Emissor do RG
                      </MDTypography>
                    </tr>
                  </thead>
                  <tbody>
                    {fileData.map((person, index) => (
                      <tr
                        key={index}
                        style={{
                          color:
                            !isValidCPF(person?.cpf ?? "") ||
                            !isValidIsoDateString(
                              person?.birthDate?.split("/").reverse().join("-") ?? ""
                            )
                              ? "red"
                              : undefined,
                          borderTop: "1px solid #e5e5e5",
                        }}
                      >
                        <td>{person.name}</td>
                        <td>{person.cpf}</td>
                        <td>{person.birthDate}</td>
                        <td>{person.registration}</td>
                        <td>{person.rg}</td>
                        <td>{person.rgIssuer}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </MDBox>
        </DialogContent>
        {!importServicesError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setImportServicesError(null)}
          >
            {importServicesError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerImportServices}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loadingImportServices}
            disabled={fileData.length === 0}
          >
            Importar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
