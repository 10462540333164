import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  ImageList,
  ImageListItem,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  ImageListItemBar,
  CircularProgress,
  Box,
  Icon,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Divider,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { maskBRL, maskBirthDate, maskcpf } from "utils/mask";

import { useState } from "react";
import { useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertBox, { AlertObject } from "components/AlertBox";
import { justNumbers } from "utils/justNumbers";
import { getLocaleDateString } from "utils/getLocaleDateString";

const CREATE_PROVIDER = gql`
  mutation CreateGuide($data: CreateGuideInput!) {
    createGuide(data: $data) {
      id
    }
  }
`;

const GET_ASSOCIATES_AND_PROVIDERS = gql`
  query AssociatesAndProviders($take: Float) {
    associates(take: $take) {
      total
      items {
        id
        person {
          id
          name
          cpf
        }
        type
        plan {
          id
          name
          coefficient
          price
          description
        }
        holder {
          id
          person {
            id
            name
            cpf
          }
        }
      }
    }
    providers(take: $take) {
      total
      items {
        id
        name
      }
    }
  }
`;

const GET_SERVICES = gql`
  query Services($take: Float, $filters: FindServiceFilters) {
    services(take: $take, filters: $filters) {
      total
      items {
        id
        name
        description
        price
      }
    }
  }
`;

function getPlanTypeLabel(type: string) {
  switch (type) {
    case "HOLDER":
      return "TITULAR";
    case "DEPENDENT":
      return "DEPENDENTE";
    default:
      return "";
  }
}
export default function NewGuide() {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  // const location = useLocation();
  const queryParams = new URLSearchParams(useLocation().search);
  const urlAssociateId = queryParams.get("associateId");

  const [date, setDate] = useState(getLocaleDateString(new Date()));

  const [selectedAssociate, setSelectedAssociate] = useState<any>(null);
  const [selectedService, setSelectedService] = useState<any>(null);

  const [services, setServices] = useState<any>([]);
  const [providerId, setProviderId] = useState(null);
  const [coefficient, setCoefficient] = useState("");
  const [price, setPrice] = useState("");

  const [associatesOptions, setAssociatesOptions] = useState<any[]>([]);
  function getAssociateACValue(associateId: number) {
    if (!associateId) return null;
    return associatesOptions.find((s) => s.value === associateId);
  }

  const [providersOptions, setProvidersOptions] = useState<any[]>([]);
  function getProviderACValue(providerId: number) {
    if (!providerId) return null;
    return providersOptions.find((s) => s.value === providerId);
  }

  const [servicesOptions, setServicesOptions] = useState<any[]>([]);
  function getServiceACValue(serviceId: number) {
    if (!serviceId) return null;
    return servicesOptions.find((s) => s.value === serviceId);
  }

  const { data: { associates } = {} } = useQuery(GET_ASSOCIATES_AND_PROVIDERS, {
    variables: {
      take: 99999,
    },

    onCompleted: (data) => {
      setAssociatesOptions(
        data.associates.items.map((a: any) => ({
          value: a.id,
          label: `${a.person.name} | ${maskcpf(a.person.cpf)}`,
        }))
      );
      setProvidersOptions(
        data.providers.items.map((a: any) => ({
          value: a.id,
          label: `${a.name}`,
        }))
      );
      if (urlAssociateId) {
        setSelectedAssociate(data.associates.items.find((a: any) => a.id === urlAssociateId));
      }
    },
  });

  const [getServices, { data: { services: allServices } = {} as any }] = useLazyQuery(GET_SERVICES);

  useEffect(() => {
    if (providerId) {
      getServices({
        variables: {
          take: 99999,
          filters: {
            providerId,
          },
        },

        onCompleted: (data) => {
          setServicesOptions(
            data.services.items.map((s: any) => ({
              value: s.id,
              label: `${s.name}` + (s.price ? ` | ${maskBRL(s.price)}` : ""),
            }))
          );
        },
      });
    }
  }, [providerId]);

  function clearForm() {
    // setData(initialData);
    setServices([]);
    setSelectedAssociate(null);
    setProviderId(null);
  }

  const navigate = useNavigate();

  const [createGuideMutation, { error: createGuideError, loading: createGuideLoading }] =
    useMutation(CREATE_PROVIDER, {
      onCompleted: (data) => {
        pushAlert({ type: "success", message: "Guia cadastrado com sucesso!" });

        const guideId = data.createGuide.id;
        setTimeout(() => {
          clearForm();
          navigate(`/guia/${guideId}`, { replace: true });
        }, 500);
      },
    });

  useEffect(() => {
    if (createGuideError) {
      pushAlert({ type: "error", message: createGuideError.message });
    }
  }, [createGuideError]);

  useEffect(() => {
    const coefficient = selectedAssociate?.plan?.coefficient;
    setCoefficient(typeof coefficient === "number" ? `${coefficient * 100}` : "");
  }, [selectedAssociate]);

  function handlerSubmit(event: any) {
    event.preventDefault();

    createGuideMutation({
      variables: {
        data: {
          associateId: selectedAssociate.id,
          date: date.split("/").reverse().join("-"),
          items: services.map((s: any) => ({
            serviceId: s.id,
            coefficient: +justNumbers(s.coefficient) / 100,
            price: +justNumbers(s.price) || null,
          })),
        },
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <AlertBox alerts={alerts} />

        <MDTypography variant="h5" gutterBottom mb={3}>
          Nova Guia
        </MDTypography>
        <Grid container spacing={4} component="form" role="form" onSubmit={handlerSubmit}>
          {/* ----- */}
          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={2}>
                Dados
              </MDTypography>

              <Autocomplete
                disablePortal
                options={associatesOptions}
                fullWidth
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                renderInput={(params) => <MDInput {...params} label="Associado" />}
                value={getAssociateACValue(selectedAssociate?.id)}
                onChange={(e: any, newValue: any) => {
                  setSelectedAssociate(
                    associates?.items?.find((a: any) => a.id === newValue?.value)
                  );
                }}
              />

              {!selectedAssociate || (
                <MDBox sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                  <MDTypography variant="subtitle2" color="dark" mr={2}>
                    Tipo: <strong>{getPlanTypeLabel(selectedAssociate?.type)}</strong>
                  </MDTypography>

                  <MDTypography variant="subtitle2" color="dark" mr={2}>
                    Plano:{" "}
                    <strong>
                      {selectedAssociate?.plan?.name} ({selectedAssociate?.plan?.coefficient * 100}
                      %)
                    </strong>
                  </MDTypography>

                  {selectedAssociate?.type === "DEPENDENT" && (
                    <MDTypography variant="subtitle2" color="dark" mr={2}>
                      Titular: <strong>{selectedAssociate?.holder?.person.name}</strong>
                    </MDTypography>
                  )}
                </MDBox>
              )}

              <Autocomplete
                disablePortal
                options={providersOptions}
                fullWidth
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                renderInput={(params) => <MDInput {...params} label="Prestador de serviço" />}
                value={getProviderACValue(providerId)}
                onChange={(e: any, newValue: any) => {
                  setProviderId(newValue?.value ?? null);
                }}
                disabled={services.length > 0}
              />

              <MDInput
                variant="outlined"
                label="Data"
                sx={{ bgcolor: "#fafafa", mb: 2 }}
                fullWidth
                value={date}
                onChange={(e: any) => setDate(maskBirthDate(e.target.value))}
                required
              />
            </Card>
          </Grid>

          <Grid item xs={12} xxl={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <MDTypography variant="h5" gutterBottom mb={2}>
                Serviços
              </MDTypography>

              <Box>
                <Box flex={1}>
                  <Autocomplete
                    disablePortal
                    options={servicesOptions}
                    fullWidth
                    sx={{ bgcolor: "#fafafa", mb: 2 }}
                    renderInput={(params) => <MDInput {...params} label="Serviço" />}
                    value={getServiceACValue(selectedService?.id)}
                    onChange={(e: any, newValue: any) => {
                      const newSelService: any = allServices?.items?.find(
                        (s: any) => s.id === newValue?.value
                      );
                      setSelectedService(newSelService);
                      setPrice(maskBRL(newSelService?.price ?? null));
                    }}
                    disabled={!providerId}
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MDInput
                      variant="outlined"
                      label="Coeficiente"
                      sx={{ bgcolor: "#fafafa", mr: 2 }}
                      fullWidth
                      value={coefficient}
                      onChange={(e: any) => setCoefficient(e.target.value)}
                      InputProps={{
                        endAdornment: <Icon fontSize="small">percent</Icon>,
                      }}
                      type="number"
                      disabled={!selectedAssociate}
                    />
                    <MDInput
                      variant="outlined"
                      label="Preço"
                      sx={{ bgcolor: "#fafafa" }}
                      fullWidth
                      value={price}
                      onChange={(e: any) => setPrice(maskBRL(e.target.value))}
                      InputProps={{
                        endAdornment: price ? (
                          <MDButton
                            iconOnly
                            variant="text"
                            color="dark"
                            onClick={() => {
                              setPrice("");
                            }}
                          >
                            <Icon fontSize="small">clear</Icon>
                          </MDButton>
                        ) : undefined,
                      }}
                      disabled={!selectedService}
                    />
                  </Box>
                </Box>
                <MDButton
                  sx={{
                    mt: 2,
                    // px: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    // whiteSpace: "nowrap",
                  }}
                  // fullWidth
                  // variant="gradient"
                  color="success"
                  onClick={() => {
                    if (!selectedService)
                      return pushAlert({ type: "error", message: "Nenhum serviço selecionado!" });
                    if (!coefficient)
                      return pushAlert({ type: "error", message: "Informe um coeficiente!" });
                    if (+coefficient < 0)
                      return pushAlert({
                        type: "error",
                        message: "O coeficiente não pode ser menor que zero!",
                      });
                    setServices([
                      ...services,
                      {
                        ...selectedService,
                        coefficient,
                        price: price ? +justNumbers(price) : null,
                        key: "" + Date.now() + selectedService.id,
                      },
                    ]);
                    setPrice("");
                    setSelectedService(null);
                  }}
                >
                  <Icon>add</Icon>&nbsp; Add Item
                </MDButton>
              </Box>

              <Divider
                sx={{
                  my: 3,
                }}
              />

              <TableContainer component="div" sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "grey.400",
                    }}
                  >
                    {services.length > 0 && (
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Preço</TableCell>
                        <TableCell>Coeficiente(%)</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {services.map((service: any, index: number) => (
                      <TableRow
                        key={service.key}
                        sx={{
                          backgroundColor: index % 2 > 0 ? "grey.200" : undefined,
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {service.name}
                        </TableCell>
                        {/* <TableCell>{maskBRL(service.price)}</TableCell> */}
                        <TableCell>
                          <MDTypography
                            variant="button"
                            color="primary"
                            sx={{
                              textDecoration: "line-through",
                            }}
                          >
                            {maskBRL(service.price)}
                          </MDTypography>

                          <MDTypography
                            variant="body2"
                            color="success"
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {maskBRL(
                              service.price ? service.price * (service.coefficient / 100) : null
                            )}
                          </MDTypography>
                        </TableCell>
                        <TableCell>{service.coefficient}%</TableCell>
                        <TableCell>
                          <MDButton
                            variant="gradient"
                            color="error"
                            iconOnly
                            onClick={() => {
                              setServices(services.filter((s: any) => s.key !== service.key));
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {services.length === 0 && (
                      <TableRow
                        sx={{
                          backgroundColor: "grey.200",
                        }}
                      >
                        <TableCell component="th" scope="row" colSpan={3}>
                          Ainda não foi adicionado nenhum serviço à lista
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>

            <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton variant="contained" color="secondary" sx={{ mr: 2 }} onClick={clearForm}>
                Limpar
              </MDButton>
              <MDButton variant="contained" color="info" type="submit" loading={createGuideLoading}>
                Gerar guia
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
