import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { CBSAUDE_API_URL } from "config/constants";
import { onError } from "@apollo/client/link/error";
import store, { appDispatch } from "../../redux/store";
import { removeSession } from "redux/session";

const httpLink = createHttpLink({
  uri: CBSAUDE_API_URL + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = store.getState().session.token;

  document.dispatchEvent(new Event("apiCall"));
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === "UNAUTHENTICATED") {
        appDispatch(removeSession());
      }
    });
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default apolloClient;
