import { gql, useLazyQuery } from "@apollo/client";
import generate from "./generate";

export interface ExternalRevenuesReportInput {
  month: number;
}

const GET_DATA = gql`
  query ExternalRevenues($filters: FindExternalRevenueFilters, $skip: Float, $take: Float) {
    externalRevenues(filters: $filters, skip: $skip, take: $take) {
      total
      items {
        amount
        createdAt
        date
        description
        id
        updatedAt
      }
    }
  }
`;

export default function externalRevenuesReport({ month }: ExternalRevenuesReportInput) {
  return useLazyQuery(GET_DATA, {
    variables: {
      take: 999999,
      filters: {
        month: month,
      },
      orderBy: {
        type: "asc",
      },
    },

    onError(error) {
      alert(error.message);
    },

    onCompleted(data) {
      generate(data, { month });
    },
  });
}
