import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import MDTypography from "components/MDTypography";

import StatisticsCards from "./components/StatisticsCards";

export default function FinancialOverview() {
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <MDTypography variant="h5" gutterBottom mb={3}>
          Visão Geral - Financeiro
        </MDTypography>

        <MDBox mt={-7}>
          <StatisticsCards />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
