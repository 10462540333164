import { gql, useLazyQuery } from "@apollo/client";
import generate from "./generate";

export interface AssociateMontlyFeesReportInput {
  month: number;
}

const GET_DATA = gql`
  query AssociateMontlyFees($take: Float, $filters: FindAssociateMontlyFeeFilters) {
    associateMontlyFees(take: $take, filters: $filters) {
      total
      items {
        id
        month
        createdAt
        updatedAt
        associate {
          id
          person {
            id
            name
            registration
          }
        }
        items {
          id
          description
          amount
        }
      }
    }
  }
`;

export default function associateMontlyFeesReport({ month }: AssociateMontlyFeesReportInput) {
  return useLazyQuery(GET_DATA, {
    variables: {
      take: 999999,
      filters: {
        month: month,
      },
    },

    onError(error) {
      alert(error.message);
    },

    onCompleted(data) {
      generate(data, { month });
    },
  });
}
