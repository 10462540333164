import {
  MenuItem,
  Icon,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import { AppointmentOwnerGroup } from "layouts/administration/configurations/appointmentOwnerGroups";
import BootstrapDialog from "components/BootstrapDialog";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";
import { gql, useQuery } from "@apollo/client";
import { maskcpf } from "utils/mask";
import { CBSAUDE_API_URL } from "config/constants";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const GET_ASSOCIATES = gql`
  query Associates($take: Float, $filters: FindAssociatesFilters) {
    associates(take: $take, filters: $filters) {
      total
      items {
        id
        person {
          id
          name
          cpf
        }
      }
    }
  }
`;

function generateYears() {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 2023; i--) {
    years.push(i);
  }
  return years;
}

export default function DmedReportButton() {
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<null | Error>(null);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [allAssociates, setAllAssociates] = useState<boolean>(false);

  const [selectedAssociate, setSelectedAssociate] = useState<any>(null);
  const [associatesOptions, setAssociatesOptions] = useState<any[]>([]);
  function getAssociateACValue(associateId: number) {
    if (!associateId) return null;
    return associatesOptions.find((s) => s.value === associateId);
  }

  const { data: { associates } = {} } = useQuery(GET_ASSOCIATES, {
    variables: {
      take: 99999,
      filters: {
        types: ["HOLDER"],
      },
    },
  });

  useEffect(() => {
    if (associates?.items) {
      setAssociatesOptions(
        associates.items.map((a: any) => ({
          value: a.id,
          label: `${a.person.name} | ${maskcpf(a.person.cpf)}`,
        }))
      );
    }
  }, [associates]);

  useEffect(() => {
    if (allAssociates) {
      setSelectedAssociate(null);
    }
  }, [allAssociates]);

  function handlerClose() {
    setOpen(false);
  }

  const token = useSelector((state: RootState) => state.session.token);

  function handlerSubmit() {
    if (!year) {
      setError(new Error("O ano é obrigatório"));
      return;
    }

    if (!allAssociates && !selectedAssociate) {
      setError(new Error("Selecione o titular"));
      return;
    }

    const url =
      CBSAUDE_API_URL +
      `/report/dmed/${year}/${allAssociates ? "all" : selectedAssociate.id}?token=${token}`;
    window.open(url, "_blank");
    setOpen(false);
  }
  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <Icon sx={{ mr: 1 }}>upload_file</Icon>
        Gerar relatório DMED
      </MenuItem>

      <BootstrapDialog
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerClose}>
          Gerar relatório DMED
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Autocomplete
            disablePortal
            options={associatesOptions}
            fullWidth
            sx={{ bgcolor: "#fafafa", mb: 0 }}
            renderInput={(params) => <MDInput {...params} label="Titular" />}
            value={getAssociateACValue(selectedAssociate?.id)}
            onChange={(e: any, newValue: any) => {
              setSelectedAssociate(associates?.items?.find((a: any) => a.id === newValue?.value));
            }}
            disabled={allAssociates}
          />
          <FormControlLabel
            control={
              <Switch
                checked={allAssociates}
                onChange={(e) => setAllAssociates(e.target.checked)}
              />
            }
            label={"Gerar para todos os titulares "}
            sx={{
              mb: 3,
            }}
          />

          <FormControl fullWidth sx={{ bgcolor: "#fafafa", flex: 2 }} required>
            <InputLabel id="select-year-label">Ano</InputLabel>
            <Select
              labelId="select-year-label"
              value={year}
              onChange={(e: any) => setYear(e.target.value)}
              label="Ano"
              required
            >
              {generateYears().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {allAssociates && (
            <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
              (Pode demorar um pouco, seja paciente)
            </Typography>
          )}
        </DialogContent>
        {!error || (
          <MDAlert sx={{ m: 1 }} color="error" dismissible onClose={() => setError(null)}>
            {error.message}
          </MDAlert>
        )}

        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerSubmit}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            // loading={loading}
          >
            Gerar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
