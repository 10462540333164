import { Link, useLocation, useParams } from "react-router-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import { gql, useQuery } from "@apollo/client";
import { CircularProgress, Icon, AppBar, Tabs, Tab } from "@mui/material";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import { useEffect } from "react";
import AlertBox, { AlertObject } from "components/AlertBox";
import TabInfo from "./components/TabInfo";
import TabDependents from "./components/TabDependents";
import TabGuides from "./components/TabGuides";
import TabFinancial from "./components/TabFinancial";
import usePermission from "hooks/usePermission";

const GET_ASSOCIATE = gql`
  query Associate($input: FindAssociateInput!) {
    associate(input: $input) {
      id
      folder
      type
      admissionDate
      kinship
      status
      person {
        id
        name
        cpf
        birthDate
      }
      plan {
        id
        name
        price
        description
        coefficient
      }
      holder {
        id
        person {
          id
          name
          cpf
        }
      }
      dependents {
        id
        status
        kinship
        person {
          id
          name
          cpf
          birthDate
        }
        plan {
          id
          name
        }
      }
    }
  }
`;

export default function ViewAssociate() {
  const { associateId } = useParams();

  const location = useLocation();
  const anchor = location.hash;
  const [tabValue, setTabValue] = useState(anchor || "#cadastro");
  useEffect(() => {
    if (anchor) {
      setTabValue(anchor);
    } else {
      setTabValue("#cadastro");
    }
  }, [anchor]);

  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  function pushAlert(alert: { type: "error" | "success"; message: string }) {
    setAlerts([...alerts, alert]);
  }

  const handleSetTabValue = (event: any, newValue: any) => setTabValue(newValue);

  // -----------------------------------------------------------------------------

  const {
    data: { associate } = {},
    loading,
    error: getAssociateError,
  } = useQuery(GET_ASSOCIATE, {
    variables: {
      input: {
        id: associateId,
      },
    },
  });

  useEffect(() => {
    if (getAssociateError) {
      pushAlert({ type: "error", message: getAssociateError.message });
    }
  }, [getAssociateError]);

  const { can } = usePermission();

  return (
    <DashboardLayout>
      <DashboardNavbar title="Associado" />

      <MDBox pt={2} pb={3}>
        <AlertBox alerts={alerts} />

        <MDTypography variant="h5" gutterBottom mb={3}>
          Associado - {associate?.person.name}{" "}
          {loading && <CircularProgress color="inherit" size={16} />}
        </MDTypography>

        <MDBox sx={{ mb: 3 }}>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleSetTabValue}>
              <Tab
                label="Cadastro"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    badge
                  </Icon>
                }
                component={Link}
                to="#cadastro"
                replace={true}
                value="#cadastro"
              />
              {associate?.type === "HOLDER" && (
                <Tab
                  label="Dependentes"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      family_restroom
                    </Icon>
                  }
                  // disabled={associate?.type === "DEPENDENT"}
                  component={Link}
                  to="#dependentes"
                  replace={true}
                  value="#dependentes"
                />
              )}

              {can("READ_GUIDE_LIST") && (
                <Tab
                  label="Guias"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      note_add
                    </Icon>
                  }
                  component={Link}
                  to="#guias"
                  replace={true}
                  value="#guias"
                />
              )}

              {associate?.type === "HOLDER" && (
                <Tab
                  label="Financeiro"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      attach_money
                    </Icon>
                  }
                  // disabled={associate?.type === "DEPENDENT"}
                  component={Link}
                  to="#financeiro"
                  replace={true}
                  value="#financeiro"
                />
              )}
            </Tabs>
          </AppBar>
        </MDBox>

        {tabValue === "#cadastro" && <TabInfo associate={associate} />}
        {tabValue === "#dependentes" && associate?.type === "HOLDER" && (
          <TabDependents associate={associate} />
        )}
        {tabValue === "#guias" && can("READ_GUIDE_LIST") && <TabGuides associate={associate} />}
        {tabValue === "#financeiro" && associate?.type === "HOLDER" && (
          <TabFinancial associate={associate} />
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
