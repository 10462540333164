import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PermissionsSelector from "layouts/administration/configurations/userGroups/components/PermissionsSelector";

const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($data: CreateUserGroupInput!) {
    createUserGroup(data: $data) {
      id
      name
      description
      permissions
    }
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const newUserGroupInitialData = {
  name: "",
  description: "",
  permissions: [] as string[],
};

export default function ModalNew({ openModalNewUserGroup, setOpenModalNewUserGroup }: any) {
  const [createUserGroupError, setCreateUserGroupError] = useState<null | Error>(null);
  const [data, setData] = useState(newUserGroupInitialData);

  // on complete, reload GET_PLANS
  const [createUserGroupMutation, { loading }] = useMutation(CREATE_USER_GROUP, {
    onCompleted: () => {
      handlerCloseModal();
      setData(newUserGroupInitialData);
    },
    refetchQueries: ["UserGroups"],
    onError: (error) => {
      setCreateUserGroupError(error);
    },
  });

  function handlerCreateUserGroup() {
    if (!data.name) return setCreateUserGroupError(new Error("Nome é obrigatório"));

    createUserGroupMutation({
      variables: {
        data: {
          ...data,
        },
      },
    });
  }

  function handlerCloseModal() {
    setOpenModalNewUserGroup(false);
  }

  return (
    <MDBox>
      <BootstrapDialog
        onClose={handlerCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModalNewUserGroup}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerCloseModal}>
          Novo Nível de Acesso
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <MDInput
            variant="outlined"
            label="Nome"
            sx={{ bgcolor: "#fafafa", mb: 2 }}
            fullWidth
            value={data?.name}
            onChange={(e: any) => setData({ ...data, name: e.target.value })}
            required
          />
          <MDInput
            variant="outlined"
            label="Descrição"
            sx={{ bgcolor: "#fafafa" }}
            fullWidth
            value={data?.description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            multiline
            rows={3}
          />
          <MDTypography variant="subtitle2" sx={{ mt: 2 }}>
            Permissões
          </MDTypography>
          <PermissionsSelector
            permissions={data.permissions}
            setPermissions={(permissions) =>
              setData({
                ...data,
                permissions,
              })
            }
          />
        </DialogContent>
        {!createUserGroupError || (
          <MDAlert
            sx={{ m: 1 }}
            color="error"
            dismissible
            onClose={() => setCreateUserGroupError(null)}
          >
            {createUserGroupError.message}
          </MDAlert>
        )}
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handlerCreateUserGroup}
            variant="gradient"
            color="info"
            sx={{ m: 1 }}
            loading={loading}
          >
            Salvar
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </MDBox>
  );
}
