import { gql, useLazyQuery } from "@apollo/client";
import generate from "./generate";

export interface AssociateCreditsReportInput {
  month: number;
}

const GET_ASSOCIATES_CREDITS = gql`
  query AssociateCredits($take: Float, $skip: Float, $filters: FindAssociateCreditFilters) {
    associateCredits(take: $take, skip: $skip, filters: $filters) {
      total
      items {
        id
        amount
        createdAt
        date
        description
        updatedAt
        associate {
          id
          person {
            id
            name
            registration
            situation
          }
        }
      }
    }
  }
`;

export default function associateCreditsReport({ month }: AssociateCreditsReportInput) {
  return useLazyQuery(GET_ASSOCIATES_CREDITS, {
    variables: {
      take: 999999,
      filters: {
        month: month,
      },
    },

    onError(error) {
      alert(error.message);
    },

    onCompleted(data) {
      generate(data, { month });
    },
  });
}
