// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  TableFooter,
  Box,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
  Icon,
} from "@mui/material";
import MDButton from "components/MDButton";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import MDAlert from "components/MDAlert";
import { useEffect } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import MDInput from "components/MDInput";
// import debounce from "lodash.debounce";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";
import { maskcpf } from "utils/mask";
import * as xlsx from "xlsx";
import { Menu } from "@mui/material";
import usePermission from "hooks/usePermission";
import useUrlState from "hooks/useUrlState";
import DmedReportButton from "layouts/administration/associates/components/DmedReportButton";
import ModalFilters from "layouts/administration/associates/components/ModalFilters";
import FiltersMenu, {
  FiltersMenuProps,
  initialFilters,
} from "layouts/administration/associates/components/FiltersMenu";
import itsBeenDays from "utils/itsBeenDays";

const GET_ASSOCIATES = gql`
  query Associates($filters: FindAssociatesFilters, $take: Float, $skip: Float) {
    associates(filters: $filters, take: $take, skip: $skip) {
      total
      items {
        id
        folder
        type
        status
        admissionDate
        person {
          id
          name
          cpf
          birthDate
        }
        plan {
          id
          name
        }
      }
    }
  }
`;

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function getTypeLabel(type: string) {
  switch (type) {
    case "HOLDER":
      return "TITULAR";
    case "DEPENDENT":
      return "DEPENDENTE";
    default:
      return type;
  }
}

function TypeBadge({ type }: { type: string }) {
  switch (type) {
    case "HOLDER":
      return <MDBadge badgeContent={getTypeLabel(type)} color="success" container />;
    case "DEPENDENT":
      return <MDBadge badgeContent={getTypeLabel(type)} color="warning" container />;
    default:
      return <MDBadge badgeContent={type} color="light" container />;
  }
}

function StatusBadge({ status }: { status: string }) {
  switch (status) {
    case "ACTIVE":
      return <MDBadge badgeContent="Ativo" color="success" container />;
    case "INACTIVE":
      return <MDBadge badgeContent="Inativo" color="secondary" container />;
    default:
      return <MDBadge badgeContent={status} color="light" container />;
  }
}

function Associates() {
  const [searchInput, setSearchInput] = useUrlState("search", "");

  const [take, setTake] = useUrlState("take", 10);
  const [skip, setSkip] = useUrlState("skip", 0);
  const [page, setPage] = useUrlState("page", 0);
  const [searchError, setSearchError] = useState<null | Error>(null);
  const [count, setCount] = useState(0);

  const [filters, setFilters] = useUrlState("filters", initialFilters);

  const {
    data,
    loading,
    error: ApolloError,
    refetch,
  } = useQuery(GET_ASSOCIATES, {
    variables: {
      filters: {
        search: searchInput,
        ...filters,
        // type: type !== "ALL" ? type : undefined,
      },
      take,
      skip,
    },
  });

  const [getAllAssociates, { loading: loadingAllAssociates }] = useLazyQuery(GET_ASSOCIATES, {
    variables: {
      filters: {
        search: searchInput,
        // type: type !== "ALL" ? type : undefined,
      },
      take: 999999,
      skip,
    },
    onError(error) {
      alert(error.message);
    },
  });

  useEffect(() => {
    if (data?.associates?.total >= 0) {
      setCount(data?.associates?.total);
    }
  }, [data]);

  useEffect(() => {
    setSearchError(ApolloError);
  }, [ApolloError]);

  useEffect(() => {
    refetch();
  }, [searchInput, take, skip]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { can } = usePermission();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {!searchError || (
          <MDAlert sx={{ mb: 5 }} color="error" dismissible onClose={() => setSearchError(null)}>
            {searchError.message}
          </MDAlert>
        )}
        {/* <MDBox sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <MDTypography variant="body1" sx={{ mr: 2 }}>
            Filtro:{" "}
          </MDTypography>

          <FormControl sx={{ bgcolor: "#fafafa", minWidth: 300 }}>
            <InputLabel id="select-type-label">Tipo</InputLabel>
            <Select
              labelId="select-type-label"
              value={type}
              label="Status"
              onChange={(e: any) => setType(e.target.value)}
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                },
              }}
            >
              <MenuItem value="ALL">Todos</MenuItem>
              <MenuItem value="HOLDER">Titular</MenuItem>
              <MenuItem value="DEPENDENT">Dependente</MenuItem>
            </Select>
          </FormControl>
        </MDBox> */}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Associados {loading && <CircularProgress color="inherit" size={16} />}
                  </MDTypography>

                  <Box sx={{ ml: "auto" }} />
                  {/* ======================================================================================== */}
                  {/* <ModalFilters open={openModalFilters} setOpen={setOpenModalFilters} /> */}
                  <FiltersMenu filters={filters} setFilters={setFilters} />

                  {/* ======================================================================================== */}

                  <MDButton
                    variant="text"
                    color="white"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    startIcon={<Icon>menu</Icon>}
                  >
                    Ações
                  </MDButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {can("CREATE_ASSOCIATE") && (
                      <MenuItem to="/associado/novo" component={Link}>
                        <Icon sx={{ mr: 1 }}>add</Icon>
                        Adicionar Associado
                      </MenuItem>
                    )}

                    <Divider sx={{ my: 0.5 }} />

                    {can("READ_FINANCIALREPORTS") && <DmedReportButton />}

                    <MenuItem
                      onClick={() =>
                        getAllAssociates({
                          onCompleted(data) {
                            const _associates = data?.associates?.items.map((associate: any) => ({
                              name: associate.person.name,
                              cpf: maskcpf(associate.person.cpf),
                              plan: associate.plan.name,
                              type: getTypeLabel(associate.type),
                            }));

                            const worksheet = xlsx.utils.json_to_sheet(_associates);

                            worksheet.A1.v = "Nome";
                            worksheet.B1.v = "CPF";
                            worksheet.C1.v = "Plano";
                            worksheet.D1.v = "Tipo";

                            const workbook = xlsx.utils.book_new();
                            xlsx.utils.book_append_sheet(workbook, worksheet, "Associados");
                            xlsx.writeFile(workbook, `Associados.xlsx`);
                          },
                        })
                      }
                      disabled={loadingAllAssociates}
                    >
                      <Icon sx={{ mr: 1 }}>file_download</Icon>
                      Exportar
                      {loadingAllAssociates && (
                        <CircularProgress color="inherit" sx={{ ml: 1 }} size={12} />
                      )}
                    </MenuItem>
                  </Menu>
                  <Divider orientation="vertical" light sx={{ height: 40, mr: 3, ml: 0 }} />
                  <Card
                    sx={{
                      px: 1.5,
                      borderRadius: "6px",
                    }}
                  >
                    <MDInput
                      label="Procurar"
                      type="search"
                      sx={{
                        minWidth: 200,
                        "& .MuiInput-underline:before": { border: "none" },
                        "& .MuiInput-underline:after": { border: "none" },
                        "& .MuiInput-underline:hover": { border: "none" },
                        "& .MuiInputBase-root:hover:before": { border: "none" },
                      }}
                      variant="standard"
                      value={searchInput}
                      onChange={(e: any) => {
                        setSearchInput(e.target.value);
                        setPage(0);
                        setSkip(0);
                      }}
                    />
                  </Card>
                </Stack>
              </MDBox>

              <MDBox sx={{ p: 3 }}>
                <TableContainer component="div" sx={{ boxShadow: "none" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Pasta</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell>Plano</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data?.associates?.items ?? []).map((associate: any) => (
                        <TableRow
                          key={associate.id}
                          sx={{
                            ":hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {associate.folder}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {associate.person.name}
                          </TableCell>

                          <TableCell>{maskcpf(associate.person.cpf)}</TableCell>
                          <TableCell>{associate.plan.name}</TableCell>
                          <TableCell>
                            <TypeBadge type={associate.type} />
                          </TableCell>
                          <TableCell>
                            <StatusBadge status={associate.status} />
                            {itsBeenDays(new Date(associate.admissionDate), 365) && (
                              <MDBadge
                                badgeContent="CARÊNCIA"
                                color="primary"
                                container
                                sx={{ ml: 1 }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <MDButton
                              variant="gradient"
                              color="info"
                              iconOnly
                              component={Link}
                              to={`/associado/${associate.id}`}
                            >
                              <Icon>arrow_forward_ios</Icon>
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* {loading && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                              <CircularProgress />
                            </MDBox>
                          </TableCell>
                        </TableRow>
                      )} */}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={6}
                          count={count}
                          rowsPerPage={take}
                          page={page}
                          labelRowsPerPage="Registros por página"
                          labelDisplayedRows={function defaultLabelDisplayedRows({
                            from,
                            to,
                            count,
                          }) {
                            return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                          }}
                          onPageChange={(event, newPage) => {
                            setPage(newPage);
                            setSkip(newPage * take);
                          }}
                          onRowsPerPageChange={(event) => {
                            setTake(parseInt(event.target.value, 10));
                            setPage(0);
                            setSkip(0);
                          }}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Associates;
